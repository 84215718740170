@import '~bootstrap/scss/functions';
@import '../../../assets/stylesheets/custom_variables.scss';

.phone-input {
  .input-group {
    .input-group-prepend {
      height: calc(1.5em + 0.6rem + 4px);
      .input-group-text {
        padding: 0;
        border: none;
        .css-2b097c-container {
          .css-yk16xz-control,
          .css-1pahdxg-control,
          .css-1fhf3k1-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            .css-g1d714-ValueContainer {
              min-width: 51px;
            }
          }
        }
        .css-26l3qy-menu {
          width: 200%;
        }
        .css-9gakcf-option {
          background-color: $primary;
          color: $input-color;;
          &:hover {
            background-color: $primary;
            color: $input-color;;
          }
        }
      }
    }
  }
}
