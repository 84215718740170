.notification-container {
  z-index: 999999;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;

  // Mobile container
  @media screen and (max-width: $sm) {
    position: absolute;
    width: 100%;
    left: 0;
    height: calc(100vh - 8rem);
  }
  // Desktop container
  @media screen and (min-width: $sm) {
    position: absolute;
    right: 5%;
    width: clamp(30rem, 20%, 40rem);
    height: clamp(60rem, 70vh, 100vh);
    border-radius: 1rem;
    padding-top: 0.5rem;
    border: 2px solid $dark-blue;
    @media screen and (min-width: $lg) and (max-width: $xl) {
      right: 1.25%;
    }
    @media screen and (min-width: $sm) and (max-width: $lg) {
      right: 1rem;
    }
  }
}

.notification-list {
  overflow-y: auto;
  border-top: 0.5px solid $gray-400;
  max-height: 90%;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: $gray-400; /* or add it to the track */
  }
  &::-webkit-scrollbar-thumb {
    background: $dark-blue;
    border-radius: 2.5px;
  }
  .infinite-scroll-component {
    padding: 0 !important;
  }
}

.notification-header {
  display: flex;
  align-items: center;
  height: 7rem;
  &--title {
    font-weight: bold;
    font-size: 1.8rem;
  }
  padding: 0 2rem;
}

.notification {
  display: flex;
  align-items: center;
  height: 10rem;
  padding: 0 2rem;
  border-bottom: 0.5px solid $gray-400;
  &.new {
    background-color: $light-blue;
  }

  &--icon {
    width: 5rem;
    display: flex;
    justify-content: flex-start;
    &__icon {
      margin-left: 0.5rem;
      transform: scale(1.5) !important;
      display: block;
    }
  }
  &--body {
    width: calc(100% - 5rem);

    &__message {
      font-size: 1.4rem;
      margin: 0;
      word-wrap: break-word;
    }
    &__time {
      font-size: 1.2rem;
      margin: 0;
    }
  }
}

.notification-counter {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-blue;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.8rem;
  position: absolute;
  top: 1.5rem;
  right: calc(10% + 6.5rem);

  @media screen and (min-width: $lg) and (max-width: $xl) {
    right: calc(2.5% + 6.5rem);
  }
  @media screen and (min-width: $md) and (max-width: $lg) {
    right: calc(2rem + 6.5rem);
  }
  @media screen and (min-width: $sm) and (max-width: $md) {
    right: calc(2rem + 6.5rem);
  }
  @media screen and (max-width: $sm) {
    right: 7rem;
  }

  @media screen and (min-width: $sm) {
    &.open {
      &::before {
        content: '';
        width: 0;
        height: 0;
        top: 3.5rem;
        left: -1.5rem;
        position: absolute;
        border-left: 1.2rem solid transparent;
        border-right: 1.2rem solid transparent;
        border-bottom: 1.2rem solid $dark-blue;
      }
    }
  }
}

.white + .notification-counter.open::before {
  @media screen and (min-width: $sm) {
    border-bottom: 1.2rem solid white;
  }
}
