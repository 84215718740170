.sidebar {
  // Desktop style
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 2rem 0;
  height: 100vh;
  min-width: 23rem;
  max-width: 23rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 15vw;
  z-index: 1032;
  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.35);
  background-color: $primary;

  @media screen and (min-width: $md) and (max-width: $lg) {
    min-width: 20rem;
  }
  @media screen and (min-width: $sm) and (max-width: $md) {
    min-width: 18rem;
  }
  // Mobile style
  @media screen and (max-width: $sm) {
    box-sizing: border-box;
    min-height: 0;
    width: 50vw;
    height: 100vh;
    border-radius: 0;
    opacity: 0;
    z-index: -1;
    transition: 0.5s;

    &.show {
      opacity: 1;
      z-index: 100000000;
    }

    .logout-link {
      bottom: 6rem !important;
    }
  }

  .nav {
    flex-direction: column;
    .nav-link {
      color: #fff;
      font-weight: 400;
      font-size: 1.6rem;
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      width: 80%;
      padding: 1.2rem 0;
      transition: 0.3s;
      border: 2px solid transparent;
      box-sizing: border-box;

      // Mouse interactions events
      .link-menu {
        padding: 1.2rem 3rem;
      }
      &.disabled {
        opacity: 0.4;
      }
      &.active {
        background-color: $light-blue-secondary;
        font-weight: 700;
        &:hover,
        &:focus {
          color: white;
        }
      }
      &:hover,
      &:focus {
        font-weight: 700;
      }
      &:hover {
        color: $light-blue-secondary;
        &.active {
          color: white;
        }
      }
      &:focus {
        background-color: $light-blue-secondary;
        outline: none;
        border: 2px solid $light-blue;
        &.active {
          border: 2px solid transparent;
        }
      }
    }
  }
  .logout-link {
    position: absolute;
    bottom: 3rem;
    .nav-link {
      width: 100% !important;
    }
  }

  .close-box {
    position: absolute;
    top: 1.5rem;
    right: 1rem;

    @media screen and (min-width: $sm) {
      display: none;
    }
  }
}

// Navbar Top
.navbar-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  padding: 0 2rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 8000;
  background-color: transparent;

  &--left {
    width: 60%;
    display: flex;
    align-items: center;

    &__logo {
      width: 10rem;
      .img {
        width: 100%;
      }
    }
  }
  &--right {
    width: 40%;
    display: flex;
    justify-content: flex-end;
  }

  // DESKTOP
  @media screen and (min-width: $sm) {
    margin-top: 0;
    padding-right: 10%;
    &--left {
      visibility: hidden;
    }
    &--right {
      width: 10rem;
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: $lg) and (max-width: $xl) {
    padding-right: 2.5%;
  }
  @media screen and (min-width: $md) and (max-width: $lg) {
    padding-right: 2rem;
  }
  @media screen and (min-width: $sm) and (max-width: $md) {
    padding-right: 2rem;
  }
}

.cover-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.5s;
  z-index: 0;
  display: none;

  &.show {
    opacity: 1;
    z-index: 9999;
    display: block;
  }
}

.icon-md {
  transform: scale(1.4) !important;
}
