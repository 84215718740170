@import '~bootstrap/scss/functions';
@import '../../../assets/stylesheets/custom_variables.scss';

.calendar-top {
  width: auto;
  font-size: 1.2rem;
  margin: 0 auto;
  @media screen and (max-width: $sm) {
    margin: 0 auto;
  }
  @media (min-width: $lg) {
    margin: 0 auto;
  }
}

.calendar-leyend {
  text-align: right;
  margin-right: 2rem;
  margin-top: 1rem;
  .today {
    background: $light-blue-secondary;
    border-radius: 5px;
    color: black;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 135%;
    letter-spacing: 0.03em;
    margin-right: 0.7rem;
    padding: 0.2rem;
  }
  .busy-days {
    background: $blue;
    border-radius: 5px;
    color: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 135%;
    letter-spacing: 0.03em;
    padding: 0.2rem;
  }
}

.fc,
.fc-media-screen,
.fc-direction-ltr,
.fc-theme-standard,
.fc-daygrid,
.fc-dayGridMonth-view,
.fc-view {
  width: 260px;
  border-radius: 1rem;
  margin: 0 auto;
}

// color fondo y día de hoy

.fc-daygrid,
.fc-daygrid-day,
.fc-day,
.fc-day-past,
.fc-day-other,
.fc-scrollgrid-sync-inner {
  background: #def3f1 !important;
}

.fc-day-today {
  .fc-daygrid-day-frame,
  .fc-scrollgrid-sync-inner {
    background-color: $light-blue-secondary !important;
    border-radius: 0.5rem;
  }
}

.fc-daygrid-day-number {
  margin-left: -2px;
  cursor: pointer;
}

.selected-day-bg {
  .fc-daygrid-day-frame {
    background-color: #24304a !important;
    border-radius: 0.5rem;
    .fc-daygrid-day-number {
      color: white !important;
    }
  }
}
// remover bordes calendario

.fc-scrollgrid,
.fc-scrollgrid-liquid,
.fc-daygrid,
.fc-dayGridMonth-view,
.fc-view,
.fc-scroller-harness,
.fc-scroller-harness-liquid,
.fc-view-harness,
.fc-view-harness-active,
.fc-media-screen,
.fc-direction-ltr,
.fc-theme-standard {
  border-radius: 1rem;
}

.fc-scrollgrid-section,
.fc-scrollgrid-section-body,
.fc-scrollgrid-section-liquid {
  td {
    border-color: transparent;
  }
}

.fc-col-header-cell {
  border-color: transparent !important;
}

// notificación eventos
.fc-daygrid-day-top {
  text-align: center !important;
  position: absolute;
  left: 25%;
  top: 0;
}

.fc-daygrid-event-harness,
.fc-daygrid-event-harness-abs {
  visibility: visible !important;
}

.fc-daygrid-day-bottom,
.fc-daygrid-more-link,
.fc-daygrid-event-dot,
.fc-event-time,
.fc-event-title,
.fc-sticky {
  visibility: hidden;
}

.fc-daygrid-event {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $green !important;
  box-shadow: 0px 1.76509px 4.41272px #15a55f;
  border: none !important;
  left: 22px;
  bottom: -9px;
  position: absolute;
  span {
    font-size: 0.7rem;
    color: $primary;
    margin-left: 2px;
    bottom: 2px;
  }
}

// header calendar

.fc-prev-button,
.fc-next-button {
  background: transparent !important;
  border: none !important;
}

.fc-icon-chevron-right,
.fc-icon-chevron-left {
  color: $primary;
}

.fc-toolbar-title {
  font-size: 1.5rem !important;
}

// List Events

.event-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50vh;
  position: relative;
  width: 85%;
  margin: 0 auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #59c3b7;
    border-radius: 10px;
    outline: none;
  }
  @media screen and (min-width: $lg) {
    margin: 0 auto;
  }
}

.event-row {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.selected-date {
  font-size: 1.3rem;
  color: #434548;
  font-family: 'Ubuntu', sans-serif;
  width: 70%;
  margin: 1rem 0 1rem 1rem;
}

.no-events {
  font-size: 1.4rem;
  color: #434548;
  font-family: 'Ubuntu', sans-serif;
  width: 70%;
  margin: 1rem 0 1rem 1rem;
}

.event-title {
  font-size: 1.3rem;
  color: $blue;
  padding-right: 2rem;
}

.event-time {
  font-size: 1rem;
  @media screen and (max-width: $md) {
    font-size: 1.1rem;
  }
}

.event-address {
  font-size: 1.1rem;
  padding-right: 2rem;
}

.event-separator {
  margin-bottom: 1rem;
  border-left: 1px solid $light-blue-secondary;
}

.status {
  font-size: 1.1rem;
  color: #434548;

  span {
    font-size: 1.1rem;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
  }

  .pending {
    background-color: $warning-yellow;
    color: #1b1f27;
  }

  .in-progress,
  .in_progress {
    background-color: $light-blue-secondary;
    color: #1b1f27;
  }

  .done {
    background-color: $green;
    color: #1b1f27;
  }
}

.modal-header {
  border-bottom: none !important;
}

.text-confirm-finish-modal {
  margin-block: 5px 0;
  font-weight: 700;
}

.confirm-finish-btn-modal {
  background-color: $warning-yellow !important;
  color: #1b1f27 !important;
}
// Modal Event

.event-modal {
  .modal-content {
    @media screen and (max-width: $md) {
      height: auto;
      overflow-y: hidden;
      overflow-x: hidden;
      .modal-body-padding.modal-body {
        overflow-y: hidden;
        overflow-x: hidden;
      }
    }
  }
}

.event-data-alone {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  a {
    background: transparent;
    min-width: 18rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    color: #1b1f27;
    border: solid 1px;
  }
  @media (max-width: 500px) {
    a {
      width: 100%;
    }
  }
}

.event-data-show-task {
  margin-top: 1rem;
  gap: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    background: transparent;
    min-width: 18rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    color: #1b1f27;
    border: solid 1px;
  }

  @media (max-width: 500px) {
    a,
    .button-notify {
      width: 100%;
    }
  }
}

.event-data-finish-shift {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  color: black;

  .not-finished {
    width: 20rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    border: 1px solid #999999;
    background-color: $warning-yellow;
    color: black;
    &:disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  }
  .finished {
    width: 20rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    background-color: $blue;
    color: white;
    &:disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  }
}

.calendar-bottom {
  width: 100%;
  margin: 0 auto;
}
