.modal-sm {
  @media screen and (min-width: $md) {
    max-width: 45rem !important;
  }
}
.modal-content {
  padding: 2.5rem 3rem;
  border-radius: 2.5rem;
  @media screen and (max-width: $sm) {
    width: 90%;
    max-height: 70%;
    padding: 1rem;
    overflow-y: hidden;
    .modal-body-padding.modal-body {
      overflow-y: auto !important;
    }
  }

  .modal-close-icon {
    position: absolute;
    top: 3rem;
    right: 2.5rem;
    @media screen and (max-width: $sm) {
      top: 1.5rem;
      right: 1.5rem;
    }
  }
}

@media screen and (max-width: $sm) {
  .fade.modal.show {
    width: 100vw;
    height: 100vh;
  }
  .modal.show .modal-dialog {
    height: 100vh;
    justify-content: center;
    overflow: hidden;
  }
}

.password-modal {
  .modal-content {
    @media screen and (max-width: $md) {
      height: auto;
      overflow-y: hidden;
      overflow-x: hidden;
      .modal-body-padding.modal-body {
        overflow-y: hidden;
        overflow-x: hidden;
      }
    }
  }
}
