.landing-navbar {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: $dark-blue;
  color: white;
  position: fixed;
  border-bottom: 2px solid rgba($color: white, $alpha: 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  z-index: 999;
}

// BRAND
.brand-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  img {
    // Mobile
    @media screen and (max-width: $lg) {
      width: 60% !important;
    }
    // Desktop
    @media screen and (min-width: $lg) {
      width: 80% !important;
    }
  }
}

// TOGGLE BUTTON
.toggle-button {
  display: none;
  @media screen and (max-width: $lg) {
    display: flex;
  }
  box-sizing: border-box;
  width: 4.5rem;
  height: 4.5rem;
  background-color: $light-blue-secondary;
  border: 2px solid white;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  &:hover {
    background-color: lighten($light-blue-secondary, 15%);
  }
}

.toggle-bar {
  display: block;
  width: 2rem;
  height: 3px;
  background-color: white;
  transition: opacity 0.1s;
  position: absolute;

  &::after,
  &::before {
    content: '';
    background-color: white;
    display: block;
    width: 2rem;
    height: 3px;
    transition: all 0.2s ease-out;
    position: absolute;
  }
  &::before {
    transform: translateY(-0.7rem);
  }
  &::after {
    transform: translateY(0.7rem);
  }

  // Animation toggle
  &.open {
    background-color: transparent;
  }
  &::after.open,
  &::before.open {
    top: 0;
  }
  &.open::before {
    transform: rotate(-135deg);
  }
  &.open::after {
    transform: rotate(135deg);
  }
}

// NAVBAR LINKS
.navbar-links {
  // Mobile
  @media screen and (max-width: $lg) {
    position: absolute;
    left: 0;
    top: 8rem;
    background-color: lighten($dark-blue, 10%);
    width: 100%;
    transition: max-height 0.4s ease-out;
    max-height: 0;
    overflow-y: hidden;
    & .navbar-links--list {
      flex-direction: column;
      transition: 0.2s;
      opacity: 0;
    }
    // Animation - Show List
    &.open {
      max-height: 480px;
      & .navbar-links--list {
        opacity: 1;
      }
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }
  }
  // Desktop
  @media screen and (min-width: $lg) {
    height: 100%;
  }
}

// NAVBAR ITEMS
.navbar-links--list {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  height: 100%;
}

.navbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  padding: 0 1rem;
  &:hover {
    background-color: lighten($dark-blue, 15%);
    & a {
      color: $light-blue-secondary;
    }
    &.as-btn a {
      color: white;
    }
  }
  // Mobile
  @media screen and (max-width: $lg) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    border-bottom: 1px solid rgba($color: white, $alpha: 0.2);
    &:last-child {
      border-bottom: none;
    }
  }
  // MID-WIDTH
  @media screen and (min-width: $lg) and (max-width: $xl) {
    padding: 0 1rem;
  }
}

.navbar-item a {
  transition: 0.4s;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  &:active,
  &:visited,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.as-btn a {
  background-color: $light-blue-secondary;
  color: $dark-blue;
  padding: 1rem 2rem;
  border-radius: 1.5rem;
}

.as-btn.no-bg a {
  background-color: transparent !important;
  color: $light-blue-secondary;
  border: 2px solid $light-blue-secondary;
}
