.tasks-list {
  margin-top: 2rem;
}

.icon-input {
  margin-top: 2rem;
  position: relative;

  &__input {
    font-size: 1.6rem !important;
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
