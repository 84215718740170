@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap);
.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

.react-datepicker-wrapper {
  width: 100%; }

@media (max-width: 767px) {
  .form-control {
    font-size: 16px !important; }
  .css-g1d714-ValueContainer {
    font-size: 15px !important; }
  .css-yk16xz-control {
    margin-top: 4.5px; }
  .css-1pahdxg-control {
    font-size: 15px !important; } }

.is-invalid .input-group .input-group-append span {
  border-color: #bf0e0e;
  color: #bf0e0e; }

.is-invalid .input-group .input-group-prepend span {
  border-color: #bf0e0e;
  color: #bf0e0e; }

.is-invalid .input-group .form-control {
  border-color: #bf0e0e; }

.is-invalid .react-datepicker-wrapper .form-control {
  border-color: #bf0e0e; }

.is-invalid .SingleDatePickerInput {
  border-color: #bf0e0e !important; }

.is-invalid .mui-textfield {
  border-color: #bf0e0e !important; }

.is-invalid .dropzone-section {
  border-color: #bf0e0e; }

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
  padding: 0.3rem 0.75rem !important; }

.form-control:disabled {
  border-color: #ced4da;
  color: #adb5bd; }

.form-control[readonly] {
  background-color: #fff;
  border-color: #f8f9fa;
  color: #adb5bd; }

.disabled .input-group .input-group-prepend span {
  border-color: #ced4da; }

.disabled .input-group .input-group-append span {
  border-color: #ced4da; }

.form-group .custom-switch {
  z-index: auto; }
  .form-group .custom-switch label {
    padding-left: 20px;
    padding-top: 5px; }
    .form-group .custom-switch label:after {
      background-color: #fff !important;
      border-color: #fff !important;
      top: calc(0.25rem + 2.2px) !important;
      left: calc(-2.25rem + 3px) !important;
      width: 1rem !important;
      height: 1rem !important;
      border-radius: 1rem; }
    .form-group .custom-switch label:before {
      background-color: #adb5bd;
      border-color: #adb5bd;
      width: 50px !important;
      height: 20px !important; }
  .form-group .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #fd7e14;
    border-color: #fd7e14;
    border-radius: 1rem; }
  .form-group .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(1.75rem) !important;
            transform: translateX(1.75rem) !important; }
  .form-group .custom-switch .custom-control-label::before {
    border-radius: 1rem !important; }

.button-group input {
  display: none; }

.button-group input + label {
  cursor: pointer;
  flex: 1 1;
  background-color: #fff;
  border: 2px solid #ced4da;
  color: #495057;
  padding: 8px 15px 5px 15px;
  text-align: center;
  margin-right: -1px;
  font-size: 13px;
  font-weight: 500; }
  .button-group input + label:hover, .button-group input + label:focus {
    border-color: #f8f9fa; }

.button-group input:first-child + label {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px; }

.button-group label:last-child {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px; }

.button-group input:checked + label {
  color: #fff;
  background-color: #24304a;
  border-color: #24304a; }

#input-group-dropdown-1 {
  z-index: 0; }

.input-group-text svg {
  width: 18px;
  height: 18px;
  color: #495057; }

.form-control:hover, .form-control:focus, .form-control:active, .form-control.active {
  border-color: #adb5bd; }

.input-disabled:hover {
  border-color: #ced4da !important; }

.form-check-label {
  border-width: 2px;
  background-color: #fff; }

.custom-control-label::before {
  top: 0; }

.mui-textfield {
  background: #fff;
  border: 2px solid #ced4da;
  border-radius: 10px;
  padding: 0 10px;
  width: 100%;
  min-height: 34px;
  font-size: 0.8125rem !important;
  font-weight: 500 !important;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .mui-textfield:hover, .mui-textfield:focus, .mui-textfield:active, .mui-textfield.active {
    border-color: #f8f9fa; }

.MuiInput-underline:after {
  border-bottom: 2px solid #24304a !important; }

.MuiInputBase-input {
  cursor: text; }

.CalendarDay__selected_span {
  background: #24304a;
  color: #f8f9fa;
  border: 1px solid #f8f9fa; }

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active {
  background: #24304a;
  color: #f8f9fa;
  border-radius: 1rem; }

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #24304a; }

.SingleDatePicker_picker,
.DayPicker {
  border-radius: 1rem; }

.DateRangePicker {
  width: 100%; }
  .DateRangePicker .DayPickerNavigation {
    min-width: 330px;
    margin: 0 -10px; }
  .DateRangePicker .DateRangePickerInput {
    width: 100%;
    border-radius: 10px;
    border-color: #ced4da;
    border-width: 1px;
    height: calc( 1.5em + 0.75rem + 2px);
    display: inline-flex; }
    .DateRangePicker .DateRangePickerInput:hover, .DateRangePicker .DateRangePickerInput:focus, .DateRangePicker .DateRangePickerInput:active, .DateRangePicker .DateRangePickerInput.active {
      border-color: #ced4da; }
    .DateRangePicker .DateRangePickerInput .DateInput {
      height: calc( 1.5em + 0.75rem + 2px);
      display: flex;
      background-color: transparent; }
      .DateRangePicker .DateRangePickerInput .DateInput .DateInput_input__focused {
        border-bottom: unset; }
      .DateRangePicker .DateRangePickerInput .DateInput .DateInput_input {
        padding-bottom: 1rem;
        height: calc( 1.5em + 0.75rem + 2px);
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        background-color: transparent;
        padding-top: 1rem;
        padding-left: 1rem;
        color: #495057;
        height: 10px; }
    .DateRangePicker .DateRangePickerInput .DateRangePickerInput_calendarIcon,
    .DateRangePicker .DateRangePickerInput .DateRangePickerInput_arrow {
      float: right;
      padding-top: 0.3rem;
      padding-left: 0;
      margin-left: auto; }
      .DateRangePicker .DateRangePickerInput .DateRangePickerInput_calendarIcon:focus,
      .DateRangePicker .DateRangePickerInput .DateRangePickerInput_arrow:focus {
        outline: none; }

.SingleDatePicker {
  width: 100%;
  z-index: auto;
  background: #def3f1; }
  .SingleDatePicker .DayPickerNavigation {
    border-radius: 50px;
    margin: 0 -10px;
    min-width: 330px; }
    @media (max-width: 576px) {
      .SingleDatePicker .DayPickerNavigation {
        width: 200px; } }
  .SingleDatePicker .SingleDatePickerInput {
    width: 100%;
    border-radius: 1rem;
    border-color: #ced4da;
    border-width: 1px;
    height: calc( 1.5em + 0.75rem + 2px);
    display: inline-flex; }
    .SingleDatePicker .SingleDatePickerInput:hover, .SingleDatePicker .SingleDatePickerInput:focus, .SingleDatePicker .SingleDatePickerInput:active, .SingleDatePicker .SingleDatePickerInput.active {
      border-color: #24304a; }
    .SingleDatePicker .SingleDatePickerInput .DateInput {
      display: flex;
      background-color: transparent; }
      .SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input__focused {
        border-bottom: unset; }
      .SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input {
        padding-bottom: 1rem;
        height: calc( 1.5em + 0.75rem + 2px);
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        background-color: transparent;
        padding-top: 0.95rem;
        padding-left: 1rem;
        color: #495057; }
        @media (max-width: 767px) {
          .SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input {
            margin-left: -9px; } }
    .SingleDatePicker .SingleDatePickerInput .SingleDatePickerInput_calendarIcon {
      float: right;
      padding-top: 0.6rem;
      padding-left: 0;
      margin-left: auto;
      margin-left: -5px;
      margin-right: -6px; }
      .SingleDatePicker .SingleDatePickerInput .SingleDatePickerInput_calendarIcon:focus {
        outline: none; }
      @media (max-width: 576px) {
        .SingleDatePicker .SingleDatePickerInput .SingleDatePickerInput_calendarIcon {
          margin-left: -10px;
          margin-right: -5px; } }

.disabled .SingleDatePickerInput:hover {
  border-color: #ced4da !important; }

.DayPicker_transitionContainer {
  background: #def3f1;
  min-height: 300px;
  border-radius: 1rem; }

.CalendarMonth {
  background: #def3f1;
  padding: 0 0 0 10px !important; }

.CalendarMonth_caption {
  background: #def3f1; }
  .CalendarMonth_caption .form-control {
    font-size: 12px !important; }
  .CalendarMonth_caption .mb-1 select:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .CalendarMonth_caption select:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem; }

.SingleDatePickerInput_clearDate {
  margin: 0 0 0 0px;
  padding-left: 10px; }

.css-1wa3eu0-placeholder {
  color: #303030 !important; }

.react-datepicker-popper {
  background: #def3f1;
  z-index: 2 !important; }

.DateInput_fangStroke {
  stroke: #def3f1;
  fill: transparent; }

.DateInput_fangShape {
  fill: #def3f1; }

.CalendarMonthGrid {
  background: #def3f1;
  text-align: left;
  z-index: 0; }

.DayPicker_weekHeader {
  background: #ace1db;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px; }

.CalendarDay__default {
  background: #def3f1;
  border: none; }

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active {
  background: #24304a;
  color: #f8f9fa; }

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 1rem;
  padding: 6px 9px;
  height: 27px; }

.DayPickerNavigation_svg__horizontal {
  margin-top: -2px;
  height: 19px;
  width: 19px;
  fill: #24304a;
  display: block; }

.CalendarMonth_caption .form-control {
  font-size: 12px !important;
  margin-top: -5px;
  color: #24304a; }

.radio-buttons label {
  padding: 5px; }
  .radio-buttons label input {
    margin: 5px; }

.button {
  background-color: #24304a;
  font-size: 14px;
  border-radius: 15px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
  padding-block: 1rem;
  padding-inline: 4rem;
  text-transform: uppercase; }

.button-notify {
  background-color: #24304a;
  border-radius: 1rem;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  min-width: 18rem;
  padding-block: 1rem;
  padding-inline: 2rem;
  text-align: center;
  text-transform: uppercase; }

.button-confirm-notify {
  background-color: #16d964 !important;
  border-radius: 1rem;
  color: #1b1f27 !important;
  cursor: pointer;
  font-weight: bold;
  margin: auto;
  min-width: 18rem;
  padding-block: 1rem;
  padding-inline: 2rem;
  text-align: center;
  text-transform: uppercase; }

.button-refresh {
  color: #1b1f27;
  background-color: #ffffff;
  border: 2px solid #24304a;
  border-radius: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-inline: auto;
  padding: 0.8rem 1rem;
  transition: all 0.25s ease-in-out;
  text-transform: uppercase;
  max-width: 200px; }
  .button-refresh:hover {
    background-color: #24304a;
    color: #ffffff; }

.title-body-modal {
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 0.2rem; }

.plain {
  font-size: 1.3rem;
  color: #8a8c90;
  margin-top: 0rem; }

.title-header-modal {
  font-size: 2rem;
  font-weight: 500; }

.title-image-send {
  margin-top: 1rem;
  margin-bottom: 0.25rem; }
  .title-image-send .optional-text {
    color: #8c8c8c;
    font-style: italic; }

.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

.custom-file-container {
  width: 100px;
  height: 100px;
  margin-bottom: 50px; }

.custom-file-img {
  position: relative; }
  .custom-file-img img {
    position: absolute;
    object-fit: cover;
    width: 100px;
    height: 100px; }
  .custom-file-img:after {
    content: '';
    padding-bottom: 56.25%;
    display: block; }

.custom-avatar-img {
  position: relative; }
  .custom-avatar-img img {
    position: absolute;
    width: 100px;
    height: 100px; }
  .custom-avatar-img:after {
    content: '';
    padding-bottom: 56.25%;
    display: block; }

.custom-file-add-image {
  width: 30px !important;
  height: 30px;
  margin-top: -30px;
  margin-left: 60px;
  background-color: #20c997;
  border-radius: 15px;
  border: 3px solid white;
  box-shadow: -1px 1px 5px 1px #000; }
  .custom-file-add-image input {
    width: 30px; }
  .custom-file-add-image .gg-math-plus {
    margin-top: -23px;
    margin-left: 4px;
    color: white; }

.containerSpinnerLoad {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px; }

.centerview {
  width: 100%;
  min-height: 300px;
  height: 88vh;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center; }

.hover-dropzone .containerSpinnerLoad {
  min-height: auto !important; }
  .hover-dropzone .containerSpinnerLoad .centerview {
    width: 100% !important;
    min-height: auto !important;
    height: auto !important;
    bottom: 0; }

.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

.select-css {
  position: relative;
  cursor: pointer; }
  .select-css:after, .select-css:before {
    content: '';
    position: absolute;
    background: #adb5bd;
    border-radius: 10px;
    display: block;
    top: 15px;
    height: 8px;
    width: 2px; }
  .select-css:before {
    right: 20px;
    -webkit-transform: rotate(-46deg);
            transform: rotate(-46deg); }
  .select-css:after {
    right: 15px;
    -webkit-transform: rotate(46deg);
            transform: rotate(46deg); }
  .select-css .form-control {
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 1rem; }

.css-1fhf3k1-control {
  background-color: #fff !important;
  border-color: #ced4da !important;
  color: #adb5bd !important; }

.css-2b097c-container.border-on .css-yk16xz-control,
.css-2b097c-container.border-on .css-1pahdxg-control,
.css-2b097c-container.border-on .css-1fhf3k1-control,
.css-14jk2my-container.border-on .css-yk16xz-control,
.css-14jk2my-container.border-on .css-1pahdxg-control,
.css-14jk2my-container.border-on .css-1fhf3k1-control {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #4e68a0; }

.css-2b097c-container .css-yk16xz-control,
.css-2b097c-container .css-1pahdxg-control,
.css-2b097c-container .css-1fhf3k1-control,
.css-14jk2my-container .css-yk16xz-control,
.css-14jk2my-container .css-1pahdxg-control,
.css-14jk2my-container .css-1fhf3k1-control {
  border-radius: 1rem;
  width: 100%;
  min-height: 33px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 2px solid #ced4da;
  background-color: #fff;
  color: #495057; }
  .css-2b097c-container .css-yk16xz-control:hover,
  .css-2b097c-container .css-1pahdxg-control:hover,
  .css-2b097c-container .css-1fhf3k1-control:hover,
  .css-14jk2my-container .css-yk16xz-control:hover,
  .css-14jk2my-container .css-1pahdxg-control:hover,
  .css-14jk2my-container .css-1fhf3k1-control:hover {
    border-color: #4e68a0; }
  .css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer,
  .css-2b097c-container .css-1pahdxg-control .css-g1d714-ValueContainer,
  .css-2b097c-container .css-1fhf3k1-control .css-g1d714-ValueContainer,
  .css-14jk2my-container .css-yk16xz-control .css-g1d714-ValueContainer,
  .css-14jk2my-container .css-1pahdxg-control .css-g1d714-ValueContainer,
  .css-14jk2my-container .css-1fhf3k1-control .css-g1d714-ValueContainer {
    padding: 2px 15px; }
    .css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer .css-1wa3eu0-placeholder,
    .css-2b097c-container .css-1pahdxg-control .css-g1d714-ValueContainer .css-1wa3eu0-placeholder,
    .css-2b097c-container .css-1fhf3k1-control .css-g1d714-ValueContainer .css-1wa3eu0-placeholder,
    .css-14jk2my-container .css-yk16xz-control .css-g1d714-ValueContainer .css-1wa3eu0-placeholder,
    .css-14jk2my-container .css-1pahdxg-control .css-g1d714-ValueContainer .css-1wa3eu0-placeholder,
    .css-14jk2my-container .css-1fhf3k1-control .css-g1d714-ValueContainer .css-1wa3eu0-placeholder {
      color: #343a40; }
  .css-2b097c-container .css-yk16xz-control .css-1rhbuit-multiValue,
  .css-2b097c-container .css-1pahdxg-control .css-1rhbuit-multiValue,
  .css-2b097c-container .css-1fhf3k1-control .css-1rhbuit-multiValue,
  .css-14jk2my-container .css-yk16xz-control .css-1rhbuit-multiValue,
  .css-14jk2my-container .css-1pahdxg-control .css-1rhbuit-multiValue,
  .css-14jk2my-container .css-1fhf3k1-control .css-1rhbuit-multiValue {
    margin: 0 2px; }
    .css-2b097c-container .css-yk16xz-control .css-1rhbuit-multiValue .css-12jo7m5,
    .css-2b097c-container .css-1pahdxg-control .css-1rhbuit-multiValue .css-12jo7m5,
    .css-2b097c-container .css-1fhf3k1-control .css-1rhbuit-multiValue .css-12jo7m5,
    .css-14jk2my-container .css-yk16xz-control .css-1rhbuit-multiValue .css-12jo7m5,
    .css-14jk2my-container .css-1pahdxg-control .css-1rhbuit-multiValue .css-12jo7m5,
    .css-14jk2my-container .css-1fhf3k1-control .css-1rhbuit-multiValue .css-12jo7m5 {
      font-size: 100%; }
  .css-2b097c-container .css-yk16xz-control .css-1okebmr-indicatorSeparator,
  .css-2b097c-container .css-yk16xz-control .css-109onse-indicatorSeparator,
  .css-2b097c-container .css-1pahdxg-control .css-1okebmr-indicatorSeparator,
  .css-2b097c-container .css-1pahdxg-control .css-109onse-indicatorSeparator,
  .css-2b097c-container .css-1fhf3k1-control .css-1okebmr-indicatorSeparator,
  .css-2b097c-container .css-1fhf3k1-control .css-109onse-indicatorSeparator,
  .css-14jk2my-container .css-yk16xz-control .css-1okebmr-indicatorSeparator,
  .css-14jk2my-container .css-yk16xz-control .css-109onse-indicatorSeparator,
  .css-14jk2my-container .css-1pahdxg-control .css-1okebmr-indicatorSeparator,
  .css-14jk2my-container .css-1pahdxg-control .css-109onse-indicatorSeparator,
  .css-14jk2my-container .css-1fhf3k1-control .css-1okebmr-indicatorSeparator,
  .css-14jk2my-container .css-1fhf3k1-control .css-109onse-indicatorSeparator {
    display: none; }
  .css-2b097c-container .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer div,
  .css-2b097c-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer div,
  .css-2b097c-container .css-1fhf3k1-control .css-1hb7zxy-IndicatorsContainer div,
  .css-14jk2my-container .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer div,
  .css-14jk2my-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer div,
  .css-14jk2my-container .css-1fhf3k1-control .css-1hb7zxy-IndicatorsContainer div {
    height: 28px; }
    .css-2b097c-container .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer div svg,
    .css-2b097c-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer div svg,
    .css-2b097c-container .css-1fhf3k1-control .css-1hb7zxy-IndicatorsContainer div svg,
    .css-14jk2my-container .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer div svg,
    .css-14jk2my-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer div svg,
    .css-14jk2my-container .css-1fhf3k1-control .css-1hb7zxy-IndicatorsContainer div svg {
      top: -3px;
      position: relative; }
  .css-2b097c-container .css-yk16xz-control .css-1gtu0rj-indicatorContainer,
  .css-2b097c-container .css-yk16xz-control .css-tlfecz-indicatorContainer,
  .css-2b097c-container .css-1pahdxg-control .css-1gtu0rj-indicatorContainer,
  .css-2b097c-container .css-1pahdxg-control .css-tlfecz-indicatorContainer,
  .css-2b097c-container .css-1fhf3k1-control .css-1gtu0rj-indicatorContainer,
  .css-2b097c-container .css-1fhf3k1-control .css-tlfecz-indicatorContainer,
  .css-14jk2my-container .css-yk16xz-control .css-1gtu0rj-indicatorContainer,
  .css-14jk2my-container .css-yk16xz-control .css-tlfecz-indicatorContainer,
  .css-14jk2my-container .css-1pahdxg-control .css-1gtu0rj-indicatorContainer,
  .css-14jk2my-container .css-1pahdxg-control .css-tlfecz-indicatorContainer,
  .css-14jk2my-container .css-1fhf3k1-control .css-1gtu0rj-indicatorContainer,
  .css-14jk2my-container .css-1fhf3k1-control .css-tlfecz-indicatorContainer {
    color: #adb5bd;
    position: relative;
    height: 28px;
    cursor: pointer; }
    .css-2b097c-container .css-yk16xz-control .css-1gtu0rj-indicatorContainer svg,
    .css-2b097c-container .css-yk16xz-control .css-tlfecz-indicatorContainer svg,
    .css-2b097c-container .css-1pahdxg-control .css-1gtu0rj-indicatorContainer svg,
    .css-2b097c-container .css-1pahdxg-control .css-tlfecz-indicatorContainer svg,
    .css-2b097c-container .css-1fhf3k1-control .css-1gtu0rj-indicatorContainer svg,
    .css-2b097c-container .css-1fhf3k1-control .css-tlfecz-indicatorContainer svg,
    .css-14jk2my-container .css-yk16xz-control .css-1gtu0rj-indicatorContainer svg,
    .css-14jk2my-container .css-yk16xz-control .css-tlfecz-indicatorContainer svg,
    .css-14jk2my-container .css-1pahdxg-control .css-1gtu0rj-indicatorContainer svg,
    .css-14jk2my-container .css-1pahdxg-control .css-tlfecz-indicatorContainer svg,
    .css-14jk2my-container .css-1fhf3k1-control .css-1gtu0rj-indicatorContainer svg,
    .css-14jk2my-container .css-1fhf3k1-control .css-tlfecz-indicatorContainer svg {
      top: -3px;
      position: relative; }
  .css-2b097c-container .css-yk16xz-control .css-1hwfws3,
  .css-2b097c-container .css-1pahdxg-control .css-1hwfws3,
  .css-2b097c-container .css-1fhf3k1-control .css-1hwfws3,
  .css-14jk2my-container .css-yk16xz-control .css-1hwfws3,
  .css-14jk2my-container .css-1pahdxg-control .css-1hwfws3,
  .css-14jk2my-container .css-1fhf3k1-control .css-1hwfws3 {
    padding: 0px 8px; }

.css-2b097c-container .css-1pahdxg-control,
.css-14jk2my-container .css-1pahdxg-control {
  border: 2px solid #ced4da !important;
  box-shadow: 2px -4px 0 -1px rgba(248, 249, 250, 0.6) !important; }

.css-2b097c-container .css-26l3qy-menu,
.css-14jk2my-container .css-26l3qy-menu {
  margin: -2px 0 0 0;
  background-color: #fff;
  border: 2px solid #ced4da;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: -2px 3px 0 0px rgba(248, 249, 250, 0.6);
  padding: 5px 10px;
  z-index: 99; }
  .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList,
  .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList {
    padding: 0; }
    .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList::-webkit-scrollbar,
    .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList::-webkit-scrollbar {
      height: 5px;
      width: 10px; }
    .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList::-webkit-scrollbar-track,
    .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 2px;
      background-color: #eceff2; }
    .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList::-webkit-scrollbar-thumb,
    .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList::-webkit-scrollbar-thumb {
      background-color: rgba(89, 195, 183, 0.4);
      border-radius: 2px;
      outline: none; }
    .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option,
    .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option,
    .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-yt9ioa-option,
    .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option,
    .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option,
    .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-yt9ioa-option {
      padding: 5px 15px;
      border-radius: 5px;
      background-color: transparent;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.5;
      color: #6c757d;
      text-align: left; }
      .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:hover,
      .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option:hover,
      .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-yt9ioa-option:hover,
      .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:hover,
      .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option:hover,
      .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-yt9ioa-option:hover {
        padding: 5px 15px;
        background-color: #def3f1; }
      .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:focus,
      .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option:focus,
      .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-yt9ioa-option:focus,
      .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:focus,
      .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option:focus,
      .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-yt9ioa-option:focus {
        outline: 0;
        box-shadow: none; }
    .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option,
    .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option {
      background-color: #24304a;
      color: #495057; }
      .css-2b097c-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option:hover,
      .css-14jk2my-container .css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option:hover {
        background-color: #24304a;
        color: #495057; }

.css-1n7v3ny-option,
.css-9gakcf-option,
.css-yt9ioa-option {
  padding: 5px 15px !important;
  border-radius: 5px;
  background-color: transparent !important;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  color: #6c757d;
  text-align: left; }
  .css-1n7v3ny-option:hover,
  .css-9gakcf-option:hover,
  .css-yt9ioa-option:hover {
    background-color: #def3f1 !important; }
  .css-1n7v3ny-option:focus,
  .css-9gakcf-option:focus,
  .css-yt9ioa-option:focus {
    outline: 0;
    box-shadow: none; }

.css-9gakcf-option {
  background-color: #def3f1 !important;
  color: #fff; }
  .css-9gakcf-option:hover {
    background-color: #def3f1 !important;
    color: #fff; }

.css-xb97g8 {
  cursor: pointer; }
  .css-xb97g8:hover {
    background-color: transparent !important;
    color: #bf0e0e !important; }

.is-invalid .css-yk16xz-control,
.is-invalid .css-1pahdxg-control,
.is-invalid .css-1fhf3k1-control {
  border-color: #bf0e0e !important; }

.is-invalid .select-css .form-control {
  border-color: #bf0e0e; }

.is-invalid .select-css:after {
  border-color: #bf0e0e transparent transparent transparent; }

.is-invalid .css-tlfecz-indicatorContainer:after {
  border-color: #bf0e0e transparent transparent transparent; }

.border-on {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-color: #4e68a0; }

.select input {
  font-family: 'Times New Roman';
  font-size: 1.6rem; }

.disabled.mb-0 {
  opacity: 0.5; }

.disabled .css-yk16xz-control,
.disabled .css-1pahdxg-control,
.disabled .css-1fhf3k1-control {
  border-color: #c2c2c2 !important; }

.disabled .select-css .form-control {
  border-color: #f8f9fa; }

.css-b8ldur-Input {
  border-radius: 1rem; }

.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

.show-hide {
  overflow: hidden;
  font-family: 'Ubuntu';
  background-color: #59C3B7;
  color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(82, 184, 172, 0.1), 2px 4px 17px rgba(89, 195, 183, 0.6);
  border-radius: 1.5rem;
  padding: 1rem;
  height: 6rem;
  margin: 2rem; }
  @media screen and (min-width: 570px) and (max-width: 768px) {
    .show-hide {
      margin: 2rem auto;
      width: 90%; } }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .show-hide {
      width: 100%;
      margin-left: 0; } }

#left {
  overflow: hidden;
  text-align: center;
  font-size: 1.2rem !important;
  padding-right: 1.5rem; }
  @media screen and (max-width: 570px) {
    #left {
      padding-right: 1rem;
      padding-top: 0.5rem;
      font-size: 1rem; } }

#right {
  float: right;
  width: 1rem;
  height: 1rem;
  text-align: center; }
  #right a {
    background-color: transparent;
    border-color: transparent;
    color: #24304A;
    margin-left: -1.5rem;
    margin-top: -1.5rem; }

.show-hide a {
  color: #FFFFFF;
  text-decoration: none;
  padding-top: 0.5rem; }

.show-hide a:hover {
  text-decoration: underline; }

.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

.calendar-top {
  width: auto;
  font-size: 1.2rem;
  margin: 0 auto; }
  @media screen and (max-width: 570px) {
    .calendar-top {
      margin: 0 auto; } }
  @media (min-width: 960px) {
    .calendar-top {
      margin: 0 auto; } }

.calendar-leyend {
  text-align: right;
  margin-right: 2rem;
  margin-top: 1rem; }
  .calendar-leyend .today {
    background: #59c3b7;
    border-radius: 5px;
    color: black;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 135%;
    letter-spacing: 0.03em;
    margin-right: 0.7rem;
    padding: 0.2rem; }
  .calendar-leyend .busy-days {
    background: #24304a;
    border-radius: 5px;
    color: white;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 135%;
    letter-spacing: 0.03em;
    padding: 0.2rem; }

.fc,
.fc-media-screen,
.fc-direction-ltr,
.fc-theme-standard,
.fc-daygrid,
.fc-dayGridMonth-view,
.fc-view {
  width: 260px;
  border-radius: 1rem;
  margin: 0 auto; }

.fc-daygrid,
.fc-daygrid-day,
.fc-day,
.fc-day-past,
.fc-day-other,
.fc-scrollgrid-sync-inner {
  background: #def3f1 !important; }

.fc-day-today .fc-daygrid-day-frame,
.fc-day-today .fc-scrollgrid-sync-inner {
  background-color: #59c3b7 !important;
  border-radius: 0.5rem; }

.fc-daygrid-day-number {
  margin-left: -2px;
  cursor: pointer; }

.selected-day-bg .fc-daygrid-day-frame {
  background-color: #24304a !important;
  border-radius: 0.5rem; }
  .selected-day-bg .fc-daygrid-day-frame .fc-daygrid-day-number {
    color: white !important; }

.fc-scrollgrid,
.fc-scrollgrid-liquid,
.fc-daygrid,
.fc-dayGridMonth-view,
.fc-view,
.fc-scroller-harness,
.fc-scroller-harness-liquid,
.fc-view-harness,
.fc-view-harness-active,
.fc-media-screen,
.fc-direction-ltr,
.fc-theme-standard {
  border-radius: 1rem; }

.fc-scrollgrid-section td,
.fc-scrollgrid-section-body td,
.fc-scrollgrid-section-liquid td {
  border-color: transparent; }

.fc-col-header-cell {
  border-color: transparent !important; }

.fc-daygrid-day-top {
  text-align: center !important;
  position: absolute;
  left: 25%;
  top: 0; }

.fc-daygrid-event-harness,
.fc-daygrid-event-harness-abs {
  visibility: visible !important; }

.fc-daygrid-day-bottom,
.fc-daygrid-more-link,
.fc-daygrid-event-dot,
.fc-event-time,
.fc-event-title,
.fc-sticky {
  visibility: hidden; }

.fc-daygrid-event {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #16d964 !important;
  box-shadow: 0px 1.76509px 4.41272px #15a55f;
  border: none !important;
  left: 22px;
  bottom: -9px;
  position: absolute; }
  .fc-daygrid-event span {
    font-size: 0.7rem;
    color: #24304a;
    margin-left: 2px;
    bottom: 2px; }

.fc-prev-button,
.fc-next-button {
  background: transparent !important;
  border: none !important; }

.fc-icon-chevron-right,
.fc-icon-chevron-left {
  color: #24304a; }

.fc-toolbar-title {
  font-size: 1.5rem !important; }

.event-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50vh;
  position: relative;
  width: 85%;
  margin: 0 auto; }
  .event-list::-webkit-scrollbar {
    width: 0.4rem; }
  .event-list::-webkit-scrollbar-thumb {
    background-color: #59c3b7;
    border-radius: 10px;
    outline: none; }
  @media screen and (min-width: 960px) {
    .event-list {
      margin: 0 auto; } }

.event-row {
  cursor: pointer;
  margin: 0;
  padding: 0; }

.selected-date {
  font-size: 1.3rem;
  color: #434548;
  font-family: 'Ubuntu', sans-serif;
  width: 70%;
  margin: 1rem 0 1rem 1rem; }

.no-events {
  font-size: 1.4rem;
  color: #434548;
  font-family: 'Ubuntu', sans-serif;
  width: 70%;
  margin: 1rem 0 1rem 1rem; }

.event-title {
  font-size: 1.3rem;
  color: #24304a;
  padding-right: 2rem; }

.event-time {
  font-size: 1rem; }
  @media screen and (max-width: 768px) {
    .event-time {
      font-size: 1.1rem; } }

.event-address {
  font-size: 1.1rem;
  padding-right: 2rem; }

.event-separator {
  margin-bottom: 1rem;
  border-left: 1px solid #59c3b7; }

.status {
  font-size: 1.1rem;
  color: #434548; }
  .status span {
    font-size: 1.1rem;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem; }
  .status .pending {
    background-color: #ffc107;
    color: #1b1f27; }
  .status .in-progress,
  .status .in_progress {
    background-color: #59c3b7;
    color: #1b1f27; }
  .status .done {
    background-color: #16d964;
    color: #1b1f27; }

.modal-header {
  border-bottom: none !important; }

.text-confirm-finish-modal {
  margin-block: 5px 0;
  font-weight: 700; }

.confirm-finish-btn-modal {
  background-color: #ffc107 !important;
  color: #1b1f27 !important; }

@media screen and (max-width: 768px) {
  .event-modal .modal-content {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden; }
    .event-modal .modal-content .modal-body-padding.modal-body {
      overflow-y: hidden;
      overflow-x: hidden; } }

.event-data-alone {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  .event-data-alone a {
    background: transparent;
    min-width: 18rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    color: #1b1f27;
    border: solid 1px; }
  @media (max-width: 500px) {
    .event-data-alone a {
      width: 100%; } }

.event-data-show-task {
  margin-top: 1rem;
  grid-gap: 7px;
  gap: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .event-data-show-task a {
    background: transparent;
    min-width: 18rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    color: #1b1f27;
    border: solid 1px; }
  @media (max-width: 500px) {
    .event-data-show-task a,
    .event-data-show-task .button-notify {
      width: 100%; } }

.event-data-finish-shift {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  color: black; }
  .event-data-finish-shift .not-finished {
    width: 20rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    border: 1px solid #999999;
    background-color: #ffc107;
    color: black; }
    .event-data-finish-shift .not-finished:disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666; }
  .event-data-finish-shift .finished {
    width: 20rem;
    padding: 1rem 0;
    border-radius: 1rem;
    font-size: 1.3rem;
    background-color: #24304a;
    color: white; }
    .event-data-finish-shift .finished:disabled {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666; }

.calendar-bottom {
  width: 100%;
  margin: 0 auto; }

.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

.phone-input .input-group .input-group-prepend {
  height: calc(1.5em + 0.6rem + 4px); }
  .phone-input .input-group .input-group-prepend .input-group-text {
    padding: 0;
    border: none; }
    .phone-input .input-group .input-group-prepend .input-group-text .css-2b097c-container .css-yk16xz-control,
    .phone-input .input-group .input-group-prepend .input-group-text .css-2b097c-container .css-1pahdxg-control,
    .phone-input .input-group .input-group-prepend .input-group-text .css-2b097c-container .css-1fhf3k1-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
      .phone-input .input-group .input-group-prepend .input-group-text .css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer,
      .phone-input .input-group .input-group-prepend .input-group-text .css-2b097c-container .css-1pahdxg-control .css-g1d714-ValueContainer,
      .phone-input .input-group .input-group-prepend .input-group-text .css-2b097c-container .css-1fhf3k1-control .css-g1d714-ValueContainer {
        min-width: 51px; }
    .phone-input .input-group .input-group-prepend .input-group-text .css-26l3qy-menu {
      width: 200%; }
    .phone-input .input-group .input-group-prepend .input-group-text .css-9gakcf-option {
      background-color: #24304a;
      color: #495057; }
      .phone-input .input-group .input-group-prepend .input-group-text .css-9gakcf-option:hover {
        background-color: #24304a;
        color: #495057; }

.carousel {
  position: relative; }
  .carousel .carousel-inner {
    position: absolute;
    width: 100%;
    height: 100%; }
    .carousel .carousel-inner .carousel-item {
      width: 100%;
      height: 100%; }
      .carousel .carousel-inner .carousel-item img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
  .carousel:after {
    content: '';
    padding-bottom: 40%;
    display: block; }

.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

.profile-top {
  position: absolute;
  top: -8rem;
  left: 0;
  height: 28rem;
  width: calc(100% + 1.5rem);
  background: linear-gradient(270.02deg, #081735 -30.74%, #24304a 60.91%, #081735 135.27%);
  color: white;
  margin: 0;
  padding-top: 8rem;
  padding-bottom: 3rem; }
  @media screen and (max-width: 570px) {
    .profile-top {
      width: 100%;
      height: 30rem; } }
  @media screen and (min-width: 570px) {
    .profile-top {
      left: -1.5rem;
      padding-left: 1.5rem; } }
  .profile-top--content {
    padding: 0 1rem;
    display: flex;
    align-items: center; }
  .profile-top .profile-img--container {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 570px) {
      .profile-top .profile-img--container {
        margin-left: 2rem;
        width: 40%; } }
    @media screen and (min-width: 570px) and (max-width: 960px) {
      .profile-top .profile-img--container {
        width: 40%; } }
    @media screen and (min-width: 960px) {
      .profile-top .profile-img--container {
        width: 20%; } }
    .profile-top .profile-img--container .profile-img-dummy {
      width: 12rem;
      height: 12rem;
      background-color: #def3f1;
      border-radius: 6rem;
      display: flex;
      justify-content: center;
      align-items: center; }
      .profile-top .profile-img--container .profile-img-dummy i {
        color: #59c3b7; }
    .profile-top .profile-img--container .profile-img {
      width: 12rem;
      height: 12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 6rem;
      border: 5px solid white; }
      .profile-top .profile-img--container .profile-img img {
        min-width: 100%;
        height: 100%;
        object-fit: cover; }
  .profile-top .profile-info {
    display: flex;
    width: 50%; }
    @media screen and (max-width: 570px) {
      .profile-top .profile-info {
        flex-direction: column;
        margin-top: 1rem;
        margin-left: 2rem;
        width: 80%; } }
    @media screen and (min-width: 570px) and (max-width: 960px) {
      .profile-top .profile-info {
        width: 80%;
        flex-direction: column;
        margin-top: 2rem; } }
    @media screen and (min-width: 960px) {
      .profile-top .profile-info {
        flex-direction: row; } }
    .profile-top .profile-info .profile-data {
      width: 50%;
      margin-top: -3rem; }
      @media screen and (max-width: 570px) {
        .profile-top .profile-info .profile-data {
          width: 100%;
          margin-top: -1rem; } }
      @media screen and (min-width: 570px) and (max-width: 960px) {
        .profile-top .profile-info .profile-data {
          width: 100%; } }
      .profile-top .profile-info .profile-data p {
        color: white;
        font-weight: bold;
        margin: 0.2rem; }
        @media screen and (min-width: 960px) {
          .profile-top .profile-info .profile-data p {
            font-size: 1.5rem; } }
      .profile-top .profile-info .profile-data .rating {
        display: flex;
        justify-content: space-between;
        width: 30%;
        max-width: 12rem;
        margin-top: 0.5rem; }
        .profile-top .profile-info .profile-data .rating-text {
          align-self: center;
          font-size: 1.5rem;
          margin-left: 1rem; }
    .profile-top .profile-info .profile-edit {
      margin-top: -4rem;
      width: 50%; }
      @media screen and (max-width: 570px) {
        .profile-top .profile-info .profile-edit {
          width: 100%;
          margin-top: 0;
          padding: 1rem 0 0 0.5rem; } }
      @media screen and (min-width: 570px) and (max-width: 960px) {
        .profile-top .profile-info .profile-edit {
          width: 100%;
          padding: 1rem 0 0 0.5rem;
          margin-top: 0; } }
      .profile-top .profile-info .profile-edit p {
        color: white;
        margin: 0.2rem; }
        @media screen and (min-width: 960px) {
          .profile-top .profile-info .profile-edit p {
            font-size: 1.5rem; } }
      .profile-top .profile-info .profile-edit .profile-edit-link {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .profile-top .profile-info .profile-edit .profile-edit-link .password {
          background: transparent;
          border: none;
          font-weight: normal;
          margin-left: -1rem;
          font-size: 1.2rem;
          color: white; }
        .profile-top .profile-info .profile-edit .profile-edit-link p {
          font-size: 1.2rem;
          font-weight: normal;
          margin-top: 0.5rem; }
        .profile-top .profile-info .profile-edit .profile-edit-link i {
          margin-right: 1rem;
          color: #59c3b7 !important; }
  .profile-top .profile-data-level {
    margin-left: 21%;
    margin-top: -2rem;
    width: 25%;
    border-radius: 1.3rem;
    background-color: #59c3b7;
    color: #ace1db;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 0; }
    .profile-top .profile-data-level a:hover, .profile-top .profile-data-level a:focus {
      color: white;
      text-decoration: none; }
    @media screen and (max-width: 570px) {
      .profile-top .profile-data-level {
        position: absolute;
        margin-left: 0;
        top: 25rem;
        left: 2rem;
        width: 90%; } }
    @media screen and (min-width: 570px) and (max-width: 960px) {
      .profile-top .profile-data-level {
        position: absolute;
        top: 23rem;
        left: -6rem;
        width: 80%; } }
  .profile-top .profile-payment {
    color: #24304a;
    position: absolute;
    top: 35%;
    right: 5%;
    z-index: 8888;
    width: 30%;
    min-width: 25rem;
    max-width: 30rem;
    height: 11rem;
    background-color: #59c3b7;
    box-shadow: 0px 4px 4px rgba(82, 184, 172, 0.1), 2px 4px 17px rgba(89, 195, 183, 0.6);
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem;
    font-weight: bold; }
    .profile-top .profile-payment__title {
      font-size: 1.8rem;
      width: 60%; }
    .profile-top .profile-payment__price {
      font-size: 2.5rem; }
    @media screen and (min-width: 960px) and (max-width: 1200px) {
      .profile-top .profile-payment {
        right: 2rem; }
        .profile-top .profile-payment__title {
          font-size: 1.6rem; }
        .profile-top .profile-payment__price {
          font-size: 2rem; } }
    @media (max-width: 960px) {
      .profile-top .profile-payment {
        display: none; } }

.profile-inverse-corner {
  position: absolute;
  width: 100%;
  height: 3rem;
  top: 25.1rem;
  left: 0;
  background-color: white;
  border-top-right-radius: 10rem;
  border-top-left-radius: 10rem; }
  @media (min-width: 570px) {
    .profile-inverse-corner {
      width: calc(100% - 1.5rem);
      left: 1.5rem; } }
  @media (max-width: 570px) {
    .profile-inverse-corner {
      height: 2.2rem;
      top: 28rem; } }

.profile-edit {
  padding: 10px 50px 0 40px; }
  @media (max-width: 768px) {
    .profile-edit {
      padding: 10px 50px 0 10px; } }
  @media (max-width: 480px) {
    .profile-edit {
      padding: 10px 30px 0 30px; } }
  @media (max-width: 320px) {
    .profile-edit {
      padding: 10px 30px 0 30px; } }

.profile-avatar {
  display: flex;
  justify-content: center;
  --profile-img-width: 12rem; }
  .profile-avatar .custom-file-avatar {
    width: var(--profile-img-width);
    height: var(--profile-img-width);
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: calc(var(--profile-img-width) / 2);
    border: 5px solid #def3f1; }
    .profile-avatar .custom-file-avatar img {
      min-width: 100%;
      height: 100%;
      object-fit: cover; }

.profile-bottom {
  position: absolute;
  top: 20rem;
  left: 0;
  width: 100%;
  padding-bottom: 3rem;
  font-family: 'Ubuntu'; }
  .profile-bottom__content {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: flex-start; }
    @media screen and (max-width: 768px) {
      .profile-bottom__content {
        flex-wrap: wrap; } }
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      .profile-bottom__content {
        flex-wrap: wrap;
        width: 50%;
        margin: 0 auto; } }
    @media screen and (min-width: 570px) and (max-width: 768px) {
      .profile-bottom__content {
        width: 100%;
        margin-left: 1.5rem;
        align-items: center; } }
    .profile-bottom__content .calendar-container {
      flex-basis: 30%;
      margin: 0 auto 3rem auto;
      box-shadow: 4px 4px 31px rgba(138, 140, 145, 0.2);
      border-radius: 25px;
      padding-bottom: 2rem; }
      @media screen and (max-width: 768px) {
        .profile-bottom__content .calendar-container {
          flex-basis: 100%;
          box-shadow: none; } }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        .profile-bottom__content .calendar-container {
          flex-basis: 30%;
          padding: 0 5rem; } }
    .profile-bottom__content .statistics-container {
      flex-basis: 30%;
      margin: 0 auto 3rem auto; }
      @media screen and (max-width: 768px) {
        .profile-bottom__content .statistics-container {
          flex-basis: 100%; } }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        .profile-bottom__content .statistics-container {
          flex-basis: 100%; } }
      .profile-bottom__content .statistics-container .completed-tasks {
        color: white;
        position: relative;
        z-index: 8888;
        width: 100%;
        height: 11rem;
        background-color: #24304a;
        border-radius: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2.5rem;
        font-weight: bold;
        margin: 0 auto 3rem auto; }
        @media screen and (min-width: 960px) and (max-width: 1200px) {
          .profile-bottom__content .statistics-container .completed-tasks {
            right: 2rem;
            width: 100%; }
            .profile-bottom__content .statistics-container .completed-tasks__title {
              font-size: 1.6rem; } }
        @media screen and (min-width: 570px) {
          .profile-bottom__content .statistics-container .completed-tasks {
            font-size: 1.3rem;
            width: 100%; } }
        @media screen and (min-width: 570px) and (max-width: 768px) {
          .profile-bottom__content .statistics-container .completed-tasks {
            width: 80%;
            margin: 1rem auto; } }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          .profile-bottom__content .statistics-container .completed-tasks {
            margin-left: 2rem; } }
        .profile-bottom__content .statistics-container .completed-tasks__title {
          font-size: 1.8rem;
          width: 60%; }
        .profile-bottom__content .statistics-container .completed-tasks__percentage {
          width: 35%; }
          .profile-bottom__content .statistics-container .completed-tasks__percentage .single-chart {
            width: 90%;
            justify-content: space-around; }
            @media screen and (min-width: 570px) and (max-width: 768px) {
              .profile-bottom__content .statistics-container .completed-tasks__percentage .single-chart {
                width: 60%;
                margin-left: 3rem; } }
          .profile-bottom__content .statistics-container .completed-tasks__percentage .circular-chart {
            display: block;
            margin: 10px auto; }
          .profile-bottom__content .statistics-container .completed-tasks__percentage .circle-bg {
            fill: none;
            stroke: #eee;
            stroke-width: 3.8; }
          .profile-bottom__content .statistics-container .completed-tasks__percentage .circle {
            fill: none;
            stroke-width: 2.8;
            stroke-linecap: round;
            -webkit-animation: progress 1s ease-out forwards;
                    animation: progress 1s ease-out forwards; }

@-webkit-keyframes progress {
  0% {
    stroke-dasharray: 0 100; } }

@keyframes progress {
  0% {
    stroke-dasharray: 0 100; } }
          .profile-bottom__content .statistics-container .completed-tasks__percentage .circular-chart.orange .circle {
            stroke: #ace1db; }
          .profile-bottom__content .statistics-container .completed-tasks__percentage .percentage-value {
            fill: white;
            font-family: sans-serif;
            font-size: 0.5em;
            text-anchor: middle; }
      .profile-bottom__content .statistics-container .tasks-stats {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 1.8rem;
        margin: 2rem 0 3rem 0;
        width: 100%;
        align-items: center;
        text-align: center;
        font-size: 1.3rem; }
        @media screen and (min-width: 570px) and (max-width: 768px) {
          .profile-bottom__content .statistics-container .tasks-stats {
            width: 80%;
            margin: 2rem auto; } }
        .profile-bottom__content .statistics-container .tasks-stats .tasks-square {
          width: 48%;
          background: #def3f1;
          color: #2b3750;
          border-radius: 1.5rem;
          padding: 2rem 1.5rem; }
          .profile-bottom__content .statistics-container .tasks-stats .tasks-square__amount {
            font-size: 2.5rem; }
    .profile-bottom__content .payment-container {
      flex-basis: 33%;
      box-shadow: 4px 4px 31px rgba(138, 140, 145, 0.2);
      border-radius: 25px;
      margin: 0 2rem 3rem 0;
      padding: 2.5rem 1rem;
      font-family: 'Ubuntu';
      font-size: 1.2rem;
      max-height: 41rem;
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 0 auto 3rem auto; }
      .profile-bottom__content .payment-container::-webkit-scrollbar {
        width: 0.4rem; }
      .profile-bottom__content .payment-container::-webkit-scrollbar-thumb {
        background-color: #59c3b7;
        border-radius: 10px;
        outline: none; }
      .profile-bottom__content .payment-container::-webkit-scrollbar-track-piece:end {
        background-color: transparent;
        margin-bottom: 1.5rem; }
      .profile-bottom__content .payment-container::-webkit-scrollbar-track-piece:start {
        background-color: transparent;
        margin-top: 1.5rem; }
      @media screen and (max-width: 570px) {
        .profile-bottom__content .payment-container {
          font-size: 1.3rem;
          flex-basis: 100%;
          box-shadow: none; } }
      @media screen and (min-width: 570px) and (max-width: 768px) {
        .profile-bottom__content .payment-container {
          flex-basis: 90%;
          margin: 2rem auto;
          box-shadow: none; } }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        .profile-bottom__content .payment-container {
          flex-basis: 100%;
          padding: 2rem 5rem; } }
      .profile-bottom__content .payment-container .user-payment-header {
        padding-left: 1rem; }
      .profile-bottom__content .payment-container .user-payment-list {
        display: flex;
        flex-direction: column; }
        .profile-bottom__content .payment-container .user-payment-list .user-payment-row {
          display: flex;
          margin: 1rem; }
          .profile-bottom__content .payment-container .user-payment-list .user-payment-row p:not(:last-child) {
            margin-bottom: 0.6rem !important; }
          .profile-bottom__content .payment-container .user-payment-list .user-payment-row__title {
            flex: 1 1;
            border-right: 1px solid #59c3b7; }
          .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body {
            padding-left: 1rem;
            flex: 3 1; }
            .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body .user-payment-title {
              color: #24304a;
              font-weight: 500; }
            .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body .user-payment-amount-title {
              color: #24304a;
              font-weight: 500; }
            .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body .user-payment-amount {
              background-color: #ace1db;
              padding: 0.5rem;
              color: #24304a;
              font-weight: 500;
              border-radius: 1.5rem;
              margin-left: 1rem; }
            .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body .user-payment-status {
              font-weight: 500;
              color: #24304a; }
              .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body .user-payment-status .user-payment-pending {
                background-color: #24304a;
                padding: 0.5rem;
                color: white;
                border-radius: 1.5rem;
                margin-left: 1rem;
                font-weight: 400;
                font-size: 1.1rem; }
              .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body .user-payment-status .user-payment-approved {
                background-color: #59c3b7;
                padding: 0.5rem;
                color: #24304a;
                border-radius: 1.5rem;
                margin-left: 1rem;
                font-weight: 400;
                font-size: 1.1rem; }
              .profile-bottom__content .payment-container .user-payment-list .user-payment-row__body .user-payment-status .user-payment-rejected {
                background-color: red;
                padding: 0.5rem;
                color: white;
                border-radius: 1.5rem;
                margin-left: 1rem;
                font-weight: 400;
                font-size: 1.1rem; }
      .profile-bottom__content .payment-container .go-to-payments {
        margin: 0 auto;
        width: 100%; }
        .profile-bottom__content .payment-container .go-to-payments a {
          width: 100%;
          border: 1px solid grey;
          text-decoration: none; }
    .profile-bottom__content .profile-payment-mobile {
      color: #24304a;
      position: relative;
      width: 100%;
      height: 11rem;
      background-color: #59c3b7;
      box-shadow: 0px 4px 4px rgba(82, 184, 172, 0.1), 2px 4px 17px rgba(89, 195, 183, 0.6);
      border-radius: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.5rem;
      font-weight: bold;
      margin-bottom: 3rem; }
      .profile-bottom__content .profile-payment-mobile__title {
        font-size: 1.8rem;
        width: 60%; }
      .profile-bottom__content .profile-payment-mobile__price {
        font-size: 2.5rem; }
      @media screen and (min-width: 960px) and (max-width: 1200px) {
        .profile-bottom__content .profile-payment-mobile {
          right: 2rem; }
          .profile-bottom__content .profile-payment-mobile__title {
            font-size: 1.6rem; }
          .profile-bottom__content .profile-payment-mobile__price {
            font-size: 2rem; } }
      @media (min-width: 960px) {
        .profile-bottom__content .profile-payment-mobile {
          display: none; } }
      @media screen and (min-width: 570px) and (max-width: 768px) {
        .profile-bottom__content .profile-payment-mobile {
          width: 80%;
          margin: 2rem auto; } }

@charset "UTF-8";
.green {
  color: #59c3b7; }

.blue {
  color: #24304a; }

.light-blue {
  color: #59c3b7; }

.yellow {
  color: #ffc107; }

.white {
  color: #fff; }

.red {
  color: #bf0e0e; }

.bg-dark-blue {
  background-color: #24304a !important;
  color: #fff !important; }

.bg-light-blue {
  background-color: #59c3b7 !important;
  color: #24304a !important; }

.bg-light-blue-secondary {
  background-color: #ace1db !important;
  color: #24304a !important; }

.bg-green {
  background-color: #16d964 !important;
  color: #24304a !important; }

.bg-warning-yellow {
  background-color: #ffc107 !important;
  color: #24304a !important; }

.bg-danger {
  background-color: #bf0e0e !important;
  color: #fff !important; }

.bg-gray {
  background-color: #adb5bd !important;
  color: #24304a !important; }

.bg-light-blue {
  background-color: #59c3b7;
  color: #1b1f27; }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #24304a;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #bf0e0e;
  --orange: #fd7e14;
  --yellow: #eacb2c;
  --green: #16d964;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #24304a;
  --secondary: #6c757d;
  --success: #16d964;
  --info: #17a2b8;
  --warning: #eacb2c;
  --danger: #bf0e0e;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Ubuntu", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #24304a;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0b0f17;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c2c5cc; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8d93a1; }

.table-hover .table-primary:hover {
  background-color: #b4b8c0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b4b8c0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bef4d4; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #86ebae; }

.table-hover .table-success:hover {
  background-color: #a8f0c6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a8f0c6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9f0c4; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f4e491; }

.table-hover .table-warning:hover {
  background-color: #f7eaad; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f7eaad; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #edbcbc; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #de8282; }

.table-hover .table-danger:hover {
  background-color: #e8a8a8; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e8a8a8; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc( 1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #4e68a0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc( 1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc( 1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #16d964; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(22, 217, 100, 0.9);
  border-radius: 1rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #16d964;
  padding-right: calc( 1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2316d964' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.1875rem) center;
  background-size: calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #16d964;
    box-shadow: 0 0 0 0.2rem rgba(22, 217, 100, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc( 1.5em + 0.75rem);
  background-position: top calc( 0.375em + 0.1875rem) right calc( 0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #16d964;
  padding-right: calc( (1em + 0.75rem) * 3 / 4 + 1.75rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2316d964' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #16d964;
    box-shadow: 0 0 0 0.2rem rgba(22, 217, 100, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #16d964; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #16d964; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #16d964; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #37eb7f;
  background-color: #37eb7f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(22, 217, 100, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #16d964; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #16d964; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #16d964;
  box-shadow: 0 0 0 0.2rem rgba(22, 217, 100, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #bf0e0e; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(191, 14, 14, 0.9);
  border-radius: 1rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #bf0e0e;
  padding-right: calc( 1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23bf0e0e' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23bf0e0e' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.1875rem) center;
  background-size: calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #bf0e0e;
    box-shadow: 0 0 0 0.2rem rgba(191, 14, 14, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc( 1.5em + 0.75rem);
  background-position: top calc( 0.375em + 0.1875rem) right calc( 0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #bf0e0e;
  padding-right: calc( (1em + 0.75rem) * 3 / 4 + 1.75rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23bf0e0e' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23bf0e0e' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") center right 1.75rem/calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #bf0e0e;
    box-shadow: 0 0 0 0.2rem rgba(191, 14, 14, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #bf0e0e; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #bf0e0e; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #bf0e0e; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ee1212;
  background-color: #ee1212; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(191, 14, 14, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #bf0e0e; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #bf0e0e; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #bf0e0e;
  box-shadow: 0 0 0 0.2rem rgba(191, 14, 14, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #24304a;
  border-color: #24304a; }
  .btn-primary:hover {
    color: #fff;
    background-color: #171f30;
    border-color: #131a28; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #171f30;
    border-color: #131a28;
    box-shadow: 0 0 0 0.2rem rgba(69, 79, 101, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #24304a;
    border-color: #24304a; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #131a28;
    border-color: #0f141f; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 79, 101, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #16d964;
  border-color: #16d964; }
  .btn-success:hover {
    color: #fff;
    background-color: #12b654;
    border-color: #11ab4f; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #12b654;
    border-color: #11ab4f;
    box-shadow: 0 0 0 0.2rem rgba(57, 223, 123, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #16d964;
    border-color: #16d964; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #11ab4f;
    border-color: #109f49; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 223, 123, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #eacb2c;
  border-color: #eacb2c; }
  .btn-warning:hover {
    color: #212529;
    background-color: #daba16;
    border-color: #ceb015; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #daba16;
    border-color: #ceb015;
    box-shadow: 0 0 0 0.2rem rgba(204, 178, 44, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #eacb2c;
    border-color: #eacb2c; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ceb015;
    border-color: #c3a613; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 178, 44, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #bf0e0e;
  border-color: #bf0e0e; }
  .btn-danger:hover {
    color: #fff;
    background-color: #9b0b0b;
    border-color: #8f0b0b; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #9b0b0b;
    border-color: #8f0b0b;
    box-shadow: 0 0 0 0.2rem rgba(201, 50, 50, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #bf0e0e;
    border-color: #bf0e0e; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #8f0b0b;
    border-color: #840a0a; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(201, 50, 50, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #24304a;
  border-color: #24304a; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #24304a;
    border-color: #24304a; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #24304a;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #24304a;
    border-color: #24304a; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #16d964;
  border-color: #16d964; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #16d964;
    border-color: #16d964; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 217, 100, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #16d964;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #16d964;
    border-color: #16d964; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 217, 100, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #eacb2c;
  border-color: #eacb2c; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #eacb2c;
    border-color: #eacb2c; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 203, 44, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #eacb2c;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #eacb2c;
    border-color: #eacb2c; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(234, 203, 44, 0.5); }

.btn-outline-danger {
  color: #bf0e0e;
  border-color: #bf0e0e; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #bf0e0e;
    border-color: #bf0e0e; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(191, 14, 14, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #bf0e0e;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #bf0e0e;
    border-color: #bf0e0e; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(191, 14, 14, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #24304a;
  text-decoration: none; }
  .btn-link:hover {
    color: #0b0f17;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 1rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #24304a; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 1rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc( 1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc( 1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #24304a;
    background-color: #24304a; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #4e68a0; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #6a82b7;
    border-color: #6a82b7; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 1rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #24304a;
  background-color: #24304a; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(36, 48, 74, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(36, 48, 74, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(36, 48, 74, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc( 1rem - 4px);
    height: calc( 1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(36, 48, 74, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc( 1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 1rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #4e68a0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc( 1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc( 1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc( 1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc( 1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #4e68a0;
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc( 1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 1rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc( 1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 1rem 1rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #24304a;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #6a82b7; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #24304a;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #6a82b7; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #24304a;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #6a82b7; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 1rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #24304a; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 1rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: white; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: white;
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: white; }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc( 1rem - 1px);
      border-top-right-radius: calc( 1rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc( 1rem - 1px);
      border-bottom-left-radius: calc( 1rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc( 1rem - 1px) calc( 1rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc( 1rem - 1px) calc( 1rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc( 1rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc( 1rem - 1px);
  border-top-right-radius: calc( 1rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc( 1rem - 1px);
  border-bottom-left-radius: calc( 1rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 1rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 1rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #24304a;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0b0f17;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #24304a;
  border-color: #24304a; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #24304a; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #131a28; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(36, 48, 74, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #16d964; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #11ab4f; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 217, 100, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #eacb2c; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ceb015; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(234, 203, 44, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #bf0e0e; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #8f0b0b; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 14, 14, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 1rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #131926;
  background-color: #d3d6db;
  border-color: #c2c5cc; }
  .alert-primary hr {
    border-top-color: #b4b8c0; }
  .alert-primary .alert-link {
    color: #020304; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #0b7134;
  background-color: #d0f7e0;
  border-color: #bef4d4; }
  .alert-success hr {
    border-top-color: #a8f0c6; }
  .alert-success .alert-link {
    color: #06431f; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #7a6a17;
  background-color: #fbf5d5;
  border-color: #f9f0c4; }
  .alert-warning hr {
    border-top-color: #f7eaad; }
  .alert-warning .alert-link {
    color: #4f450f; }

.alert-danger {
  color: #630707;
  background-color: #f2cfcf;
  border-color: #edbcbc; }
  .alert-danger hr {
    border-top-color: #e8a8a8; }
  .alert-danger .alert-link {
    color: #330404; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 1rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #24304a;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 1rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #24304a;
    border-color: #24304a; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 1rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 1rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 1rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 1rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #131926;
  background-color: #c2c5cc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #131926;
    background-color: #b4b8c0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #131926;
    border-color: #131926; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #0b7134;
  background-color: #bef4d4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0b7134;
    background-color: #a8f0c6; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0b7134;
    border-color: #0b7134; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #7a6a17;
  background-color: #f9f0c4; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7a6a17;
    background-color: #f7eaad; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7a6a17;
    border-color: #7a6a17; }

.list-group-item-danger {
  color: #630707;
  background-color: #edbcbc; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #630707;
    background-color: #e8a8a8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #630707;
    border-color: #630707; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 1rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #24304a !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #131a28 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #16d964 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #11ab4f !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #eacb2c !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ceb015 !important; }

.bg-danger {
  background-color: #bf0e0e !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #8f0b0b !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #24304a !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #16d964 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #eacb2c !important; }

.border-danger {
  border-color: #bf0e0e !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 1rem !important; }

.rounded-top {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important; }

.rounded-right {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important; }

.rounded-left {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #24304a !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0b0f17 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #16d964 !important; }

a.text-success:hover, a.text-success:focus {
  color: #0f9444 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #eacb2c !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b79c12 !important; }

.text-danger {
  color: #bf0e0e !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #780909 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.application-list {
  margin-top: 2rem; }

.application-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 2rem;
  margin-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .application-container {
      padding-bottom: 0.5rem; } }

@media screen and (max-width: 768px) {
  .application {
    width: 100%; } }

@media screen and (min-width: 768px) {
  .application {
    width: 80%; } }

@media screen and (min-width: 768px) {
  .main {
    display: flex; } }

.header {
  display: flex; }
  .header--thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem; }
    .header--thumbnail__image {
      width: 100%;
      object-fit: fill; }
  .header--info {
    flex-grow: 1;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 1.4rem;
    font-weight: bold; }
    .header--info__title {
      font-weight: bold;
      margin: 0; }
    .header--info__prices {
      display: flex; }
      .header--info__prices p {
        padding: 0 0.8rem;
        border-radius: 1rem; }
      .header--info__prices .price {
        background-color: #59c3b7;
        margin-right: 0.5rem; }
      .header--info__prices .variable {
        background-color: #ace1db; }
  @media screen and (max-width: 768px) {
    .header {
      margin-bottom: 1rem; }
      .header--thumbnail {
        width: 7.5rem;
        height: 7.5rem; }
      .header--info {
        height: 7.5rem; } }
  @media screen and (min-width: 768px) {
    .header {
      flex: 2 1 60%; }
      .header--thumbnail {
        width: 10rem;
        height: 10rem; }
      .header--info {
        height: 10rem; } }

.campaign-info {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (min-width: 768px) {
    .campaign-info {
      flex: 1 1 40%; } }

.state {
  margin: 1.5rem 0;
  font-size: 1.3rem;
  align-items: center; }
  .state p {
    margin: 0; }
  .state--status {
    display: flex;
    align-items: center; }
    .state--status__title {
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 0.5rem !important; }
    .state--status__text {
      background-color: #59c3b7;
      padding: 0 0.8rem;
      border-radius: 1rem; }
  .state--date {
    display: flex;
    align-items: center; }
    .state--date__text {
      margin-left: 1rem !important; }
  @media screen and (max-width: 768px) {
    .state--status {
      margin-bottom: 1rem; } }
  @media screen and (min-width: 768px) {
    .state {
      display: flex; }
      .state--status {
        flex: 0 2 60%; }
      .state--date {
        flex: 1 1 40%; } }

@media screen and (max-width: 768px) {
  .application-buttons {
    display: flex;
    justify-content: space-between; }
    .application-buttons--secondary {
      flex: 0 1 35%; }
      .application-buttons--secondary .hidden {
        visibility: hidden; }
    .application-buttons--primary {
      flex: 0 2 60%; } }

@media screen and (min-width: 768px) {
  .application-buttons {
    position: absolute;
    top: 10%;
    right: 0;
    width: 18%; }
    .application-buttons--secondary {
      margin-bottom: 0.5rem; }
      .application-buttons--secondary .hidden {
        display: none; }
    .application-buttons * > button {
      font-size: 1.2rem; } }

@media screen and (min-width: 768px) {
  .application-buttons--secondary.task-show {
    width: calc(33% - 1rem);
    float: right; } }

.application-buttons--secondary.task-show button {
  height: 4rem; }

.task-container {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  position: relative;
  border-bottom: 1px solid #dee2e6; }

.task {
  color: #24304a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 10rem;
  z-index: 999;
  overflow: hidden; }
  @media screen and (min-width: 1200px) {
    .task {
      width: 75%; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .task {
      width: 78%; } }
  @media screen and (min-width: 768px) {
    .task {
      min-height: 12rem; } }
  .task--thumbnail {
    --thumbnail-width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    width: var(--thumbnail-width);
    height: var(--thumbnail-width);
    border-radius: calc(var(--thumbnail-width) / 2);
    overflow: hidden; }
    .task--thumbnail__image {
      width: 100%;
      object-fit: fill; }
    @media screen and (min-width: 768px) {
      .task--thumbnail {
        --thumbnail-width: 8rem; } }
  .task.map-list .task--thumbnail {
    justify-content: center; }
    .task.map-list .task--thumbnail__image {
      width: 100%;
      height: 100%;
      max-width: 7rem;
      max-height: 7rem;
      object-fit: contain;
      border-radius: 1rem; }
    @media screen and (min-width: 1200px) {
      .task.map-list .task--thumbnail {
        width: 25%; }
        .task.map-list .task--thumbnail__image {
          max-width: 12rem;
          min-height: 12rem; } }
  .task--info {
    display: flex;
    width: calc(100% - 8rem);
    margin-left: 1rem;
    flex-direction: column;
    justify-content: flex-start; }
    @media screen and (min-width: 768px) {
      .task--info {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; } }

.info--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  max-height: 3rem;
  overflow-y: visible; }
  @media screen and (min-width: 768px) {
    .info--header {
      width: 50%;
      justify-content: space-between;
      align-items: center; }
      .info--header__title {
        max-width: 70%; } }
  .info--header__title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .info--header__title-container i {
      margin-right: 2rem; }
  .info--header__title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 0.5rem;
    width: 60%; }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .info--header__title {
        margin-right: 0.5rem;
        font-size: 1.4rem; } }
    @media screen and (min-width: 1200px) {
      .info--header__title {
        margin-right: 0.5rem;
        font-size: 1.6rem; } }
  .info--header__prices {
    margin-top: 0.5rem;
    display: flex;
    max-width: 40%;
    flex-direction: column; }
    .info--header__prices p {
      text-align: center; }
      @media screen and (min-width: 768px) and (max-width: 960px) {
        .info--header__prices p {
          font-size: 1.1rem;
          font-weight: bold; } }
  .info--header__price {
    height: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #ace1db;
    padding: 0.3rem 0.8rem;
    border-radius: 1.5rem;
    margin-bottom: 0.5rem; }
    @media screen and (min-width: 960px) {
      .info--header__price {
        font-size: 1.3rem; } }
    @media screen and (min-width: 768px) and (max-width: 960px) {
      .info--header__price {
        font-size: 1.2rem; } }
    @media screen and (min-width: 768px) {
      .info--header__price {
        padding: 0.5rem 1.1rem; } }
    @media screen and (max-width: 768px) {
      .info--header__price {
        height: 100%; } }
  .info--header__variable {
    height: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #def3f1;
    padding: 0.3rem 0.8rem;
    border-radius: 1.5rem;
    margin-bottom: 0; }
    @media screen and (min-width: 960px) {
      .info--header__variable {
        font-size: 1.3rem; } }
    @media screen and (min-width: 768px) and (max-width: 960px) {
      .info--header__variable {
        font-size: 1.2rem; } }
    @media screen and (min-width: 768px) {
      .info--header__variable {
        padding: 0.5rem 1rem; } }
    @media screen and (max-width: 768px) {
      .info--header__variable {
        height: 100%; } }

.info--body p {
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
  padding: 0; }
  @media screen and (min-width: 768px) {
    .info--body p {
      font-size: 1.3rem; } }

@media screen and (min-width: 768px) {
  .info--body {
    margin-left: 1rem; }
    .info--body__dates {
      font-size: 1.2rem; } }

@media screen and (max-width: 768px) {
  .info--body {
    width: 80%; } }

.task--description {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.62, 0.03, 0.47, 1.01);
  margin: 0; }
  .task--description.show {
    margin-top: 1.5rem;
    opacity: 1;
    max-height: 25rem; }
  .task--description .section {
    margin-bottom: 2rem; }
    .task--description .section--title {
      font-weight: bold;
      font-size: 1.4rem; }
    .task--description .section--body {
      font-size: 1.3rem; }

.task-buttons .primary-btn {
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center; }

@media screen and (max-width: 768px) {
  .task-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem; }
    .task-buttons--secondary {
      flex: 0 1 40%; }
    .task-buttons--primary {
      flex: 0 2 55%; } }

@media screen and (min-width: 768px) {
  .task-buttons {
    position: absolute;
    right: 0;
    top: 3rem;
    width: 19%; }
    .task-buttons--primary {
      margin-bottom: 0.5rem; }
    .task-buttons * > button {
      font-size: 1.2rem; } }

.task-container.show-view {
  border-bottom: none; }
  .task-container.show-view .task {
    height: 12rem; }
  .task-container.show-view .task--description {
    max-height: 800rem; }

.task-container.keep-mobile {
  padding: 0 1rem; }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task {
      width: 100%;
      height: 12rem; } }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task--thumbnail {
      max-width: 10rem;
      max-height: 10rem; }
      .task-container.keep-mobile .task--thumbnail__image {
        object-fit: fill;
        width: 100%;
        max-height: 100%; } }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task--info {
      width: 65%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: initial; } }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task--info .info--header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      max-height: 100%; }
      .task-container.keep-mobile .task--info .info--header__prices {
        flex-direction: row; } }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task--info .info--header__title {
      font-size: 1.3rem; } }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task--info .info--header__price {
      font-size: 1.2rem;
      font-weight: bold;
      background-color: #ace1db;
      padding: 0.3rem 0.8rem;
      border-radius: 1.5rem;
      margin-bottom: 0; } }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task--info .info--header__variable {
      font-size: 1.2rem;
      font-weight: bold;
      background-color: #def3f1;
      padding: 0.3rem 0.8rem;
      border-radius: 1.5rem;
      margin-bottom: 0; } }
  @media screen and (min-width: 1200px) {
    .task-container.keep-mobile .task--info .info--body p {
      font-size: 1.2rem;
      margin-bottom: 0.2rem; }
      .task-container.keep-mobile .task--info .info--body p:nth-child(2) {
        display: none; } }
  .task-container.keep-mobile .task--description.show {
    max-height: 100rem; }
  .task-container.keep-mobile .task--description .section .section--body {
    font-size: 1.2rem; }
  @media screen and (min-width: 768px) {
    .task-container.keep-mobile .task-buttons {
      top: 0 !important;
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem; }
      .task-container.keep-mobile .task-buttons--secondary {
        flex: 0 1 35%; }
        .task-container.keep-mobile .task-buttons--secondary button {
          font-size: 1rem !important;
          height: 3.5rem; }
      .task-container.keep-mobile .task-buttons--primary {
        flex: 0 2 60%; }
        .task-container.keep-mobile .task-buttons--primary a {
          font-size: 1rem !important;
          height: 3.5rem;
          line-height: 2.5rem; } }

@media screen and (max-width: 768px) {
  .task-container.map-list {
    padding: 0.5rem 1rem; }
    .task-container.map-list .task {
      height: 10rem; }
    .task-container.map-list .task--description .section--title {
      font-size: 1.3rem; }
    .task-container.map-list .task--description .section--body {
      font-size: 1.2rem; }
    .task-container.map-list .task-buttons {
      margin-bottom: 1rem; }
      .task-container.map-list .task-buttons--secondary {
        flex: 0 1 35%; }
        .task-container.map-list .task-buttons--secondary button {
          font-size: 1rem !important;
          height: 3.5rem; }
      .task-container.map-list .task-buttons--primary {
        flex: 0 2 60%; }
        .task-container.map-list .task-buttons--primary a {
          font-size: 1rem !important;
          height: 3.5rem;
          line-height: 2.5rem; } }

@media screen and (min-width: 768px) {
  .task-container.map-list {
    padding: 0.5rem 1rem; }
    .task-container.map-list .task--thumbnail {
      max-width: 7rem;
      max-height: 7rem; }
    .task-container.map-list .task {
      height: 10rem; }
    .task-container.map-list .task-buttons {
      top: 2rem; }
      .task-container.map-list .task-buttons--secondary button {
        font-size: 1rem !important; }
      .task-container.map-list .task-buttons--primary a {
        font-size: 1rem !important; } }
  @media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 960px) {
    .task-container.map-list .task .task--info {
      align-items: initial; }
      .task-container.map-list .task .task--info .info--header {
        flex-direction: column; }
        .task-container.map-list .task .task--info .info--header__prices {
          flex-direction: row; } }

@media screen and (min-width: 768px) {
    .task-container.map-list .task .task--info .info--header__title {
      font-size: 1.3rem;
      margin-right: 0; }
    .task-container.map-list .task .task--info .info--header__price {
      font-size: 1rem;
      padding: 0.2rem 0.5rem; }
    .task-container.map-list .task .task--info .info--header__variable {
      font-size: 1rem;
      padding: 0.2rem 0.5rem; }
    .task-container.map-list .task .task--info .info--body p {
      font-size: 1.2rem;
      margin-bottom: 0.2rem; }
    .task-container.map-list .task--description .section .section--title {
      font-size: 1.3rem; }
    .task-container.map-list .task--description .section .section--body {
      font-size: 1.2rem; } }

.shift-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 4rem; }
  @media screen and (max-width: 768px) {
    .shift-list {
      margin-bottom: 2rem; } }
  @media screen and (min-width: 768px) and (max-width: 960px) {
    .shift-list {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 1rem;
      grid-column-gap: 1rem; } }
  @media screen and (max-width: 768px) {
    .shift-list {
      grid-template-columns: 1fr;
      grid-row-gap: 1rem; } }

.shift-item {
  background: #def3f1;
  font-size: 1.2rem;
  border-radius: 1.5rem;
  padding: 1rem 2rem; }
  @media screen and (max-width: 768px) {
    .shift-item {
      margin-bottom: 0.5rem;
      font-size: 1.2rem; } }

.btn-apply-to-task {
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end; }
  .btn-apply-to-task button {
    font-size: 1.5rem;
    padding: 0.8rem 3rem;
    border-radius: 1rem;
    border-color: transparent; }
    .btn-apply-to-task button:hover {
      color: white;
      border-color: transparent;
      background-color: #24304a; }
  .btn-apply-to-task .btn-notify {
    background-color: #16d964;
    color: #1b1f27; }

.apply-to-task {
  display: flex;
  justify-content: flex-end; }
  .apply-to-task button {
    font-size: 1.5rem;
    padding: 0.8rem 3rem;
    border-radius: 1rem;
    border-color: transparent;
    width: calc(33.333% - 1rem);
    text-transform: uppercase; }
    .apply-to-task button:hover {
      color: #24304a;
      border-color: transparent;
      background-color: #ffc107; }
    @media screen and (min-width: 768px) and (max-width: 960px) {
      .apply-to-task button {
        width: calc(50% - 0.5rem); } }
    @media screen and (max-width: 768px) {
      .apply-to-task button {
        width: 100%; } }

.apply-message {
  margin-top: 4rem; }
  @media screen and (max-width: 768px) {
    .apply-message {
      margin-top: 2rem; } }
  .apply-message--title {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .apply-message--title p {
      margin: 0;
      font-size: 1.6rem;
      font-weight: bold; }
  .apply-message--body {
    margin-top: 0.5rem; }

.highlighted-payment {
  width: 100%;
  color: white;
  background-color: #24304a;
  box-shadow: 2px 4px 13px rgba(200, 218, 216, 0.4);
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem; }
  .highlighted-payment p:nth-child(2) {
    font-weight: 600; }
  @media screen and (min-width: 768px) {
    .highlighted-payment {
      width: 31.5%; }
      .highlighted-payment p:nth-child(2) {
        font-size: 1.5rem;
        font-weight: 600; } }

.repeteable-task {
  background-color: #ffc107;
  color: #24304a;
  padding: 0.3rem 0.8rem;
  border-radius: 1rem;
  margin: 0 0.5rem;
  font-weight: normal; }

@media screen and (min-width: 768px) {
  .modal-sm {
    max-width: 45rem !important; } }

.modal-content {
  padding: 2.5rem 3rem;
  border-radius: 2.5rem; }
  @media screen and (max-width: 570px) {
    .modal-content {
      width: 90%;
      max-height: 70%;
      padding: 1rem;
      overflow-y: hidden; }
      .modal-content .modal-body-padding.modal-body {
        overflow-y: auto !important; } }
  .modal-content .modal-close-icon {
    position: absolute;
    top: 3rem;
    right: 2.5rem; }
    @media screen and (max-width: 570px) {
      .modal-content .modal-close-icon {
        top: 1.5rem;
        right: 1.5rem; } }

@media screen and (max-width: 570px) {
  .fade.modal.show {
    width: 100vw;
    height: 100vh; }
  .modal.show .modal-dialog {
    height: 100vh;
    justify-content: center;
    overflow: hidden; } }

@media screen and (max-width: 768px) {
  .password-modal .modal-content {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden; }
    .password-modal .modal-content .modal-body-padding.modal-body {
      overflow-y: hidden;
      overflow-x: hidden; } }

.sidebar {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 2rem 0;
  height: 100vh;
  min-width: 23rem;
  max-width: 23rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 15vw;
  z-index: 1032;
  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.35);
  background-color: #24304a; }
  @media screen and (min-width: 768px) and (max-width: 960px) {
    .sidebar {
      min-width: 20rem; } }
  @media screen and (min-width: 570px) and (max-width: 768px) {
    .sidebar {
      min-width: 18rem; } }
  @media screen and (max-width: 570px) {
    .sidebar {
      box-sizing: border-box;
      min-height: 0;
      width: 50vw;
      height: 100vh;
      border-radius: 0;
      opacity: 0;
      z-index: -1;
      transition: 0.5s; }
      .sidebar.show {
        opacity: 1;
        z-index: 100000000; }
      .sidebar .logout-link {
        bottom: 6rem !important; } }
  .sidebar .nav {
    flex-direction: column; }
    .sidebar .nav .nav-link {
      color: #fff;
      font-weight: 400;
      font-size: 1.6rem;
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      width: 80%;
      padding: 1.2rem 0;
      transition: 0.3s;
      border: 2px solid transparent;
      box-sizing: border-box; }
      .sidebar .nav .nav-link .link-menu {
        padding: 1.2rem 3rem; }
      .sidebar .nav .nav-link.disabled {
        opacity: 0.4; }
      .sidebar .nav .nav-link.active {
        background-color: #59c3b7;
        font-weight: 700; }
        .sidebar .nav .nav-link.active:hover, .sidebar .nav .nav-link.active:focus {
          color: white; }
      .sidebar .nav .nav-link:hover, .sidebar .nav .nav-link:focus {
        font-weight: 700; }
      .sidebar .nav .nav-link:hover {
        color: #59c3b7; }
        .sidebar .nav .nav-link:hover.active {
          color: white; }
      .sidebar .nav .nav-link:focus {
        background-color: #59c3b7;
        outline: none;
        border: 2px solid #ace1db; }
        .sidebar .nav .nav-link:focus.active {
          border: 2px solid transparent; }
  .sidebar .logout-link {
    position: absolute;
    bottom: 3rem; }
    .sidebar .logout-link .nav-link {
      width: 100% !important; }
  .sidebar .close-box {
    position: absolute;
    top: 1.5rem;
    right: 1rem; }
    @media screen and (min-width: 570px) {
      .sidebar .close-box {
        display: none; } }

.navbar-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  padding: 0 2rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 8000;
  background-color: transparent; }
  .navbar-top--left {
    width: 60%;
    display: flex;
    align-items: center; }
    .navbar-top--left__logo {
      width: 10rem; }
      .navbar-top--left__logo .img {
        width: 100%; }
  .navbar-top--right {
    width: 40%;
    display: flex;
    justify-content: flex-end; }
  @media screen and (min-width: 570px) {
    .navbar-top {
      margin-top: 0;
      padding-right: 10%; }
      .navbar-top--left {
        visibility: hidden; }
      .navbar-top--right {
        width: 10rem;
        display: flex;
        justify-content: space-between; } }
  @media screen and (min-width: 960px) and (max-width: 1200px) {
    .navbar-top {
      padding-right: 2.5%; } }
  @media screen and (min-width: 768px) and (max-width: 960px) {
    .navbar-top {
      padding-right: 2rem; } }
  @media screen and (min-width: 570px) and (max-width: 768px) {
    .navbar-top {
      padding-right: 2rem; } }

.cover-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.5s;
  z-index: 0;
  display: none; }
  .cover-screen.show {
    opacity: 1;
    z-index: 9999;
    display: block; }

.icon-md {
  -webkit-transform: scale(1.4) !important;
          transform: scale(1.4) !important; }

.landing {
  background-color: transparent; }

.section-title {
  font-weight: bold;
  text-align: center; }
  @media screen and (max-width: 1200px) {
    .section-title {
      font-size: 2rem;
      margin-bottom: 3rem; } }
  @media screen and (min-width: 1200px) {
    .section-title {
      font-size: 3rem;
      margin-bottom: 4rem; } }

.landing--main {
  display: grid;
  overflow-y: hidden;
  height: calc(100vh - 8rem) !important; }
  @media screen and (min-width: 1200px) {
    .landing--main {
      grid-template-columns: 2fr 3fr;
      height: calc(100vh - 8rem) !important; } }
  .landing--main .info {
    background-color: #59c3b7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15%;
    max-height: calc(100vh - 8rem) !important; }
    .landing--main .info p {
      text-shadow: 1px 1px 8px #333; }
    @media screen and (max-width: 1200px) {
      .landing--main .info {
        padding: 6rem 10%;
        height: calc(100vh - 8rem);
        min-height: 40rem;
        background-color: transparent;
        z-index: 100; } }
  .landing--main .catchphrase {
    font-weight: bold;
    color: white; }
    @media screen and (max-width: 1200px) {
      .landing--main .catchphrase {
        font-size: 2.5rem;
        margin-bottom: 3rem;
        letter-spacing: 0.1rem;
        text-align: center; } }
    @media screen and (min-width: 1200px) {
      .landing--main .catchphrase {
        font-size: 3rem;
        margin-bottom: 4rem;
        letter-spacing: 0.3rem; } }
  .landing--main .sub-catchphrase {
    color: white; }
    @media screen and (max-width: 1200px) {
      .landing--main .sub-catchphrase {
        font-size: 1.6rem;
        text-align: center;
        width: 80%;
        margin: 0 auto 4rem auto; } }
    @media screen and (min-width: 1200px) {
      .landing--main .sub-catchphrase {
        width: 80%;
        letter-spacing: 0.1rem;
        margin-bottom: 6rem;
        font-size: 1.8rem; } }
  .landing--main__btn {
    height: 6rem !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 1200px) {
      .landing--main__btn {
        font-size: 1.6rem !important;
        width: 80% !important;
        max-width: 35rem;
        margin: 0 auto; } }
    @media screen and (min-width: 1200px) {
      .landing--main__btn {
        font-size: 1.8rem !important;
        width: 50% !important;
        min-width: 30rem; } }
  @media screen and (max-width: 1200px) {
    .landing--main .carousel-container {
      position: absolute;
      top: 0;
      z-index: 99;
      width: 100%;
      -webkit-filter: brightness(60%);
              filter: brightness(60%);
      opacity: 0.75; }
      .landing--main .carousel-container .carousel-control-prev,
      .landing--main .carousel-container .carousel-control-next {
        visibility: hidden; } }
  .landing--main .landing-carousel {
    height: calc(100vh - 8rem) !important;
    overflow-y: hidden;
    background: linear-gradient(to bottom, #24304a, #59c3b7); }
  .landing--main .landing-carousel-image {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    opacity: 0.7;
    mix-blend-mode: luminosity; }
  @media screen and (max-width: 1200px) {
    .landing--main .carousel-caption {
      display: none; } }
  @media screen and (min-width: 1200px) {
    .landing--main .carousel-caption {
      bottom: 10rem !important; }
      .landing--main .carousel-caption--title {
        font-size: 3rem !important; }
      .landing--main .carousel-caption--subtitle {
        font-size: 1.6rem !important; } }

.landing--how-it-works {
  background-color: #def3f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .landing--how-it-works {
      padding: 3rem 5%; } }
  @media screen and (min-width: 1200px) {
    .landing--how-it-works {
      min-height: 60rem;
      padding: 15rem 15%;
      max-height: calc(100vh - 8rem); } }
  .landing--how-it-works--title {
    margin-bottom: 4rem; }
  .landing--how-it-works--cards {
    display: grid; }
    @media screen and (max-width: 1200px) {
      .landing--how-it-works--cards {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr); } }
    @media screen and (min-width: 1200px) {
      .landing--how-it-works--cards {
        grid-template-columns: repeat(4, 1fr); } }
    .landing--how-it-works--cards .card {
      --card-title-font-size: 1.8rem;
      --card-text-font-size: 1.4rem;
      --card-icon-height: 8rem;
      background-color: transparent;
      border: none; }
      .landing--how-it-works--cards .card .card-icon {
        display: grid;
        justify-content: center;
        align-items: center;
        height: var(--card-icon-height); }
      .landing--how-it-works--cards .card .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start; }
      .landing--how-it-works--cards .card--title {
        font-weight: bold;
        font-size: var(--card-title-font-size);
        margin-bottom: calc(var(--card-text-font-size) * 1.5); }
      .landing--how-it-works--cards .card--subtitle {
        font-size: var(--card-text-font-size);
        width: 80%; }
      .landing--how-it-works--cards .card--title, .landing--how-it-works--cards .card--subtitle {
        text-align: center; }
      @media screen and (max-width: 1200px) {
        .landing--how-it-works--cards .card {
          --card-title-font-size: 1.6rem;
          --card-text-font-size: 1.2rem;
          --card-icon-height: 5rem; }
          .landing--how-it-works--cards .card--subtitle {
            width: 100%; }
          .landing--how-it-works--cards .card .card-icon i {
            -webkit-transform: scale(1.5);
                    transform: scale(1.5); } }

@media screen and (max-width: 1200px) {
  .landing--tasks {
    padding: 3rem 5%; }
    .landing--tasks .tasks {
      width: 80%;
      margin: 0 auto; } }

@media screen and (min-width: 1200px) {
  .landing--tasks {
    padding: 5rem 10%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 50rem; } }

.landing--tasks .phone {
  display: none; }
  @media screen and (min-width: 1200px) {
    .landing--tasks .phone {
      display: block;
      width: 100%; }
      .landing--tasks .phone img {
        position: absolute;
        -webkit-transform: translateY(-15rem);
                transform: translateY(-15rem);
        width: 30rem; } }

.task-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10rem;
  -webkit-column-gap: 10rem;
          column-gap: 10rem;
  padding: 0 1rem; }
  @media screen and (max-width: 1200px) {
    .task-cards {
      grid-template-columns: 1fr; } }

@media screen and (max-width: 1200px) {
  .tasks .carousel {
    min-height: 55rem !important; } }

@media screen and (min-width: 1200px) {
  .tasks .carousel {
    max-height: 45rem !important; } }

.tasks .carousel .carousel-control-prev,
.tasks .carousel .carousel-control-next {
  visibility: hidden; }

.task-card {
  --card-title-font-size: 1.6rem;
  --card-text-font-size: 1.3rem; }
  .task-card .main {
    flex-direction: column;
    margin-bottom: 2rem; }
  .task-card .header {
    margin-bottom: var(--card-title-font-size); }
  .task-card .header--info__title {
    font-size: var(--card-title-font-size); }
  .task-card .header--info__prices p {
    font-size: calc(1.4) !important;
    font-size: calc(var(--card-text-font-size * 1.4)) !important; }
  .task-card .campaign-info p {
    font-size: var(--card-text-font-size) !important;
    margin-bottom: calc(var(--card-text-font-size) * 0.5) !important; }
  @media screen and (max-width: 1200px) {
    .task-card {
      --card-title-font-size: 1.6rem;
      --card-text-font-size: 1.4rem;
      margin-bottom: 3rem; } }

.landing--benefits {
  background-color: #24304a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .landing--benefits {
      padding: 3rem 5%;
      min-height: 60rem; } }
  @media screen and (min-width: 1200px) {
    .landing--benefits {
      padding: 5rem 15%; } }
  .landing--benefits--title {
    color: white;
    margin-bottom: 5rem; }
  .landing--benefits .benefits {
    display: grid; }
    @media screen and (max-width: 1200px) {
      .landing--benefits .benefits {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-row-gap: 1rem;
        row-gap: 1rem;
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem; } }
    @media screen and (min-width: 1200px) {
      .landing--benefits .benefits {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 10rem;
        -webkit-column-gap: 10rem;
                column-gap: 10rem; } }
  .landing--benefits .benefit-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .landing--benefits .benefit-card--text {
      color: white;
      width: 80%;
      text-align: center;
      font-size: var(--card-text-font-size); }
    .landing--benefits .benefit-card .icon-container {
      --container-diameter: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #59c3b7;
      width: var(--container-diameter);
      height: var(--container-diameter);
      border-radius: calc(var(--container-diameter) / 2);
      margin-bottom: 4rem; }
      @media screen and (max-width: 1200px) {
        .landing--benefits .benefit-card .icon-container {
          --container-diameter: 12rem;
          margin-bottom: 2rem; } }

.landing--feedbacks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .landing--feedbacks {
      padding: 3rem 5%; } }
  @media screen and (min-width: 1200px) {
    .landing--feedbacks {
      padding: 5rem 15%; } }

.feedbacks {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 30rem; }
  .feedbacks-carousel {
    width: 80%;
    min-height: 45rem; }
    .feedbacks-carousel .carousel-control-prev,
    .feedbacks-carousel .carousel-control-next {
      -webkit-filter: invert(100%);
              filter: invert(100%); }
    @media screen and (max-width: 1200px) {
      .feedbacks-carousel {
        width: 100%;
        min-height: 45rem; } }

.feedback-container {
  display: flex;
  justify-content: center; }

.feedback {
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .feedback {
      max-width: 80%;
      margin: 0 auto; } }
  .feedback-photo {
    box-sizing: border-box;
    --feedback-profile-img: 12rem;
    width: var(--feedback-profile-img);
    height: var(--feedback-profile-img);
    border-radius: calc(var(--feedback-profile-img) / 2);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 2rem;
    border: 6px solid #ace1db; }
    .feedback-photo img {
      width: 100%; }
    @media screen and (max-width: 1200px) {
      .feedback-photo {
        --feedback-profile-img: 12rem; } }
  .feedback-info p {
    text-align: center;
    font-size: var(--card-text-font-size); }
    .feedback-info p:not(:last-child) {
      margin-bottom: 1.5rem;
      font-size: var(--card-text-font-size) !important; }
  .feedback-info--name {
    font-weight: bold; }
  @media screen and (max-width: 1200px) {
    .feedback-info--text {
      width: 80%;
      margin: 0 auto; } }

@media screen and (max-width: 1200px) {
  .landing--companies {
    padding: 3rem 5%;
    min-height: 25rem; } }

@media screen and (min-width: 1200px) {
  .landing--companies {
    min-height: 40rem;
    padding: 5rem 0;
    display: grid; } }

.companies {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  @media screen and (max-width: 1200px) {
    .companies {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 2rem;
      row-gap: 2rem;
      padding: 0 1rem; } }

.company {
  --company-logo-size: 12rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .company {
      --company-logo-size: 6rem; } }
  @media screen and (min-width: 1200px) {
    .company {
      min-width: 20rem; } }
  .company-img-container {
    width: var(--company-logo-size);
    height: var(--company-logo-size);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    -webkit-clip-path: circle(calc(var(--company-logo-size) / 2 * 0.95) at 50% 50%);
            clip-path: circle(calc(var(--company-logo-size) / 2 * 0.95) at 50% 50%); }
    .company-img-container img {
      min-width: 100%;
      height: 100%;
      object-fit: cover; }

.landing--footer {
  background-color: #59c3b7; }
  @media screen and (max-width: 1200px) {
    .landing--footer {
      padding: 3rem 5%;
      min-height: 25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  @media screen and (min-width: 1200px) {
    .landing--footer {
      padding: 5rem 10%;
      display: grid;
      grid-template-columns: 2fr 7fr 1fr; }
      .landing--footer .footer-logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%; }
        .landing--footer .footer-logo img {
          width: 100%; } }

.offices {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5rem;
  -webkit-column-gap: 5rem;
          column-gap: 5rem;
  --text-size: 1.5rem; }
  @media screen and (max-width: 1200px) {
    .offices {
      --text-size: 1.4rem;
      display: flex;
      flex-direction: column; }
      .offices p {
        width: 100% !important;
        margin-bottom: 1rem !important; }
      .offices .office {
        margin-bottom: 3rem; } }
  .offices .office {
    padding: 0 2rem; }
    .offices .office p {
      font-size: var(--text-size);
      width: 80%;
      margin-bottom: 1.5rem; }
    .offices .office-country {
      font-weight: bold;
      margin-bottom: 2rem !important; }
      @media screen and (max-width: 1200px) {
        .offices .office-country {
          margin-bottom: 1rem !important; } }
    .offices .office-detail {
      display: grid;
      grid-template-columns: 3rem 1fr; }
      .offices .office-detail i {
        margin-top: 0.5rem; }

.social-media {
  display: flex;
  align-items: center;
  flex-direction: column;
  --text-size: 1.8rem; }
  .social-media p {
    font-size: var(--text-size) !important;
    font-weight: bold;
    margin-bottom: 2rem; }
  .social-media--links {
    display: grid;
    grid-template-columns: 1fr 1fr; }
    .social-media--links .social-link {
      margin: 0 1.5rem; }
      .social-media--links .social-link i {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); }

p {
  margin: 0; }

.carousel {
  max-height: 100%;
  --indicator-size: 1.5rem;
  --indicators-margin: 3rem; }
  .carousel .carousel-indicators {
    bottom: var(--indicators-margin) !important; }
    .carousel .carousel-indicators li {
      width: var(--indicator-size);
      height: var(--indicator-size);
      border-radius: 100%;
      background-color: #999;
      margin: 0 var(--indicators-margin); }
      .carousel .carousel-indicators li.active {
        background-color: #59c3b7; }
    @media screen and (max-width: 1200px) {
      .carousel .carousel-indicators {
        --indicator-size: 1rem;
        --indicators-margin: 1rem; } }

.landing-navbar {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: #24304a;
  color: white;
  position: fixed;
  border-bottom: 2px solid rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  z-index: 999; }

.brand-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%; }
  @media screen and (max-width: 960px) {
    .brand-title img {
      width: 60% !important; } }
  @media screen and (min-width: 960px) {
    .brand-title img {
      width: 80% !important; } }

.toggle-button {
  display: none;
  box-sizing: border-box;
  width: 4.5rem;
  height: 4.5rem;
  background-color: #59c3b7;
  border: 2px solid white;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  transition: 0.3s; }
  @media screen and (max-width: 960px) {
    .toggle-button {
      display: flex; } }
  .toggle-button:hover {
    background-color: #91d7cf; }

.toggle-bar {
  display: block;
  width: 2rem;
  height: 3px;
  background-color: white;
  transition: opacity 0.1s;
  position: absolute; }
  .toggle-bar::after, .toggle-bar::before {
    content: '';
    background-color: white;
    display: block;
    width: 2rem;
    height: 3px;
    transition: all 0.2s ease-out;
    position: absolute; }
  .toggle-bar::before {
    -webkit-transform: translateY(-0.7rem);
            transform: translateY(-0.7rem); }
  .toggle-bar::after {
    -webkit-transform: translateY(0.7rem);
            transform: translateY(0.7rem); }
  .toggle-bar.open {
    background-color: transparent; }
  .toggle-bar::after.open, .toggle-bar::before.open {
    top: 0; }
  .toggle-bar.open::before {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  .toggle-bar.open::after {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }

@media screen and (max-width: 960px) {
  .navbar-links {
    position: absolute;
    left: 0;
    top: 8rem;
    background-color: #35466c;
    width: 100%;
    transition: max-height 0.4s ease-out;
    max-height: 0;
    overflow-y: hidden; }
    .navbar-links .navbar-links--list {
      flex-direction: column;
      transition: 0.2s;
      opacity: 0; }
    .navbar-links.open {
      max-height: 480px;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 2rem; }
      .navbar-links.open .navbar-links--list {
        opacity: 1; } }

@media screen and (min-width: 960px) {
  .navbar-links {
    height: 100%; } }

.navbar-links--list {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  height: 100%; }

.navbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  padding: 0 1rem; }
  .navbar-item:hover {
    background-color: #3d517d; }
    .navbar-item:hover a {
      color: #59c3b7; }
    .navbar-item:hover.as-btn a {
      color: white; }
  @media screen and (max-width: 960px) {
    .navbar-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
      .navbar-item:last-child {
        border-bottom: none; } }
  @media screen and (min-width: 960px) and (max-width: 1200px) {
    .navbar-item {
      padding: 0 1rem; } }

.navbar-item a {
  transition: 0.4s;
  font-weight: bold;
  color: white;
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase; }
  .navbar-item a:active, .navbar-item a:visited, .navbar-item a:hover, .navbar-item a:focus {
    text-decoration: none; }

.as-btn a {
  background-color: #59c3b7;
  color: #24304a;
  padding: 1rem 2rem;
  border-radius: 1.5rem; }

.as-btn.no-bg a {
  background-color: transparent !important;
  color: #59c3b7;
  border: 2px solid #59c3b7; }

.notification-container {
  z-index: 999999;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden; }
  @media screen and (max-width: 570px) {
    .notification-container {
      position: absolute;
      width: 100%;
      left: 0;
      height: calc(100vh - 8rem); } }
  @media screen and (min-width: 570px) {
    .notification-container {
      position: absolute;
      right: 5%;
      width: clamp(30rem, 20%, 40rem);
      height: clamp(60rem, 70vh, 100vh);
      border-radius: 1rem;
      padding-top: 0.5rem;
      border: 2px solid #24304a; } }
  @media screen and (min-width: 570px) and (min-width: 960px) and (max-width: 1200px) {
    .notification-container {
      right: 1.25%; } }
  @media screen and (min-width: 570px) and (min-width: 570px) and (max-width: 960px) {
    .notification-container {
      right: 1rem; } }

.notification-list {
  overflow-y: auto;
  border-top: 0.5px solid #ced4da;
  max-height: 90%; }
  .notification-list::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ced4da;
    /* or add it to the track */ }
  .notification-list::-webkit-scrollbar-thumb {
    background: #24304a;
    border-radius: 2.5px; }
  .notification-list .infinite-scroll-component {
    padding: 0 !important; }

.notification-header {
  display: flex;
  align-items: center;
  height: 7rem;
  padding: 0 2rem; }
  .notification-header--title {
    font-weight: bold;
    font-size: 1.8rem; }

.notification {
  display: flex;
  align-items: center;
  height: 10rem;
  padding: 0 2rem;
  border-bottom: 0.5px solid #ced4da; }
  .notification.new {
    background-color: #ace1db; }
  .notification--icon {
    width: 5rem;
    display: flex;
    justify-content: flex-start; }
    .notification--icon__icon {
      margin-left: 0.5rem;
      -webkit-transform: scale(1.5) !important;
              transform: scale(1.5) !important;
      display: block; }
  .notification--body {
    width: calc(100% - 5rem); }
    .notification--body__message {
      font-size: 1.4rem;
      margin: 0;
      word-wrap: break-word; }
    .notification--body__time {
      font-size: 1.2rem;
      margin: 0; }

.notification-counter {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ace1db;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 0.8rem;
  position: absolute;
  top: 1.5rem;
  right: calc(10% + 6.5rem); }
  @media screen and (min-width: 960px) and (max-width: 1200px) {
    .notification-counter {
      right: calc(2.5% + 6.5rem); } }
  @media screen and (min-width: 768px) and (max-width: 960px) {
    .notification-counter {
      right: calc(2rem + 6.5rem); } }
  @media screen and (min-width: 570px) and (max-width: 768px) {
    .notification-counter {
      right: calc(2rem + 6.5rem); } }
  @media screen and (max-width: 570px) {
    .notification-counter {
      right: 7rem; } }
  @media screen and (min-width: 570px) {
    .notification-counter.open::before {
      content: '';
      width: 0;
      height: 0;
      top: 3.5rem;
      left: -1.5rem;
      position: absolute;
      border-left: 1.2rem solid transparent;
      border-right: 1.2rem solid transparent;
      border-bottom: 1.2rem solid #24304a; } }

@media screen and (min-width: 570px) {
  .white + .notification-counter.open::before {
    border-bottom: 1.2rem solid white; } }

.map-container {
  position: relative;
  margin-top: 2rem;
  box-sizing: border-box; }
  @media screen and (max-width: 570px) {
    .map-container {
      margin-top: 0;
      position: absolute;
      left: 0;
      top: 5rem;
      overflow: hidden;
      width: 100%;
      height: calc(100vh - 13rem); } }
  @media screen and (min-width: 570px) and (max-width: 1200px) {
    .map-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 50rem; } }
  .map-container .map-list.desktop {
    display: none; }
    @media screen and (min-width: 1200px) {
      .map-container .map-list.desktop {
        display: block;
        width: 35%;
        position: absolute;
        top: 0;
        left: 65%;
        height: 60vh;
        max-height: 50rem;
        min-height: 50rem;
        overflow-y: auto; }
        .map-container .map-list.desktop::-webkit-scrollbar {
          width: 5px;
          height: 8px;
          background-color: #ced4da;
          /* or add it to the track */ }
        .map-container .map-list.desktop::-webkit-scrollbar-thumb {
          background: #24304a;
          border-radius: 2.5px; } }
    .map-container .map-list.desktop .tasks-list {
      margin-top: 0; }

.map-current-location {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  margin-inline: auto;
  height: 250px;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center; }

.marker-current-location {
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  position: relative;
  width: 24px;
  height: 26px; }

.marker-icon-style {
  color: #59c3b7;
  width: 100% !important;
  height: auto; }

.confirm-finish-container {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  align-items: center; }

.icon-alert {
  background-color: #ffc107;
  color: #1b1f27; }

.alert-btn-wrapper {
  padding-inline: 1rem;
  padding-block: 0.2rem;
  border-radius: 100%;
  border: 3px solid #1b1f27;
  outline: 1rem solid #ffc107; }

.map {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  position: relative; }
  @media screen and (min-width: 570px) and (max-width: 1200px) {
    .map {
      height: 100%;
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0; } }
  @media screen and (min-width: 1200px) {
    .map {
      width: 65%;
      height: 50rem; } }

.location-marker {
  --ggs: 1.2;
  width: 2rem;
  height: 2rem; }
  .location-marker.button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none; }

.info-box {
  position: relative; }
  @media screen and (min-width: 1200px) {
    .info-box {
      display: none; } }
  .info-box__menu {
    position: absolute;
    top: 3rem;
    right: -2.05rem;
    width: 7rem;
    height: 5rem;
    border: none;
    border-radius: 1.2rem;
    background-color: #24304a;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 99;
    transition: all 0.4s ease-out; }
  .info-box__burger {
    width: 2.5rem;
    height: 0.25rem;
    background-color: white;
    border-radius: 0.15rem;
    position: absolute;
    top: 2.3rem;
    left: 1.6rem;
    transition: all 0.2s ease-in-out; }
    .info-box__burger::before, .info-box__burger::after {
      content: '';
      position: absolute;
      width: 2.5rem;
      height: 0.25rem;
      background-color: white;
      border-radius: 0.15rem;
      transition: all 0.2s ease-in-out; }
    .info-box__burger::before {
      -webkit-transform: translate(-1.25rem, -1rem);
              transform: translate(-1.25rem, -1rem); }
    .info-box__burger::after {
      -webkit-transform: translate(-1.25rem, 1rem);
              transform: translate(-1.25rem, 1rem); }
  .info-box__menu.open {
    -webkit-transform: translateX(-85vw);
            transform: translateX(-85vw); }
    @media screen and (min-width: 570px) and (max-width: 768px) {
      .info-box__menu.open {
        -webkit-transform: translateX(-50vw);
                transform: translateX(-50vw); } }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .info-box__menu.open {
        -webkit-transform: translateX(-60vw);
                transform: translateX(-60vw); } }
  .info-box__menu.open .info-box__burger {
    -webkit-transform: translateX(-5rem);
            transform: translateX(-5rem);
    background: transparent;
    box-shadow: none; }
  .info-box__menu.open .info-box__burger::before {
    -webkit-transform: rotate(45deg) translate(2.6rem, -2.6rem);
            transform: rotate(45deg) translate(2.6rem, -2.6rem); }
  .info-box__menu.open .info-box__burger::after {
    -webkit-transform: rotate(-45deg) translate(2.6rem, 2.6rem);
            transform: rotate(-45deg) translate(2.6rem, 2.6rem); }
  .info-box__list {
    background-color: white;
    z-index: 100;
    position: absolute;
    width: 85vw;
    height: 75vh;
    top: -50%;
    right: 0;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border: 2px solid #24304a;
    border-right: 2px solid #d5d8db;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translateX(85vw);
            transform: translateX(85vw);
    transition: all 0.4s ease-out; }
    @media screen and (min-width: 570px) and (max-width: 1200px) {
      .info-box__list {
        height: 50rem; } }
    @media screen and (min-width: 570px) and (max-width: 768px) {
      .info-box__list {
        width: 50vw;
        -webkit-transform: translateX(50vw);
                transform: translateX(50vw); } }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      .info-box__list {
        width: 60vw;
        -webkit-transform: translateX(60vw);
                transform: translateX(60vw); } }
    .info-box__list.open {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .info-box__list--content {
      width: 97.5%;
      height: 97.5%;
      overflow: auto; }
      .info-box__list--content::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #ced4da;
        /* or add it to the track */ }
      .info-box__list--content::-webkit-scrollbar-thumb {
        background: #24304a;
        border-radius: 2.5px; }

@media screen and (max-width: 570px) {
  .alert-container {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 80%; } }

@media screen and (min-width: 570px) and (max-width: 1200px) {
  .alert-container {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%; } }

@media screen and (min-width: 1200px) {
  .alert-container {
    position: absolute;
    top: 20%;
    left: 15%;
    width: 34%; } }

.alert-container {
  z-index: -1;
  transition: z-index 0.3s step-end; }
  .alert-container.show {
    z-index: 99;
    transition: z-index 0.3s step-start; }
    .alert-container.show .alert {
      opacity: 0.75; }

.alert {
  transition: all 0.3s ease-in-out;
  background-color: black;
  opacity: 0;
  padding: 1rem 0; }

.alert-message {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  margin: 0; }

.payment-layout {
  margin-top: 2rem; }
  @media screen and (min-width: 768px) {
    .payment-layout {
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-column-gap: 5%;
      -webkit-column-gap: 5%;
              column-gap: 5%; }
      .payment-layout .payments-data {
        width: 80%;
        margin: 0 auto; } }

.payment-amounts-container {
  margin-bottom: 4rem; }

.amount-box {
  padding: 1.5rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2); }
  .amount-box__title, .amount-box__price {
    width: 100%;
    margin: 0;
    text-align: start;
    font-weight: bold;
    font-size: 1.6rem; }

.bank-info-container {
  margin-bottom: 4rem; }

.completed-info--header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .completed-info--header__title {
    font-weight: bold;
    font-size: 1.8rem; }
  .completed-info--header__edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.3rem; }
    .completed-info--header__edit i {
      margin-right: 0.5rem;
      -webkit-transform: rotate(-45deg) scale(0.8);
              transform: rotate(-45deg) scale(0.8); }

.payment-list {
  overflow-y: auto;
  min-height: 15rem;
  max-height: 40vh;
  margin-bottom: 4rem; }
  .payment-list.payment-modal {
    margin-bottom: 0 !important; }
  .payment-list::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ace1db;
    /* or add it to the track */ }
  .payment-list::-webkit-scrollbar-thumb {
    background: #59c3b7;
    border-radius: 20px; }
  .payment-list .infinite-scroll-component {
    padding: 0 !important; }

.usershift-container {
  margin-bottom: 0.5rem; }

.usershift {
  display: flex;
  align-items: stretch;
  padding: 0.5rem 0; }
  .usershift--dates, .usershift--info {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .usershift--dates {
    width: 40%;
    border-right: 2px solid #ace1db;
    align-items: flex-start; }
    .usershift--dates__shiftname {
      font-weight: bold;
      margin-bottom: 0.6rem !important; }
    .usershift--dates p {
      font-size: 1.1rem;
      margin-bottom: 0.3rem; }
  .usershift.one-column .usershift--info {
    width: 100% !important;
    padding-left: 0 !important; }
  .usershift--info {
    width: 60%;
    padding-left: 1rem; }
    .usershift--info__taskname {
      font-size: 1.3rem !important;
      font-weight: bold;
      margin-bottom: 0.6rem !important; }
    .usershift--info__location {
      margin-bottom: 0.6rem !important; }
    .usershift--info__payment .payment {
      background-color: #ace1db;
      padding: 0.3rem 0.6rem;
      border-radius: 1rem;
      font-weight: bold; }
    .usershift--info p {
      font-size: 1.2rem;
      margin-bottom: 0; }

.no-payment-alert {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem; }
  .no-payment-alert p {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    margin-top: 5rem; }

.request-container {
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .request-container {
      width: 80%;
      margin-top: 4rem; }
      .request-container .receipt {
        margin-bottom: 3rem; } }
  @media screen and (min-width: 768px) {
    .request-container {
      width: 40%;
      min-width: 45rem;
      margin-top: 6rem; }
      .request-container .receipt {
        margin-bottom: 5rem; } }

.receipt-upload {
  cursor: pointer;
  width: 100%;
  height: 8rem;
  background-color: #d3d6db;
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center; }
  .receipt-upload p {
    margin: 0;
    font-weight: bold;
    color: #24304a;
    text-align: center;
    padding-inline: 0.5rem; }

.request--amount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4rem; }
  .request--amount p {
    margin: 0;
    margin-left: 1rem; }

.request-disclaimer {
  background-color: #ffc107;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.1); }
  .request-disclaimer .title {
    margin-bottom: 1.5rem; }
  .request-disclaimer .disclaimer-text {
    font-size: 1.4rem;
    text-align: justify; }

.payment-group {
  margin-bottom: 3rem; }
  .payment-group-header {
    width: 100%;
    height: 2rem;
    font-weight: bold;
    margin-bottom: 2rem; }
  .payment-group .user-payment {
    width: 100%;
    display: grid;
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .payment-group .user-payment {
        grid-template-columns: 1fr 8fr 1fr; } }
    @media screen and (max-width: 768px) {
      .payment-group .user-payment {
        grid-template-columns: 1fr 6fr 3fr; } }
    .payment-group .user-payment--time {
      border-right: 2px solid #ace1db;
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 5rem; }
    .payment-group .user-payment--info {
      padding-left: 1rem; }
      .payment-group .user-payment--info p {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        margin: 0; }
        @media screen and (max-width: 768px) {
          .payment-group .user-payment--info p {
            margin-bottom: 0.5rem;
            font-size: 1.4rem; } }
    .payment-group .user-payment--action-btn {
      display: flex;
      justify-content: center;
      align-items: center; }
      .payment-group .user-payment--action-btn button {
        background-color: transparent !important;
        border: none !important; }
        .payment-group .user-payment--action-btn button i {
          -webkit-transform: scale(1.5);
                  transform: scale(1.5); }
  @media screen and (min-width: 768px) {
    .payment-group .user-payment--info {
      display: grid;
      grid-template-columns: 5fr 5fr 4fr; } }
  @media screen and (max-width: 768px) {
    .payment-group .user-payment--info {
      display: flex;
      flex-direction: column; } }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid #24304a; }
  .nav-tabs .nav-item.show .nav-link:hover,
  .nav-tabs .nav-link.active:hover {
    border-bottom: 2px solid #24304a; }

.nav-tabs .nav-link {
  padding: 0.5rem 0 !important;
  margin-right: 3rem;
  border: none; }

.nav-tabs {
  border-bottom: none; }

.nav-tabs .nav-link:hover {
  border: none; }

.tasks-list {
  margin-top: 2rem; }

.icon-input {
  margin-top: 2rem;
  position: relative; }
  .icon-input__input {
    font-size: 1.6rem !important; }
  .icon-input__icon {
    position: absolute;
    top: 50%;
    right: 2%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }

.session-main {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0; }
  @media screen and (min-width: 768px) {
    .session-main {
      display: grid;
      grid-template-columns: 2fr 3fr; } }
  .session-main--image {
    width: 100%;
    background-color: #59c3b7; }
    @media screen and (max-width: 768px) {
      .session-main--image {
        display: none; } }
    .session-main--image img {
      display: block;
      height: 100%;
      min-height: 100vh;
      width: 100%;
      object-fit: cover;
      mix-blend-mode: luminosity;
      opacity: 0.8; }
  .session-main--content {
    margin: 0 15px; }
  .session-main .phone-container {
    position: absolute;
    top: 50%;
    left: 40%;
    width: 20%;
    min-width: 250px;
    display: flex;
    align-items: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .session-main .phone-container img {
      display: block;
      width: 100%; }
      @media (max-width: 1000px) {
        .session-main .phone-container img {
          translate: -20%; } }
    @media screen and (max-width: 768px) {
      .session-main .phone-container {
        display: none; } }

.session-form .form-group {
  margin-bottom: 1.5rem; }
  .session-form .form-group label {
    font-weight: bold;
    font-size: 1.4rem; }
  .session-form .form-group > * > input {
    height: 3.5rem !important;
    font-family: 'Ubuntu';
    padding: 0 2rem;
    font-size: 1.4rem !important; }
    .session-form .form-group > * > input::autofill {
      background-color: red; }

.scrollable {
  height: 90vh !important;
  padding: 100px 0px;
  overflow-y: scroll;
  width: 100%; }

.scrollable .row {
  margin-right: 0px;
  margin-left: 0px; }

.custom-gap > [class^='col-'] {
  margin: 0;
  padding: 0;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  /* Ajusta este valor según el espacio que desees */ }

.session-btn {
  border-radius: 1rem !important;
  margin-top: 4rem !important;
  margin-bottom: 2.5rem !important; }

.session-social-logins button {
  margin-bottom: 2rem; }

.session-links {
  display: flex;
  flex-direction: column; }
  .session-links .btn-link {
    font-weight: normal !important;
    font-size: 1.4rem !important;
    letter-spacing: normal;
    font-family: 'Ubuntu';
    color: #24304a; }
    .session-links .btn-link:hover {
      color: #5974ae;
      text-decoration: none; }

.session-main--logo {
  width: 30% !important; }
  @media screen and (min-width: 768px) {
    .session-main--logo {
      display: none !important; } }

.not-found {
  --big-text: 20rem;
  --secondary-text: 1.8rem;
  width: 100%;
  height: calc(100vh - 12rem);
  min-height: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .not-found--body {
    display: flex;
    align-items: center;
    padding: 3rem 0; }
    .not-found--body i {
      margin-right: 1rem;
      margin-top: 0.4rem; }
  .not-found h1 {
    font-size: var(--big-text);
    color: #59c3b7; }
  .not-found p {
    font-size: var(--secondary-text); }
  @media screen and (max-width: 768px) {
    .not-found {
      --big-text: 12rem;
      --secondary-text: 1.6rem;
      min-height: 40rem; } }

.not-found--navbar {
  background-color: #24304a;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  padding: 0 10%;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .not-found--navbar {
      padding: 0 2rem; }
      .not-found--navbar .main-logo {
        max-width: 15rem;
        justify-content: flex-start; } }

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.icon-size-2 {
  --ggs: 2; }

.icon-size-3 {
  --ggs: 3; }

.icon-size-4 {
  --ggs: 4; }

.icon-size-5 {
  --ggs: 5; }

.icon-size-6 {
  --ggs: 6; }

.icon-size-7 {
  --ggs: 7; }

.icon-size-8 {
  --ggs: 8; }

.icon-size-9 {
  --ggs: 9; }

.icon-size-10 {
  --ggs: 10; }

html {
  position: relative;
  min-height: 100%;
  font-size: 62.5%;
  /* Sets up the Base 10 stuff */ }

body {
  padding-top: 6rem;
  margin-bottom: 6rem !important;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem; }
  @media screen and (max-width: 570px) {
    body {
      padding-top: 8rem; } }

input {
  font-size: 1.6rem !important; }
  input.DateInput_input {
    margin-left: 0 !important; }

.content-public {
  position: absolute;
  top: 7.9rem;
  width: 100%; }

.content-body {
  box-sizing: border-box;
  position: relative;
  width: calc(100% - 23rem);
  margin-left: 23rem;
  padding: 0 10%; }
  @media screen and (min-width: 960px) and (max-width: 1200px) {
    .content-body {
      padding: 0 2.5%; } }
  @media screen and (min-width: 768px) and (max-width: 960px) {
    .content-body {
      margin-left: 20rem;
      width: calc(100% - 20rem);
      padding: 0 2rem; } }
  @media screen and (min-width: 570px) and (max-width: 768px) {
    .content-body {
      margin-left: 18rem;
      width: calc(100% - 18rem);
      padding: 0 2rem; } }
  @media screen and (max-width: 570px) {
    .content-body {
      width: 100%;
      margin-left: 0;
      padding: 0 2rem; } }

@media screen and (min-width: 768px) {
  .module-title {
    margin-bottom: 10rem; } }

.index-action-buttons {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .index-action-buttons {
      padding-left: 50%; }
      .index-action-buttons > * {
        width: 24% !important;
        margin-left: 1%;
        margin-bottom: 1%; } }
  .index-action-buttons > * {
    width: 49% !important;
    margin-left: 1%;
    margin-bottom: 1%; }
  @media screen and (max-width: 768px) {
    .index-action-buttons {
      margin-top: 2rem; }
      .index-action-buttons button,
      .index-action-buttons a {
        font-size: 1.1rem !important; } }

.highlighted {
  background-color: #ace1db;
  padding: 0.3rem 0.8rem;
  border-radius: 1.5rem; }

button.big {
  height: 4rem;
  border-radius: 1rem; }

a.big {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  border-radius: 1rem; }

a.disabled {
  background-color: #343a40 !important; }

.primary-btn {
  color: white;
  font-weight: bold;
  background-color: #24304a;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1.2rem;
  border-radius: 1rem !important;
  border: 1px solid transparent;
  text-transform: uppercase;
  line-height: 1.2; }
  .primary-btn:hover, .primary-btn:focus {
    color: white;
    text-decoration: none; }
  @media screen and (min-width: 960px) {
    .primary-btn {
      font-size: 1.2rem; } }

.secondary-btn-white {
  padding: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: white;
  border: 1px solid #24304a !important;
  color: #24304a;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem !important;
  text-transform: uppercase;
  transition: all 0.2s; }
  .secondary-btn-white i {
    margin-left: 0.3rem; }
  .secondary-btn-white.show:focus i, .secondary-btn-white.show:active i {
    color: #24304a; }
  .secondary-btn-white.show i {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .secondary-btn-white:hover i, .secondary-btn-white:focus i {
    color: white; }
  .secondary-btn-white:focus i {
    color: #24304a; }
  .secondary-btn-white:focus {
    background-color: white;
    color: #24304a;
    outline: none; }
  @media screen and (min-width: 768px) {
    .secondary-btn-white {
      font-size: 1.4rem; } }

.base-bold, h1, h2, h3, h4 {
  letter-spacing: 130%;
  font-weight: bold; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 2.8rem; }

h3 {
  font-size: 2rem; }

h4 {
  font-size: 1.8rem; }

h5 {
  letter-spacing: 130%;
  font-size: 1.6rem; }

h6 {
  letter-spacing: 135%;
  font-size: 1.4rem; }

.footer {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  height: 60px; }

.navbar .navbar-brand {
  height: 40px;
  width: 100px; }
  .navbar .navbar-brand img {
    object-fit: contain;
    height: 100%;
    width: 100%; }

.full-height {
  height: calc(100vh - 120px); }

.carousel-home {
  margin: 0 -15px;
  width: auto; }

.carousel-show {
  position: relative; }
  .carousel-show img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .carousel-show:after {
    content: '';
    padding-bottom: 40%;
    display: block; }

.custom-data-table {
  min-height: 350px; }

.btn {
  font-weight: bold;
  border-radius: 0.5rem;
  letter-spacing: 0.1rem;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    filter: alpha(opacity=80);
    opacity: 0.8; }

.transparent {
  background-color: transparent;
  border: none; }
  .transparent:hover, .transparent:active, .transparent:visited, .transparent:focus {
    background-color: transparent; }

.spinner-container {
  min-height: 10rem;
  height: 100%;
  display: flex;
  align-items: center; }
  .spinner-container.spinner-border {
    width: 3rem;
    height: 3rem; }

.css-g1d714-ValueContainer {
  font-size: 1.6rem;
  font-weight: 400; }

.input-group-prepend {
  width: 12rem; }
  .input-group-prepend .input-group-text {
    width: 100%; }

.css-2b097c-container {
  width: 100%; }

.back-btn {
  margin: 1rem 0; }
  @media screen and (min-width: 768px) {
    .back-btn {
      margin-bottom: 2rem; } }

.m-t-0 {
  margin-top: 0 !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.m-b-0 {
  margin-bottom: 0 !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.m-l-0 {
  margin-left: 0 !important; }

.m-l-10 {
  margin-left: 10px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.w-60 {
  width: 60%; }

.w-40 {
  width: 38%; }

.p-t-0 {
  padding-top: 0 !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-b-0 {
  padding-bottom: 0 !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

pm-l-0 {
  padding-left: 0 !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.chevron-back {
  padding-left: 40px; }
  @media (max-width: 991px) {
    .chevron-back {
      padding-left: 30px;
      padding-top: 30px; } }
  @media (max-width: 414px) {
    .chevron-back {
      padding-left: 30px;
      padding-top: 30px; } }
  @media (max-width: 320px) {
    .chevron-back {
      padding-left: 30px;
      padding-top: 30px; } }

.iconed-btn {
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
  border: 2px solid transparent; }

.navbar-logged {
  background: linear-gradient(270.02deg, #081735 -30.74%, #24304a 60.91%, #081735 135.27%); }

.toaster-info-bg {
  background-color: #24304a !important; }

.Toastify__toast {
  border-radius: 0.5rem !important; }
  .Toastify__toast-body {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2rem;
    width: 70%; }

.password-buttons button {
  width: 100%;
  border-radius: 1rem; }

