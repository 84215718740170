.task-container {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  position: relative;
  border-bottom: 1px solid $gray-300;
}

.task {
  color: $dark-blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 10rem;
  z-index: 999;
  overflow: hidden;

  @media screen and (min-width: $xl) {
    width: 75%;
  }
  @media screen and (min-width: $md) and (max-width: $xl) {
    width: 78%;
  }
  @media screen and (min-width: $md) {
    min-height: 12rem;
  }

  &--thumbnail {
    --thumbnail-width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    width: var(--thumbnail-width);
    height: var(--thumbnail-width);
    border-radius: calc(var(--thumbnail-width) / 2);
    overflow: hidden;

    &__image {
      width: 100%;
      object-fit: fill;
    }

    @media screen and (min-width: $md) {
      --thumbnail-width: 8rem;
    }
  }

  &.map-list &--thumbnail {
    justify-content: center;
    &__image {
      width: 100%;
      height: 100%;
      max-width: 7rem;
      max-height: 7rem;
      object-fit: contain;
      border-radius: 1rem;
    }

    @media screen and (min-width: $xl) {
      width: 25%;
      &__image {
        max-width: 12rem;
        min-height: 12rem;
      }
    }
  }

  &--info {
    display: flex;
    width: calc(100% - 8rem);
    margin-left: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (min-width: $md) {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.info--header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  max-height: 3rem;
  overflow-y: visible;
  @media screen and (min-width: $md) {
    width: 50%;
    justify-content: space-between;
    align-items: center;
    &__title {
      max-width: 70%;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      margin-right: 2rem;
    }
  }
  &__title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 0.5rem;
    width: 60%;
    @media screen and (min-width: $md) and (max-width: $xl) {
      margin-right: 0.5rem;
      font-size: 1.4rem;
    }
    @media screen and (min-width: $xl) {
      margin-right: 0.5rem;
      font-size: 1.6rem;
    }
  }
  &__prices {
    margin-top: 0.5rem;
    display: flex;
    max-width: 40%;
    flex-direction: column;
    p {
      text-align: center;
      @media screen and (min-width: $md) and (max-width: $lg) {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }
  &__price {
    height: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: $light-blue;
    padding: 0.3rem 0.8rem;
    border-radius: 1.5rem;
    margin-bottom: 0.5rem;
    @media screen and (min-width: $lg) {
      font-size: 1.3rem;
    }
    @media screen and (min-width: $md) and (max-width: $lg) {
      font-size: 1.2rem;
    }
    @media screen and (min-width: $md) {
      padding: 0.5rem 1.1rem;
    }
    @media screen and (max-width: $md) {
      height: 100%;
    }
  }
  &__variable {
    height: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #def3f1;
    padding: 0.3rem 0.8rem;
    border-radius: 1.5rem;
    margin-bottom: 0;
    @media screen and (min-width: $lg) {
      font-size: 1.3rem;
    }
    @media screen and (min-width: $md) and (max-width: $lg) {
      font-size: 1.2rem;
    }
    @media screen and (min-width: $md) {
      padding: 0.5rem 1rem;
    }
    @media screen and (max-width: $md) {
      height: 100%;
    }
  }
}

.info--body {
  p {
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
    padding: 0;
    @media screen and (min-width: $md) {
      font-size: 1.3rem;
    }
  }
  @media screen and (min-width: $md) {
    margin-left: 1rem;
    &__dates {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: $md) {
    width: 80%;
  }
}

.task--description {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.62, 0.03, 0.47, 1.01);
  margin: 0;

  &.show {
    margin-top: 1.5rem;
    opacity: 1;
    max-height: 25rem;
  }

  .section {
    margin-bottom: 2rem;
    &--title {
      font-weight: bold;
      font-size: 1.4rem;
    }
    &--body {
      font-size: 1.3rem;
    }
  }
}

.task-buttons {
  .primary-btn {
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // Mobile
  @media screen and (max-width: $md) {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    &--secondary {
      flex: 0 1 40%;
    }
    &--primary {
      flex: 0 2 55%;
    }
  }
  // Desktop
  @media screen and (min-width: $md) {
    position: absolute;
    right: 0;
    top: 3rem;
    width: 19%;
    &--primary {
      margin-bottom: 0.5rem;
    }
    * > button {
      font-size: 1.2rem;
    }
  }
}

// Variations

// Show View
.task-container.show-view {
  & .task {
    height: 12rem;
  }
  border-bottom: none;
  & .task--description {
    max-height: 800rem;
  }
}

// Map Desktop List
.task-container.keep-mobile {
  padding: 0 1rem;
  .task {
    @media screen and (min-width: $xl) {
      width: 100%;
      height: 12rem;
    }
    &--thumbnail {
      @media screen and (min-width: $xl) {
        max-width: 10rem;
        max-height: 10rem;

        &__image {
          object-fit: fill;
          width: 100%;
          max-height: 100%;
        }
      }
    }
    &--info {
      @media screen and (min-width: $xl) {
        width: 65%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: initial;
      }

      .info--header {
        @media screen and (min-width: $xl) {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          max-height: 100%;
          &__prices {
            flex-direction: row;
          }
        }

        &__title {
          @media screen and (min-width: $xl) {
            font-size: 1.3rem;
          }
        }
        &__price {
          @media screen and (min-width: $xl) {
            font-size: 1.2rem;
            font-weight: bold;
            background-color: $light-blue;
            padding: 0.3rem 0.8rem;
            border-radius: 1.5rem;
            margin-bottom: 0;
          }
        }
        &__variable {
          @media screen and (min-width: $xl) {
            font-size: 1.2rem;
            font-weight: bold;
            background-color: #def3f1;
            padding: 0.3rem 0.8rem;
            border-radius: 1.5rem;
            margin-bottom: 0;
          }
        }
      }

      .info--body {
        p {
          @media screen and (min-width: $xl) {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }

  .task--description {
    &.show {
      max-height: 100rem;
    }
    & .section .section--body {
      font-size: 1.2rem;
    }
  }

  & .task-buttons {
    @media screen and (min-width: $md) {
      top: 0 !important;
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem;
      &--secondary {
        flex: 0 1 35%;
        & button {
          font-size: 1rem !important;
          height: 3.5rem;
        }
      }
      &--primary {
        flex: 0 2 60%;
        & a {
          font-size: 1rem !important;
          height: 3.5rem;
          line-height: 2.5rem;
        }
      }
    }
  }
}

// Map Mobile List
.task-container.map-list {
  @media screen and (max-width: $md) {
    padding: 0.5rem 1rem;
    .task {
      height: 10rem;
    }
    & .task--description {
      & .section--title {
        font-size: 1.3rem;
      }
      & .section--body {
        font-size: 1.2rem;
      }
    }
    & .task-buttons {
      margin-bottom: 1rem;

      &--secondary {
        flex: 0 1 35%;
        & button {
          font-size: 1rem !important;
          height: 3.5rem;
        }
      }
      &--primary {
        flex: 0 2 60%;
        & a {
          font-size: 1rem !important;
          height: 3.5rem;
          line-height: 2.5rem;
        }
      }
    }
  }
}

// Map Medium List
.task-container.map-list {
  @media screen and (min-width: $md) {
    & .task--thumbnail {
      max-width: 7rem;
      max-height: 7rem;
    }
    padding: 0.5rem 1rem;
    .task {
      height: 10rem;
    }
    & .task-buttons {
      top: 2rem;
      &--secondary {
        & button {
          font-size: 1rem !important;
        }
      }
      &--primary {
        & a {
          font-size: 1rem !important;
        }
      }
    }
    & .task .task--info {
      @media screen and (min-width: $md) and (max-width: $lg) {
        align-items: initial;
        & .info--header {
          flex-direction: column;
          &__prices {
            flex-direction: row;
          }
        }
      }
    }
    & .task .task--info .info--header {
      &__title {
        font-size: 1.3rem;
        margin-right: 0;
      }
      &__price {
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
      }
      &__variable {
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
      }
    }
    & .task .task--info .info--body {
      p {
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
      }
    }
    & .task--description .section {
      & .section--title {
        font-size: 1.3rem;
      }
      & .section--body {
        font-size: 1.2rem;
      }
    }
  }
}

.shift-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 4rem;
  // Mobile
  @media screen and (max-width: $md) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: $md) and (max-width: $lg) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
  @media screen and (max-width: $md) {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
}
.shift-item {
  background: #def3f1;
  font-size: 1.2rem;
  border-radius: 1.5rem;
  padding: 1rem 2rem;
  @media screen and (max-width: $md) {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }
}

.btn-apply-to-task {
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 1.5rem;
    padding: 0.8rem 3rem;
    border-radius: 1rem;
    border-color: transparent;
    &:hover {
      color: white;
      border-color: transparent;
      background-color: $dark-blue;
    }
  }

  .btn-notify {
    background-color: #16d964;
    color: #1b1f27;
  }
}

.apply-to-task {
  display: flex;
  justify-content: flex-end;

  & button {
    font-size: 1.5rem;
    padding: 0.8rem 3rem;
    border-radius: 1rem;
    border-color: transparent;
    width: calc(33.333% - 1rem);
    text-transform: uppercase;
    &:hover {
      color: $dark-blue;
      border-color: transparent;
      background-color: $warning-yellow;
    }
    @media screen and (min-width: $md) and (max-width: $lg) {
      width: calc(50% - 0.5rem);
    }
    @media screen and (max-width: $md) {
      width: 100%;
    }
  }
}

.apply-message {
  margin-top: 4rem;

  // Mobile
  @media screen and (max-width: $md) {
    margin-top: 2rem;
  }

  &--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin: 0;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
  &--body {
    margin-top: 0.5rem;
  }
}

.highlighted-payment {
  width: 100%;
  color: white;
  background-color: #24304a;
  box-shadow: 2px 4px 13px rgba(200, 218, 216, 0.4);
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  p:nth-child(2) {
    font-weight: 600;
  }
  @media screen and (min-width: $md) {
    width: 31.5%;
    p:nth-child(2) {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}

.repeteable-task {
  background-color: $warning-yellow;
  color: $dark-blue;
  padding: 0.3rem 0.8rem;
  border-radius: 1rem;
  margin: 0 0.5rem;
  font-weight: normal;
}
