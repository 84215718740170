@import '~bootstrap/scss/functions';
@import '../../assets/stylesheets/custom_variables.scss';

.profile-top {
  position: absolute;
  top: -8rem;
  left: 0;
  height: 28rem;
  width: calc(100% + 1.5rem);
  background: linear-gradient(
    270.02deg,
    #081735 -30.74%,
    #24304a 60.91%,
    #081735 135.27%
  );
  color: white;
  margin: 0;
  padding-top: 8rem;
  padding-bottom: 3rem;

  @media screen and (max-width: $sm) {
    width: 100%;
    height: 30rem;
  }
  @media screen and (min-width: $sm) {
    left: -1.5rem;
    padding-left: 1.5rem;
  }

  &--content {
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  .profile-img--container {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $sm) {
      margin-left: 2rem;
      width: 40%;
    }
    @media screen and (min-width: $sm) and (max-width: $lg) {
      width: 40%;
    }
    @media screen and (min-width: $lg) {
      width: 20%;
    }

    .profile-img-dummy {
      width: 12rem;
      height: 12rem;
      background-color: #def3f1;
      border-radius: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: #59c3b7;
      }
    }
    .profile-img {
      width: 12rem;
      height: 12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 6rem;
      border: 5px solid white;

      img {
        min-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .profile-info {
    display: flex;
    width: 50%;

    @media screen and (max-width: $sm) {
      flex-direction: column;
      margin-top: 1rem;
      margin-left: 2rem;
      width: 80%;
    }
    @media screen and (min-width: $sm) and (max-width: $lg) {
      width: 80%;
      flex-direction: column;
      margin-top: 2rem;
    }
    @media screen and (min-width: $lg) {
      flex-direction: row;
    }

    .profile-data {
      width: 50%;
      margin-top: -3rem;
      @media screen and (max-width: $sm) {
        width: 100%;
        margin-top: -1rem;
      }
      @media screen and (min-width: $sm) and (max-width: $lg) {
        width: 100%;
      }

      p {
        color: white;
        font-weight: bold;
        margin: 0.2rem;
        @media screen and (min-width: $lg) {
          font-size: 1.5rem;
        }
      }

      .rating {
        display: flex;
        justify-content: space-between;
        width: 30%;
        max-width: 12rem;
        margin-top: 0.5rem;
        &-text {
          align-self: center;
          font-size: 1.5rem;
          margin-left: 1rem;
        }
      }
    }

    .profile-edit {
      margin-top: -4rem;
      width: 50%;
      @media screen and (max-width: $sm) {
        width: 100%;
        margin-top: 0;
        padding: 1rem 0 0 0.5rem;
      }
      @media screen and (min-width: $sm) and (max-width: $lg) {
        width: 100%;
        padding: 1rem 0 0 0.5rem;
        margin-top: 0;
      }

      p {
        color: white;
        margin: 0.2rem;
        @media screen and (min-width: $lg) {
          font-size: 1.5rem;
        }
      }
      .profile-edit-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .password {
          background: transparent;
          border: none;
          font-weight: normal;
          margin-left: -1rem;
          font-size: 1.2rem;
          color: white;
        }
        p {
          font-size: 1.2rem;
          font-weight: normal;
          margin-top: 0.5rem;
        }
        i {
          margin-right: 1rem;
          color: #59c3b7 !important;
        }
      }
    }
  }

  .profile-data-level {
    margin-left: 21%;
    margin-top: -2rem;
    width: 25%;
    border-radius: 1.3rem;
    background-color: #59c3b7;
    color: $light-blue;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 0;
    a {
      &:hover,
      &:focus {
        color: white;
        text-decoration: none;
      }
    }
    @media screen and (max-width: $sm) {
      position: absolute;
      margin-left: 0;
      top: 25rem;
      left: 2rem;
      width: 90%;
    }
    @media screen and (min-width: $sm) and (max-width: $lg) {
      position: absolute;
      top: 23rem;
      left: -6rem;
      width: 80%;
    }
  }

  .profile-payment {
    color: $primary;
    position: absolute;
    top: 35%;
    right: 5%;
    z-index: 8888;
    width: 30%;
    min-width: 25rem;
    max-width: 30rem;
    height: 11rem;
    background-color: #59c3b7;
    box-shadow: 0px 4px 4px rgba(82, 184, 172, 0.1),
      2px 4px 17px rgba(89, 195, 183, 0.6);
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem;
    font-weight: bold;
    &__title {
      font-size: 1.8rem;
      width: 60%;
    }
    &__price {
      font-size: 2.5rem;
    }
    @media screen and (min-width: $lg) and (max-width: $xl) {
      right: 2rem;
      &__title {
        font-size: 1.6rem;
      }
      &__price {
        font-size: 2rem;
      }
    }
    @media (max-width: $lg) {
      display: none;
    }
  }
}

.profile-inverse-corner {
  position: absolute;
  width: 100%;
  height: 3rem;
  top: 25.1rem;
  left: 0;
  background-color: white;
  border-top-right-radius: 10rem;
  border-top-left-radius: 10rem;
  @media (min-width: $sm) {
    width: calc(100% - 1.5rem);
    left: 1.5rem;
  }
  @media (max-width: $sm) {
    height: 2.2rem;
    top: 28rem;
  }
}

//EDIT

.profile-edit {
  padding: 10px 50px 0 40px;
  @media (max-width: 768px) {
    padding: 10px 50px 0 10px;
  }
  @media (max-width: 480px) {
    padding: 10px 30px 0 30px;
  }
  @media (max-width: 320px) {
    padding: 10px 30px 0 30px;
  }
}

.profile-avatar {
  display: flex;
  justify-content: center;
  --profile-img-width: 12rem;

  .custom-file-avatar {
    width: var(--profile-img-width);
    height: var(--profile-img-width);
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: calc(var(--profile-img-width) / 2);
    border: 5px solid #def3f1;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// PROFILE BOTTOM

.profile-bottom {
  position: absolute;
  top: 20rem;
  left: 0;
  width: 100%;
  padding-bottom: 3rem;
  font-family: 'Ubuntu';
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: flex-start;
    @media screen and (max-width: $md) {
      flex-wrap: wrap;
    }
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      flex-wrap: wrap;
      width: 50%;
      margin: 0 auto;
    }
    @media screen and (min-width: $sm) and (max-width: $md) {
      width: 100%;
      margin-left: 1.5rem;
      align-items: center;
    }
    .calendar-container {
      flex-basis: 30%;
      margin: 0 auto 3rem auto;
      box-shadow: 4px 4px 31px rgba(138, 140, 145, 0.2);
      border-radius: 25px;
      padding-bottom: 2rem;
      @media screen and (max-width: $md) {
        flex-basis: 100%;
        box-shadow: none;
      }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        flex-basis: 30%;
        padding: 0 5rem;
      }
    }
    .statistics-container {
      flex-basis: 30%;
      margin: 0 auto 3rem auto;
      @media screen and (max-width: $md) {
        flex-basis: 100%;
      }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        flex-basis: 100%;
      }
      .completed-tasks {
        color: white;
        position: relative;
        z-index: 8888;
        width: 100%;
        height: 11rem;
        background-color: #24304a;
        border-radius: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2.5rem;
        font-weight: bold;
        margin: 0 auto 3rem auto;
        @media screen and (min-width: $lg) and (max-width: $xl) {
          right: 2rem;
          width: 100%;
          &__title {
            font-size: 1.6rem;
          }
        }

        @media screen and (min-width: $sm) {
          font-size: 1.3rem;
          width: 100%;
        }

        @media screen and (min-width: $sm) and (max-width: $md) {
          width: 80%;
          margin: 1rem auto;
        }

        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          margin-left: 2rem;
        }

        &__title {
          font-size: 1.8rem;
          width: 60%;
        }

        &__percentage {
          width: 35%;

          .single-chart {
            width: 90%;
            justify-content: space-around;
            @media screen and (min-width: $sm) and (max-width: $md) {
              width: 60%;
              margin-left: 3rem;
            }
          }

          .circular-chart {
            display: block;
            margin: 10px auto;
          }

          .circle-bg {
            fill: none;
            stroke: #eee;
            stroke-width: 3.8;
          }

          .circle {
            fill: none;
            stroke-width: 2.8;
            stroke-linecap: round;
            animation: progress 1s ease-out forwards;
          }

          @keyframes progress {
            0% {
              stroke-dasharray: 0 100;
            }
          }

          .circular-chart.orange .circle {
            stroke: #ace1db;
          }

          .percentage-value {
            fill: white;
            font-family: sans-serif;
            font-size: 0.5em;
            text-anchor: middle;
          }
        }
      }
      .tasks-stats {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 1.8rem;
        margin: 2rem 0 3rem 0;
        width: 100%;
        align-items: center;
        text-align: center;
        font-size: 1.3rem;
        @media screen and (min-width: $sm) and (max-width: $md) {
          width: 80%;
          margin: 2rem auto;
        }
        .tasks-square {
          width: 48%;
          background: #def3f1;
          color: #2b3750;
          border-radius: 1.5rem;
          padding: 2rem 1.5rem;
          &__amount {
            font-size: 2.5rem;
          }
        }
      }
    }
    .payment-container {
      flex-basis: 33%;
      box-shadow: 4px 4px 31px rgba(138, 140, 145, 0.2);
      border-radius: 25px;
      margin: 0 2rem 3rem 0;
      padding: 2.5rem 1rem;
      font-family: 'Ubuntu';
      font-size: 1.2rem;
      max-height: 41rem;
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 0 auto 3rem auto;
      &::-webkit-scrollbar {
        width: 0.4rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #59c3b7;
        border-radius: 10px;
        outline: none;
      }
      &::-webkit-scrollbar-track-piece:end {
        background-color: transparent;
        margin-bottom: 1.5rem;
      }
      &::-webkit-scrollbar-track-piece:start {
        background-color: transparent;
        margin-top: 1.5rem;
      }
      @media screen and (max-width: $sm) {
        font-size: 1.3rem;
        flex-basis: 100%;
        box-shadow: none;
      }
      @media screen and (min-width: $sm) and (max-width: $md) {
        flex-basis: 90%;
        margin: 2rem auto;
        box-shadow: none;
      }
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        flex-basis: 100%;
        padding: 2rem 5rem;
      }
      .user-payment-header {
        padding-left: 1rem;
      }
      .user-payment-list {
        display: flex;
        flex-direction: column;
        .user-payment-row {
          display: flex;
          margin: 1rem;
          p:not(:last-child) {
            margin-bottom: 0.6rem !important;
          }
          &__title {
            flex: 1;
            border-right: 1px solid #59c3b7;
          }
          &__body {
            padding-left: 1rem;
            flex: 3;
            .user-payment-title {
              color: #24304a;
              font-weight: 500;
            }
            .user-payment-amount-title {
              color: #24304a;
              font-weight: 500;
            }
            .user-payment-amount {
              background-color: #ace1db;
              padding: 0.5rem;
              color: #24304a;
              font-weight: 500;
              border-radius: 1.5rem;
              margin-left: 1rem;
            }
            .user-payment-status {
              font-weight: 500;
              color: #24304a;
              .user-payment-pending {
                background-color: #24304a;
                padding: 0.5rem;
                color: white;
                border-radius: 1.5rem;
                margin-left: 1rem;
                font-weight: 400;
                font-size: 1.1rem;
              }
              .user-payment-approved {
                background-color: #59c3b7;
                padding: 0.5rem;
                color: #24304a;
                border-radius: 1.5rem;
                margin-left: 1rem;
                font-weight: 400;
                font-size: 1.1rem;
              }
              .user-payment-rejected {
                background-color: red;
                padding: 0.5rem;
                color: white;
                border-radius: 1.5rem;
                margin-left: 1rem;
                font-weight: 400;
                font-size: 1.1rem;
              }
            }
          }
        }
      }
      .go-to-payments {
        margin: 0 auto;
        width: 100%;
        a {
          width: 100%;
          border: 1px solid grey;
          text-decoration: none;
        }
      }
    }
    .profile-payment-mobile {
      color: $primary;
      position: relative;
      width: 100%;
      height: 11rem;
      background-color: #59c3b7;
      box-shadow: 0px 4px 4px rgba(82, 184, 172, 0.1),
        2px 4px 17px rgba(89, 195, 183, 0.6);
      border-radius: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.5rem;
      font-weight: bold;
      margin-bottom: 3rem;
      &__title {
        font-size: 1.8rem;
        width: 60%;
      }
      &__price {
        font-size: 2.5rem;
      }
      @media screen and (min-width: $lg) and (max-width: $xl) {
        right: 2rem;
        &__title {
          font-size: 1.6rem;
        }
        &__price {
          font-size: 2rem;
        }
      }
      @media (min-width: $lg) {
        display: none;
      }
      @media screen and (min-width: $sm) and (max-width: $md) {
        width: 80%;
        margin: 2rem auto;
      }
    }
  }
}
