$open-width-mobile: 85vw;
$open-width-medium: 50vw;
$open-width-desktop: 60vw;

@media screen and (min-width: $sm) {
}

.info-box {
  position: relative;
  @media screen and (min-width: $xl) {
    display: none;
  }

  &__menu {
    position: absolute;
    top: 3rem;
    right: -2.05rem;
    width: 7rem;
    height: 5rem;
    border: none;
    border-radius: 1.2rem;
    background-color: $dark-blue;
    box-shadow: 0 2px 5px rgba(darken(#000, 7), 0.2);
    z-index: 99;
    transition: all 0.4s ease-out;
  }

  &__burger {
    width: 2.5rem;
    height: 0.25rem;
    background-color: white;
    border-radius: 0.15rem;
    position: absolute;
    top: 2.3rem;
    left: 1.6rem;

    transition: all 0.2s ease-in-out;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 2.5rem;
      height: 0.25rem;
      background-color: white;
      border-radius: 0.15rem;
      transition: all 0.2s ease-in-out;
    }
    &::before {
      transform: translate(-1.25rem, -1rem);
    }
    &::after {
      transform: translate(-1.25rem, 1rem);
    }
  }

  // Animation
  &__menu.open {
    transform: translateX(-$open-width-mobile);
    @media screen and (min-width: $sm) and (max-width: $md) {
      transform: translateX(-$open-width-medium);
    }
    @media screen and (min-width: $md) and (max-width: $xl) {
      transform: translateX(-$open-width-desktop);
    }
  }

  &__menu.open &__burger {
    transform: translateX(-5rem);
    background: transparent;
    box-shadow: none;
  }

  &__menu.open &__burger::before {
    transform: rotate(45deg) translate(2.6rem, -2.6rem);
  }

  &__menu.open &__burger::after {
    transform: rotate(-45deg) translate(2.6rem, 2.6rem);
  }

  &__list {
    background-color: white;
    z-index: 100;
    position: absolute;
    width: $open-width-mobile;
    height: 75vh;
    top: -50%;
    right: 0;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border: 2px solid $dark-blue;
    border-right: 2px solid #d5d8db;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX($open-width-mobile);
    transition: all 0.4s ease-out;

    @media screen and (min-width: $sm) and (max-width: $xl) {
      height: 50rem;
    }
    @media screen and (min-width: $sm) and (max-width: $md) {
      width: $open-width-medium;
      transform: translateX($open-width-medium);
    }
    @media screen and (min-width: $md) and (max-width: $xl) {
      width: $open-width-desktop;
      transform: translateX($open-width-desktop);
    }

    &.open {
      transform: translateX(0);
    }

    &--content {
      width: 97.5%;
      height: 97.5%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: $gray-400; /* or add it to the track */
      }
      &::-webkit-scrollbar-thumb {
        background: $dark-blue;
        border-radius: 2.5px;
      }
    }
  }
}
