.session-main {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (min-width: $md) {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  &--image {
    width: 100%;
    background-color: $light-blue-secondary;
    @media screen and (max-width: $md) {
      display: none;
    }
    img {
      display: block;
      height: 100%;
      min-height: 100vh;
      width: 100%;
      object-fit: cover;
      mix-blend-mode: luminosity;
      opacity: 0.8;
    }
  }
  &--content {
    margin: 0 15px;
  }

  .phone-container {
    position: absolute;
    top: 50%;
    left: 40%;
    width: 20%;
    min-width: 250px;
    display: flex;
    align-items: center;
    transform: translate(-50%, -50%);

    img {
      display: block;
      width: 100%;
      @media (max-width: 1000px) {
        translate: -20%;
      }
    }

    @media screen and (max-width: $md) {
      display: none;
    }
  }
}

.session-form .form-group {
  label {
    font-weight: bold;
    font-size: 1.4rem;
  }
  & > * > input {
    height: 3.5rem !important;
    font-family: 'Ubuntu';
    padding: 0 2rem;
    font-size: 1.4rem !important;
    &::autofill {
      background-color: red;
    }
  }
  margin-bottom: 1.5rem;
}

.scrollable {
  height: 90vh !important;
  padding: 100px 0px;
  overflow-y: scroll;
  width: 100%;
}

.scrollable .row {
  margin-right: 0px;
  margin-left: 0px;
}

.custom-gap > [class^='col-'] {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 16px; /* Ajusta este valor según el espacio que desees */
}

.session-btn {
  border-radius: 1rem !important;
  margin-top: 4rem !important;
  margin-bottom: 2.5rem !important;
}

.session-social-logins {
  button {
    margin-bottom: 2rem;
  }
}

.session-links {
  display: flex;
  flex-direction: column;

  .btn-link {
    font-weight: normal !important;
    font-size: 1.4rem !important;
    letter-spacing: normal;
    font-family: 'Ubuntu';
    color: $dark-blue;
    &:hover {
      color: lighten($dark-blue, 30%);
      text-decoration: none;
    }
  }
}

.session-main--logo {
  width: 30% !important;
  // margin-bottom: -15%;
  @media screen and (min-width: $md) {
    display: none !important;
  }
}
