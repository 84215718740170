@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import './components/application.scss';
@import './components/task.scss';
@import './components/modal.scss';
@import './components/navbar.scss';
@import './components/landing.scss';
@import './components/landing-navbar-top.scss';
@import './components/notification.scss';
@import './components/task-map.scss';
@import './components/task-info-box.scss';
@import './components/map-alert.scss';
@import './components/payment.scss';
@import './screens/task_index.scss';
@import './screens/session.scss';
@import './screens/not_found.scss';

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@for $i from 2 through 10 {
  .icon-size-#{$i} {
    --ggs: #{$i};
  }
}

html {
  position: relative;
  min-height: 100%;
  font-size: 62.5%; /* Sets up the Base 10 stuff */
}

body {
  padding-top: 6rem;
  margin-bottom: 6rem !important;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;

  @media screen and (max-width: $sm) {
    padding-top: 8rem;
  }
}

input {
  font-size: 1.6rem !important;
  &.DateInput_input {
    margin-left: 0 !important;
  }
}

.content-public {
  position: absolute;
  top: 7.9rem;
  width: 100%;
}

// CONTENT
.content-body {
  box-sizing: border-box;
  position: relative;
  width: calc(100% - 23rem);
  margin-left: 23rem;
  padding: 0 10%;

  @media screen and (min-width: $lg) and (max-width: $xl) {
    padding: 0 2.5%;
  }
  @media screen and (min-width: $md) and (max-width: $lg) {
    margin-left: 20rem;
    width: calc(100% - 20rem);
    padding: 0 2rem;
  }
  @media screen and (min-width: $sm) and (max-width: $md) {
    margin-left: 18rem;
    width: calc(100% - 18rem);
    padding: 0 2rem;
  }
  @media screen and (max-width: $sm) {
    width: 100%;
    margin-left: 0;
    padding: 0 2rem;
  }
}
// Module Title
.module-title {
  @media screen and (min-width: $md) {
    margin-bottom: 10rem;
  }
}

.index-action-buttons {
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (min-width: $md) {
    padding-left: 50%;
    & > * {
      width: 24% !important;
      margin-left: 1%;
      margin-bottom: 1%;
    }
  }
  & > * {
    width: 49% !important;
    margin-left: 1%;
    margin-bottom: 1%;
  }
  @media screen and (max-width: $md) {
    margin-top: 2rem;
    button,
    a {
      font-size: 1.1rem !important;
    }
  }
}

.highlighted {
  background-color: $light-blue;
  padding: 0.3rem 0.8rem;
  border-radius: 1.5rem;
}
// Buttons

button.big {
  height: 4rem;
  border-radius: 1rem;
}
a.big {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  border-radius: 1rem;
}

a.disabled {
  background-color: $dark !important;
}

.primary-btn {
  color: white;
  font-weight: bold;
  background-color: $dark-blue;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1.2rem;
  border-radius: 1rem !important;
  border: 1px solid transparent;
  text-transform: uppercase;
  line-height: 1.2;

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }

  @media screen and (min-width: $lg) {
    font-size: 1.2rem;
  }
}

.secondary-btn-white {
  padding: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: white;
  border: 1px solid $dark-blue !important;
  color: $dark-blue;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem !important;
  text-transform: uppercase;

  transition: all 0.2s;

  i {
    margin-left: 0.3rem;
  }

  &.show {
    &:focus,
    &:active {
      i {
        color: $dark-blue;
      }
    }
    i {
      transform: rotate(-180deg);
    }
  }

  &:hover,
  &:focus {
    i {
      color: white;
    }
  }
  &:focus {
    i {
      color: $dark-blue;
    }
  }

  &:focus {
    background-color: white;
    color: $dark-blue;
    outline: none;
  }

  @media screen and (min-width: $md) {
    font-size: 1.4rem;
  }
}

.base-bold {
  letter-spacing: 130%;
  font-weight: bold;
}
h1 {
  @extend .base-bold;
  font-size: 3rem;
}

h2 {
  @extend .base-bold;
  font-size: 2.8rem;
}
h3 {
  @extend .base-bold;
  font-size: 2rem;
}
h4 {
  @extend .base-bold;
  font-size: 1.8rem;
}

h5 {
  letter-spacing: 130%;
  font-size: 1.6rem;
}
h6 {
  letter-spacing: 135%;
  font-size: 1.4rem;
}

.footer {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  height: 60px;
}
.navbar {
  .navbar-brand {
    height: 40px;
    width: 100px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}
.full-height {
  height: calc(100vh - 120px);
}
.carousel-home {
  margin: 0 -15px;
  width: auto;
}
.carousel-show {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}
.custom-data-table {
  min-height: 350px;
}
.btn {
  font-weight: bold;
  border-radius: 0.5rem;
  letter-spacing: 0.1rem;
  @include transition(
    opacity 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease
  );

  &:hover,
  &:focus {
    filter: alpha(opacity = 80);
    opacity: 0.8;
  }
}

.transparent {
  background-color: transparent;
  border: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: transparent;
  }
}

.spinner-container {
  min-height: 10rem;
  height: 100%;
  display: flex;
  align-items: center;
  &.spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

.css-g1d714-ValueContainer {
  font-size: 1.6rem;
  font-weight: 400;
}

.input-group-prepend {
  width: 12rem;
  & .input-group-text {
    width: 100%;
  }
}

.css-2b097c-container {
  width: 100%;
}

.back-btn {
  margin: 1rem 0;
  @media screen and (min-width: $md) {
    margin-bottom: 2rem;
  }
}
// MARGENES
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
// WIDTH
.w-60 {
  width: 60%;
}
.w-40 {
  width: 38%;
}

// PADDING
.p-t-0 {
  padding-top: 0 !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-t-50 {
  padding-top: 50px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.p-b-50 {
  padding-bottom: 50px !important;
}

pm-l-0 {
  padding-left: 0 !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.p-l-50 {
  padding-left: 50px !important;
}
.p-l-100 {
  padding-left: 100px !important;
}

.chevron-back {
  padding-left: 40px;
  @media (max-width: 991px) {
    padding-left: 30px;
    padding-top: 30px;
  }
  @media (max-width: 414px) {
    padding-left: 30px;
    padding-top: 30px;
  }
  @media (max-width: 320px) {
    padding-left: 30px;
    padding-top: 30px;
  }
}
.iconed-btn {
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
  border: 2px solid transparent;
}

.navbar-logged {
  background: linear-gradient(
    270.02deg,
    #081735 -30.74%,
    #24304a 60.91%,
    #081735 135.27%
  );
}
// Toaster
.toaster-info-bg {
  background-color: $dark-blue !important;
}

.Toastify__toast {
  border-radius: 0.5rem !important;

  &-body {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2rem;
    width: 70%;
  }
}

.password-buttons button {
  width: 100%;
  border-radius: 1rem;
}
