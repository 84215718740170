@import '~bootstrap/scss/functions';
@import '../../../assets/stylesheets/custom_variables.scss';
// FORM
.select-css {
  position: relative;
  cursor: pointer;
  &:after,
  &:before {
    content: '';
    position: absolute;
    background: $gray-500;
    border-radius: 10px;
    display: block;
    top: 15px;
    height: 8px;
    width: 2px;
  }
  &:before {
    right: 20px;
    transform: rotate(-46deg);
  }
  &:after {
    right: 15px;
    transform: rotate(46deg);
  }
  .form-control {
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: $border-radius;
  }
}
// FORM CONTROL DISABLED
.css-1fhf3k1-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  color: $gray-500 !important;
}
// FORM CONTROL BASE
.css-2b097c-container,
.css-14jk2my-container {
  &.border-on {
    .css-yk16xz-control,
    .css-1pahdxg-control,
    .css-1fhf3k1-control {
      border-top-right-radius: $input-border-radius;
      border-top-left-radius: $input-border-radius;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-color: $input-focus-border-color;
    }
  }
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    border-radius: $input-border-radius;
    width: 100%;
    min-height: 33px;
    font-size: $font-size-sm;
    font-weight: 500;
    line-height: 1.5;
    color: $input-color;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    border: 2px solid $input-border-color;
    background-color: $input-bg;
    color: $input-color;
    &:hover {
      border-color: $input-focus-border-color;
    }
    .css-g1d714-ValueContainer {
      padding: 2px 15px;
      .css-1wa3eu0-placeholder {
        color: $dark;
      }
    }
    .css-1rhbuit-multiValue {
      margin: 0 2px;
      .css-12jo7m5 {
        font-size: 100%;
      }
    }
    .css-1okebmr-indicatorSeparator,
    .css-109onse-indicatorSeparator {
      display: none;
    }
    .css-1hb7zxy-IndicatorsContainer {
      div {
        height: 28px;
        svg {
          top: -3px;
          position: relative;
        }
      }
    }
    .css-1gtu0rj-indicatorContainer,
    .css-tlfecz-indicatorContainer {
      color: $gray-500;
      position: relative;
      height: 28px;
      cursor: pointer;
      svg {
        top: -3px;
        position: relative;
      }
    }
    .css-1hwfws3 {
      padding: 0px 8px;
    }
  }
  .css-1pahdxg-control {
    border: 2px solid $gray-400 !important;
    box-shadow: 2px -4px 0 -1px transparentize($light, 0.4) !important;
  }

  // MenuList
  .css-26l3qy-menu {
    margin: -2px 0 0 0;
    background-color: $white;
    border: 2px solid $gray-400;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: -2px 3px 0 0px transparentize($light, 0.4);
    padding: 5px 10px;
    z-index: 99;
    .css-4ljt47-MenuList {
      padding: 0;
      &::-webkit-scrollbar {
        height: 5px;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 2px;
        background-color: darken($light, 4%);
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparentize(#59c3b7, 0.6);
        border-radius: 2px;
        outline: none;
      }
      .css-1n7v3ny-option,
      .css-9gakcf-option,
      .css-yt9ioa-option {
        padding: 5px 15px;
        border-radius: 5px;
        background-color: transparent;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.5;
        color: $gray-600;
        text-align: left;
        &:hover {
          padding: 5px 15px;
          background-color: #def3f1;
        }
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .css-9gakcf-option {
        background-color: $primary;
        color: $input-color;
        &:hover {
          background-color: $primary;
          color: $input-color;
        }
      }
    }
  }
}
.css-1n7v3ny-option,
.css-9gakcf-option,
.css-yt9ioa-option {
  padding: 5px 15px !important;
  border-radius: 5px;
  background-color: transparent !important;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  color: $gray-600;
  text-align: left;
  &:hover {
    background-color: #def3f1 !important;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
.css-9gakcf-option {
  background-color: #def3f1 !important;
  color: #fff;
  &:hover {
    background-color: #def3f1 !important;
    color: #fff;
  }
}
// FORM CONTROL
.css-xb97g8 {
  cursor: pointer;
  &:hover {
    background-color: transparent !important;
    color: $danger !important;
  }
}
// INVALID
.is-invalid {
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    border-color: $danger !important;
  }
  .select-css {
    .form-control {
      border-color: $danger;
    }
    &:after {
      border-color: $danger transparent transparent transparent;
    }
  }
  .css-tlfecz-indicatorContainer {
    &:after {
      border-color: $danger transparent transparent transparent;
    }
  }
}
// FORM CONTROL SELECTED
.border-on {
  border-top-right-radius: $input-border-radius !important;
  border-top-left-radius: $input-border-radius !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-color: $input-focus-border-color;
}

.select input {
  font-family: 'Times New Roman';
  font-size: 1.6rem;
}
// DISABLED
.disabled {
  &.mb-0 {
    opacity: 0.5;
  }
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    border-color: #c2c2c2 !important;
  }
  .select-css {
    .form-control {
      border-color: $light;
    }
  }
}

.css-b8ldur-Input{
  border-radius: $border-radius;
}