.payment-layout {
  margin-top: 2rem;

  @media screen and (min-width: $md) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 5%;
    & .payments-data {
      width: 80%;
      margin: 0 auto;
    }
  }
}

// PAYMENT AMOUNTS COMPONENT
.payment-amounts-container {
  margin-bottom: 4rem;
}
.amount-box {
  padding: 1.5rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 2rem;
  -webkit-box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2);
  &__title,
  &__price {
    width: 100%;
    margin: 0;
    text-align: start;
    font-weight: bold;
    font-size: 1.6rem;
  }
}

// PAYMENT BANK INFO COMPONENT

.bank-info-container {
  margin-bottom: 4rem;
}

.completed-info {
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-weight: bold;
      font-size: 1.8rem;
    }
    &__edit {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 1.3rem;
      i {
        margin-right: 0.5rem;
        transform: rotate(-45deg) scale(0.8);
      }
    }
  }
}

// PAYMENT LIST COMPONENT
.payment-list {
  overflow-y: auto;
  min-height: 15rem;
  max-height: 40vh;
  margin-bottom: 4rem;

  &.payment-modal {
    margin-bottom: 0 !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: $light-blue; /* or add it to the track */
  }
  &::-webkit-scrollbar-thumb {
    background: $light-blue-secondary;
    border-radius: 20px;
  }
  .infinite-scroll-component {
    padding: 0 !important;
  }
}

// USERSHIFT COMPONENT
.usershift-container {
  margin-bottom: 0.5rem;
}
.usershift {
  display: flex;
  align-items: stretch;
  padding: 0.5rem 0;

  &--dates,
  &--info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &--dates {
    width: 40%;
    border-right: 2px solid $light-blue;
    align-items: flex-start;
    &__shiftname {
      font-weight: bold;
      margin-bottom: 0.6rem !important;
    }
    & p {
      font-size: 1.1rem;
      margin-bottom: 0.3rem;
    }
  }
  &.one-column {
    .usershift--info {
      width: 100% !important;
      padding-left: 0 !important;
    }
  }
  &--info {
    width: 60%;
    padding-left: 1rem;
    &__taskname {
      font-size: 1.3rem !important;
      font-weight: bold;
      margin-bottom: 0.6rem !important;
    }
    &__location {
      margin-bottom: 0.6rem !important;
    }
    &__payment .payment {
      background-color: $light-blue;
      padding: 0.3rem 0.6rem;
      border-radius: 1rem;
      font-weight: bold;
    }
    & p {
      font-size: 1.2rem;
      margin-bottom: 0;
    }
  }
}

// NO PAYMENT ALERT
.no-payment-alert {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  p {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    margin-top: 5rem;
  }
}

// PAYMENT REQUEST

.request-container {
  margin: 0 auto;
  // Mobile
  @media screen and (max-width: $md) {
    width: 80%;
    margin-top: 4rem;
    .receipt {
      margin-bottom: 3rem;
    }
  }
  // Desktop
  @media screen and (min-width: $md) {
    width: 40%;
    min-width: 45rem;
    margin-top: 6rem;
    .receipt {
      margin-bottom: 5rem;
    }
  }
}

.receipt-upload {
  cursor: pointer;
  width: 100%;
  height: 8rem;
  background-color: #d3d6db;
  border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 5px 8px 10px rgba(black, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    font-weight: bold;
    color: $dark-blue;
    text-align: center;
    padding-inline: 0.5rem;
  }
}

.request--amount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4rem;
  p {
    margin: 0;
    margin-left: 1rem;
  }
}

.request-disclaimer {
  background-color: $warning-yellow;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 5px 8px 10px rgba(black, 0.1);

  .title {
    margin-bottom: 1.5rem;
  }
  .disclaimer-text {
    font-size: 1.4rem;
    text-align: justify;
  }
}

// USER PAYMENT GROUP COMPONENT
.payment-group {
  margin-bottom: 3rem;
  &-header {
    width: 100%;
    height: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  .user-payment {
    width: 100%;
    display: grid;
    margin-bottom: 2rem;
    // Desktop
    @media screen and (min-width: $md) {
      grid-template-columns: 1fr 8fr 1fr;
    }
    // Mobile
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 6fr 3fr;
    }
    &--time {
      border-right: 2px solid $light-blue;
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 5rem;
    }
    &--info {
      padding-left: 1rem;
      p {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        margin: 0;
        // Mobile
        @media screen and (max-width: $md) {
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
        }
      }
    }
    &--action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: transparent !important;
        border: none !important;
        i {
          transform: scale(1.5);
        }
      }
    }
  }

  .user-payment--info {
    // Desktop
    @media screen and (min-width: $md) {
      display: grid;
      grid-template-columns: 5fr 5fr 4fr;
    }
    // Mobile
    @media screen and (max-width: $md) {
      display: flex;
      flex-direction: column;
    }
  }
}

// TABS STYLING

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid $dark-blue;
  &:hover {
    border-bottom: 2px solid $dark-blue;
  }
}

.nav-tabs .nav-link {
  padding: 0.5rem 0 !important;
  margin-right: 3rem;
  border: none;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link:hover {
  border: none;
}
