$mobile-img-dimension: 7.5rem;
$desktop-img-dimension: 10rem;

.application-list {
  margin-top: 2rem;
}

.application-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid $gray-300;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  @media screen and (min-width: $md) {
    padding-bottom: 0.5rem;
  }
}

.application {
  // Mobile
  @media screen and (max-width: $md) {
    width: 100%;
  }
  // Desktop
  @media screen and (min-width: $md) {
    width: 80%;
  }
}

.main {
  // Desktop
  @media screen and (min-width: $md) {
    display: flex;
  }
}

.header {
  display: flex;
  &--thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    &__image {
      width: 100%;
      object-fit: fill;
    }
  }
  &--info {
    flex-grow: 1;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 1.4rem;
    font-weight: bold;

    &__title {
      font-weight: bold;
      margin: 0;
    }

    &__prices {
      display: flex;
      p {
        padding: 0 0.8rem;
        border-radius: 1rem;
      }
      .price {
        background-color: $light-blue-secondary;
        margin-right: 0.5rem;
      }
      .variable {
        background-color: $light-blue;
      }
    }
  }

  // Mobile
  @media screen and (max-width: $md) {
    margin-bottom: 1rem;
    &--thumbnail {
      width: $mobile-img-dimension;
      height: $mobile-img-dimension;
    }
    &--info {
      height: $mobile-img-dimension;
    }
  }
  // Desktop
  @media screen and (min-width: $md) {
    flex: 2 1 60%;
    &--thumbnail {
      width: $desktop-img-dimension;
      height: $desktop-img-dimension;
    }
    &--info {
      height: $desktop-img-dimension;
    }
  }
}

.campaign-info {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // Desktop
  @media screen and (min-width: $md) {
    flex: 1 1 40%;
  }
}

.state {
  margin: 1.5rem 0;
  font-size: 1.3rem;
  align-items: center;
  p {
    margin: 0;
  }

  &--status {
    display: flex;
    align-items: center;

    &__title {
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 0.5rem !important;
    }
    &__text {
      background-color: $light-blue-secondary;
      padding: 0 0.8rem;
      border-radius: 1rem;
    }
  }
  &--date {
    display: flex;
    align-items: center;
    &__text {
      margin-left: 1rem !important;
    }
  }

  // Mobile
  @media screen and (max-width: $md) {
    &--status {
      margin-bottom: 1rem;
    }
  }
  // Desktop
  @media screen and (min-width: $md) {
    display: flex;
    &--status {
      flex: 0 2 60%;
    }
    &--date {
      flex: 1 1 40%;
    }
  }
}

.application-buttons {
  // Mobile
  @media screen and (max-width: $md) {
    display: flex;
    justify-content: space-between;
    &--secondary {
      flex: 0 1 35%;
      & .hidden {
        visibility: hidden;
      }
    }
    &--primary {
      flex: 0 2 60%;
    }
  }
  // Desktop
  @media screen and (min-width: $md) {
    position: absolute;
    top: 10%;
    right: 0;
    width: 18%;
    &--secondary {
      margin-bottom: 0.5rem;
      & .hidden {
        display: none;
      }
    }
    * > button {
      font-size: 1.2rem;
    }
  }
}

// Variations
.application-buttons--secondary.task-show {
  @media screen and (min-width: $md) {
    width: calc(33% - 1rem);
    float: right;
  }
  & button {
    height: 4rem;
  }
}
