@import '~bootstrap/scss/functions';
@import '../../../assets/stylesheets/custom_variables.scss';

.custom-file-container{
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
}

.custom-file-img {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100px;
    height: 100px;
  }
  &:after {
    content: '';
    padding-bottom: 56.25%;
    display: block;
  }
}

.custom-avatar-img{
  position: relative;
  img {
    position: absolute;
    width: 100px;
    height: 100px;
  }
  &:after {
    content: '';
    padding-bottom: 56.25%;
    display: block;
  }
}

.custom-file-add-image{
  width: 30px !important;
  height: 30px;
  margin-top: -30px;
  margin-left: 60px;
  background-color: $teal;
  border-radius: 15px;
  border: 3px solid white;
  box-shadow: -1px 1px 5px 1px #000;
  input{
    width: 30px;
  }
  .gg-math-plus{
    margin-top: -23px;
    margin-left: 4px;
    color: white;
  }
}