@import '~bootstrap/scss/functions';
@import '../../../assets/stylesheets/custom_variables.scss';
.react-datepicker-wrapper {
  width: 100%;
}
@media (max-width: 767px) {
  .form-control {
    font-size: 16px !important;
  }
  .css-g1d714-ValueContainer {
    font-size: 15px !important;
  }
  .css-yk16xz-control {
    margin-top: 4.5px;
  }
  .css-1pahdxg-control {
    font-size: 15px !important;
  }
}
.is-invalid {
  .input-group {
    .input-group-append {
      span {
        border-color: $danger;
        color: $danger;
      }
    }
    .input-group-prepend {
      span {
        border-color: $danger;
        color: $danger;
      }
    }
    .form-control {
      border-color: $danger;
    }
  }
  .react-datepicker-wrapper {
    .form-control {
      border-color: $danger;
    }
  }
  .SingleDatePickerInput {
    border-color: $danger !important;
  }
  .mui-textfield {
    border-color: $danger !important;
  }
  .dropzone-section {
    border-color: $danger;
  }
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
  padding: 0.3rem 0.75rem !important;
}
.form-control:disabled {
  border-color: $gray-400;
  color: $gray-500;
}
.form-control[readonly] {
  background-color: $input-bg;
  border-color: $light;
  color: $gray-500;
}
.disabled {
  .input-group {
    .input-group-prepend {
      span {
        border-color: $gray-400;
      }
    }
    .input-group-append {
      span {
        border-color: $gray-400;
      }
    }
  }
}

.form-group {
  .custom-switch {
    z-index: auto;
    label {
      padding-left: 20px;
      padding-top: 5px;
      &:after {
        background-color: $white !important;
        border-color: $white !important;
        top: calc(0.25rem + 2.2px) !important;
        left: calc(-2.25rem + 3px) !important;
        width: 1rem !important;
        height: 1rem !important;
        border-radius: 1rem;
      }
      &:before {
        background-color: $gray-500;
        border-color: $gray-500;
        width: 50px !important;
        height: 20px !important;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $orange;
      border-color: $orange;
      border-radius: 1rem;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: $white;
      transform: translateX(1.75rem) !important;
    }
    .custom-control-label::before {
      border-radius: 1rem !important;
    }
  }
}

.button-group input {
  display: none;
}
.button-group {
  input + label {
    cursor: pointer;
    flex: 1;
    background-color: $white;
    border: 2px solid $gray-400;
    color: $input-color;
    padding: 8px 15px 5px 15px;
    text-align: center;
    margin-right: -1px;
    font-size: 13px;
    font-weight: 500;
    &:hover,
    &:focus {
      border-color: $gray-100;
    }
  }
  input:first-child + label {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
  label:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  input:checked + label {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
}

#input-group-dropdown-1 {
  z-index: 0;
}
.input-group-text {
  svg {
    width: 18px;
    height: 18px;
    color: $gray-700;
  }
}
.form-control {
  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: $gray-500;
  }
}
.input-disabled {
  &:hover {
    border-color: $gray-400 !important;
  }
}
.form-check-label {
  border-width: 2px;
  background-color: $white;
}
.custom-control-label::before {
  top: 0;
}
.mui-textfield {
  background: $white;
  border: 2px solid $gray-400;
  border-radius: 10px;
  padding: 0 10px;
  width: 100%;
  min-height: 34px;
  font-size: 0.8125rem !important;
  font-weight: 500 !important;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: $gray-100;
  }
}
.MuiInput-underline:after {
  border-bottom: 2px solid $primary !important;
}
.MuiInputBase-input {
  cursor: text;
}
// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $primary; //background
  color: $light; //text
  border: 1px solid $light; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
// Will edit when hovered over. _span style also has this property
.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active {
  background: $primary;
  color: $light;
  border-radius: 1rem;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $primary;
}

.SingleDatePicker_picker,
.DayPicker {
  border-radius: 1rem;
}

.DateRangePicker {
  width: 100%;
  .DayPickerNavigation {
    min-width: 330px;
    margin: 0 -10px;
  }
  .DateRangePickerInput {
    width: 100%;
    border-radius: 10px;
    border-color: $input-border-color;
    border-width: $input-border-width;
    height: $input-height;
    display: inline-flex;
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $gray-400;
    }
    .DateInput {
      height: $input-height;
      display: flex;
      background-color: transparent;
      .DateInput_input__focused {
        border-bottom: unset;
      }
      .DateInput_input {
        padding-bottom: 1rem;
        height: $input-height;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-sm;
        background-color: transparent;
        padding-top: 1rem;
        padding-left: 1rem;
        color: $input-color;
        height: 10px;
      }
    }
    .DateRangePickerInput_calendarIcon,
    .DateRangePickerInput_arrow {
      float: right;
      padding-top: 0.3rem;
      padding-left: 0;
      margin-left: auto;
      &:focus {
        outline: none;
      }
    }
  }
}

.SingleDatePicker {
  width: 100%;
  z-index: auto;
  background: #def3f1;
  .DayPickerNavigation {
    border-radius: 50px;
    margin: 0 -10px;
    min-width: 330px;
    @media (max-width: 576px) {
      width: 200px;
    }
  }
  .SingleDatePickerInput {
    width: 100%;
    border-radius: $border-radius;
    border-color: $input-border-color;
    border-width: $input-border-width;
    height: $input-height;
    display: inline-flex;
    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $primary;
    }
    .DateInput {
      display: flex;
      background-color: transparent;
      .DateInput_input__focused {
        border-bottom: unset;
      }
      .DateInput_input {
        padding-bottom: 1rem;
        height: $input-height;
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 500;
        font-size: $font-size-sm;
        background-color: transparent;
        padding-top: 0.95rem;
        padding-left: 1rem;
        color: $input-color;
        @media (max-width: 767px) {
          margin-left: -9px;
        }
      }
    }
    .SingleDatePickerInput_calendarIcon {
      float: right;
      padding-top: 0.6rem;
      padding-left: 0;
      margin-left: auto;
      margin-left: -5px;
      margin-right: -6px;
      &:focus {
        outline: none;
      }
      @media (max-width: 576px) {
        margin-left: -10px;
        margin-right: -5px;
      }
    }
  }
}
.disabled {
  .SingleDatePickerInput {
    &:hover {
      border-color: $gray-400 !important;
    }
  }
}
.DayPicker_transitionContainer {
  background: #def3f1;
  min-height: 300px;
  border-radius: 1rem;
}
.CalendarMonth {
  background: #def3f1;
  padding: 0 0 0 10px !important;
}
.CalendarMonth_caption {
  background: #def3f1;
  .form-control {
    font-size: 12px !important;
  }
  .mb-1 {
    select:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  select:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}
.SingleDatePickerInput_clearDate {
  margin: 0 0 0 0px;
  padding-left: 10px;
}

.css-1wa3eu0-placeholder {
  color: #303030 !important;
}

.react-datepicker-popper {
  background: #def3f1;
  z-index: 2 !important;
}

.DateInput_fangStroke {
  stroke: #def3f1;
  fill: transparent;
}

.DateInput_fangShape {
  fill: #def3f1;
}

.CalendarMonthGrid {
  background: #def3f1;
  text-align: left;
  z-index: 0;
}

.DayPicker_weekHeader {
  background: #ace1db;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.CalendarDay__default {
  background: #def3f1;
  border: none;
}

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active {
  background: #24304a;
  color: #f8f9fa;
}

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 1rem;
  padding: 6px 9px;
  height: 27px;
}

.DayPickerNavigation_svg__horizontal {
  margin-top: -2px;
  height: 19px;
  width: 19px;
  fill: $primary;
  display: block;
}

.CalendarMonth_caption .form-control {
  font-size: 12px !important;
  margin-top: -5px;
  color: $primary;
}

.radio-buttons {
  label {
    padding: 5px;
    input {
      margin: 5px;
    }
  }
}

.button {
  background-color: $blue;
  font-size: 14px;
  border-radius: 15px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
  padding-block: 1rem;
  padding-inline: 4rem;
  text-transform: uppercase;
}

.button-notify {
  background-color: $blue;
  border-radius: 1rem;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  min-width: 18rem;
  padding-block: 1rem;
  padding-inline: 2rem;
  text-align: center;
  text-transform: uppercase;
}

.button-confirm-notify {
  background-color: $green !important;
  border-radius: 1rem;
  color: #1b1f27 !important;
  cursor: pointer;
  font-weight: bold;
  margin: auto;
  min-width: 18rem;
  padding-block: 1rem;
  padding-inline: 2rem;
  text-align: center;
  text-transform: uppercase;
}

.button-refresh {
  color: #1b1f27;
  background-color: #ffffff;
  border: 2px solid $blue;
  border-radius: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-inline: auto;
  padding: 0.8rem 1rem;
  transition: all 0.25s ease-in-out;
  text-transform: uppercase;
  max-width: 200px;

  &:hover {
    background-color: $blue;
    color: #ffffff;
  }
}

.title-body-modal {
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}

.plain {
  font-size: 1.3rem;
  color: #8a8c90;
  margin-top: 0rem;
}

.title-header-modal {
  font-size: 2rem;
  font-weight: 500;
}

.title-image-send {
  margin-top: 1rem;
  margin-bottom: 0.25rem;

  .optional-text {
    color: #8c8c8c;
    font-style: italic;
  }
}
