
@import '~bootstrap/scss/functions';
@import '../../../assets/stylesheets/custom_variables.scss';

.show-hide {
    overflow: hidden;
    font-family: 'Ubuntu';
    background-color: #59C3B7;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(82, 184, 172, 0.1), 2px 4px 17px rgba(89, 195, 183, 0.6);
    border-radius: 1.5rem;
    padding:1rem;
    height: 6rem;
    margin: 2rem;
    @media screen and (min-width: $sm) and (max-width: $md){
      margin: 2rem auto;
      width: 90%;
    }
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      width: 100%;
      margin-left: 0;
    }
  }
  #left {
    overflow: hidden;
    text-align: center;
    font-size: 1.2rem !important;
    padding-right: 1.5rem;
    @media screen and (max-width: $sm) {
      padding-right: 1rem;
      padding-top: 0.5rem;
      font-size: 1rem;
    }
  }
  #right {
    float: right;
    width: 1rem;
    height: 1rem;
    text-align: center;
    a{
        background-color: transparent;
        border-color: transparent;
        color: #24304A;
        margin-left: -1.5rem;
        margin-top: -1.5rem;
    }
  }
  .show-hide a {
     color: #FFFFFF;
     text-decoration: none;
     padding-top: 0.5rem;
  }
  .show-hide a:hover {
     text-decoration:underline;
  }