.map-container {
  position: relative;
  margin-top: 2rem;
  box-sizing: border-box;

  @media screen and (max-width: $sm) {
    margin-top: 0;
    position: absolute;
    left: 0;
    top: 5rem;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 13rem);
  }

  @media screen and (min-width: $sm) and (max-width: $xl) {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 50rem;
  }

  .map-list.desktop {
    display: none;
    @media screen and (min-width: $xl) {
      display: block;
      width: 35%;
      position: absolute;
      top: 0;
      left: 65%;
      height: 60vh;
      max-height: 50rem;
      min-height: 50rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: $gray-400; /* or add it to the track */
      }
      &::-webkit-scrollbar-thumb {
        background: $dark-blue;
        border-radius: 2.5px;
      }
    }
    & .tasks-list {
      margin-top: 0;
    }
  }
}

.map-current-location {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  margin-inline: auto;
  height: 250px;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-current-location {
  transform: translate(-50%, -100%);
  position: relative;
  width: 24px;
  height: 26px;
}

.marker-icon-style {
  color: $light-blue-secondary;
  width: 100% !important;
  height: auto;
}

.confirm-finish-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.icon-alert {
  background-color: $warning-yellow;
  color: #1b1f27;
}

.alert-btn-wrapper {
  padding-inline: 1rem;
  padding-block: 0.2rem;
  border-radius: 100%;
  border: 3px solid #1b1f27;
  outline: 1rem solid $warning-yellow;
}

.map {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  position: relative;
  @media screen and (min-width: $sm) and (max-width: $xl) {
    height: 100%;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media screen and (min-width: $xl) {
    width: 65%;
    height: 50rem;
  }
}

.location-marker {
  --ggs: 1.2;
  width: 2rem;
  height: 2rem;

  &.button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
  }
}
