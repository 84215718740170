.landing {
  background-color: transparent;
}
.section-title {
  font-weight: bold;
  text-align: center;

  // Small Screens
  @media screen and (max-width: $xl) {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    font-size: 3rem;
    margin-bottom: 4rem;
  }
}
// -------------------------------- MAIN SECTION ----------------------------------//

.landing--main {
  display: grid;
  overflow-y: hidden;
  height: calc(100vh - 8rem) !important;

  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    grid-template-columns: 2fr 3fr;
    height: calc(100vh - 8rem) !important;
  }

  // INFO
  .info {
    background-color: $light-blue-secondary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15%;
    max-height: calc(100vh - 8rem) !important;

    p {
      text-shadow: 1px 1px 8px #333;
    }

    // Small Screens
    @media screen and (max-width: $xl) {
      padding: 6rem 10%;
      height: calc(100vh - 8rem);
      min-height: 40rem;
      background-color: transparent;
      z-index: 100;
    }
  }

  .catchphrase {
    font-weight: bold;
    color: white;

    // Small Screens
    @media screen and (max-width: $xl) {
      font-size: 2.5rem;
      margin-bottom: 3rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }
    // Full Screen Desktop
    @media screen and (min-width: $xl) {
      font-size: 3rem;
      margin-bottom: 4rem;
      letter-spacing: 0.3rem;
    }
  }

  .sub-catchphrase {
    color: white;

    // Small Screens
    @media screen and (max-width: $xl) {
      font-size: 1.6rem;
      text-align: center;
      width: 80%;
      margin: 0 auto 4rem auto;
    }

    // Full Screen Desktop
    @media screen and (min-width: $xl) {
      width: 80%;
      letter-spacing: 0.1rem;
      margin-bottom: 6rem;
      font-size: 1.8rem;
    }
  }

  &__btn {
    height: 6rem !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    // Small Screens
    @media screen and (max-width: $xl) {
      font-size: 1.6rem !important;
      width: 80% !important;
      max-width: 35rem;
      margin: 0 auto;
    }
    // Full Screen Desktop
    @media screen and (min-width: $xl) {
      font-size: 1.8rem !important;
      width: 50% !important;
      min-width: 30rem;
    }
  }

  .carousel-container {
    @media screen and (max-width: $xl) {
      position: absolute;
      top: 0;
      z-index: 99;
      width: 100%;
      filter: brightness(60%);
      opacity: 0.75;

      .carousel-control-prev,
      .carousel-control-next {
        visibility: hidden;
      }
    }
  }

  .landing-carousel {
    height: calc(100vh - 8rem) !important;
    overflow-y: hidden;
    background: linear-gradient(to bottom, $dark-blue, $light-blue-secondary);
  }

  .landing-carousel-image {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    opacity: 0.7;
    mix-blend-mode: luminosity;
  }

  .carousel-caption {
    // Small Screens
    @media screen and (max-width: $xl) {
      display: none;
    }
    // Full Screen Desktop
    @media screen and (min-width: $xl) {
      bottom: 10rem !important;
      &--title {
        font-size: 3rem !important;
      }
      &--subtitle {
        font-size: 1.6rem !important;
      }
    }
  }
}

// -------------------------------- HOW-IT-WORKS SECTION ----------------------------------//

.landing--how-it-works {
  background-color: #def3f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Small Screens
  @media screen and (max-width: $xl) {
    padding: 3rem 5%;
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    min-height: 60rem;
    padding: 15rem 15%;
    max-height: calc(100vh - 8rem);
  }

  &--title {
    margin-bottom: 4rem;
  }

  &--cards {
    display: grid;

    // Small Screens
    @media screen and (max-width: $xl) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    // Full Screen Desktop
    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(4, 1fr);
    }

    .card {
      --card-title-font-size: 1.8rem;
      --card-text-font-size: 1.4rem;
      --card-icon-height: 8rem;

      background-color: transparent;
      border: none;

      .card-icon {
        display: grid;
        justify-content: center;
        align-items: center;
        height: var(--card-icon-height);
      }

      .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      &--title {
        font-weight: bold;
        font-size: var(--card-title-font-size);
        margin-bottom: calc(var(--card-text-font-size) * 1.5);
      }
      &--subtitle {
        font-size: var(--card-text-font-size);
        width: 80%;
      }
      &--title,
      &--subtitle {
        text-align: center;
      }

      // Small Screens
      @media screen and (max-width: $xl) {
        --card-title-font-size: 1.6rem;
        --card-text-font-size: 1.2rem;
        --card-icon-height: 5rem;

        &--subtitle {
          width: 100%;
        }
        .card-icon i {
          transform: scale(1.5);
        }
      }
    }
  }
}

// -------------------------------- TASKS SECTION -------------------------------------//

.landing--tasks {
  // Small Screens
  @media screen and (max-width: $xl) {
    padding: 3rem 5%;
    .tasks {
      width: 80%;
      margin: 0 auto;
    }
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    padding: 5rem 10%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 50rem;
  }

  .phone {
    display: none;
    @media screen and (min-width: $xl) {
      display: block;
      width: 100%;
      img {
        position: absolute;
        transform: translateY(-15rem);
        width: 30rem;
      }
    }
  }
}

.task-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10rem;
  padding: 0 1rem;

  // Mobile
  @media screen and (max-width: $xl) {
    grid-template-columns: 1fr;
  }
}

.tasks .carousel {
  // Mobile
  @media screen and (max-width: $xl) {
    min-height: 55rem !important;
  }
  // Desktop
  @media screen and (min-width: $xl) {
    max-height: 45rem !important;
  }

  .carousel-control-prev,
  .carousel-control-next {
    visibility: hidden;
  }
}

.task-card {
  --card-title-font-size: 1.6rem;
  --card-text-font-size: 1.3rem;

  .main {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .header {
    margin-bottom: var(--card-title-font-size);
  }

  .header--info__title {
    font-size: var(--card-title-font-size);
  }
  .header--info__prices p {
    font-size: calc(var(--card-text-font-size * 1.4)) !important;
  }
  .campaign-info p {
    font-size: var(--card-text-font-size) !important;
    margin-bottom: calc(var(--card-text-font-size) * 0.5) !important;
  }

  // Mobile
  @media screen and (max-width: $xl) {
    --card-title-font-size: 1.6rem;
    --card-text-font-size: 1.4rem;

    margin-bottom: 3rem;
  }
}

// -------------------------------- BENEFITS SECTION -------------------------------------//

.landing--benefits {
  background-color: $dark-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Small Screens
  @media screen and (max-width: $xl) {
    padding: 3rem 5%;
    min-height: 60rem;
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    padding: 5rem 15%;
  }

  &--title {
    color: white;
    margin-bottom: 5rem;
  }

  .benefits {
    display: grid;

    // Small Screens
    @media screen and (max-width: $xl) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 1rem;
      column-gap: 2rem;
    }
    // Full Screen Desktop
    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10rem;
    }
  }

  .benefit-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &--text {
      color: white;
      width: 80%;
      text-align: center;
      font-size: var(--card-text-font-size);
    }

    .icon-container {
      --container-diameter: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light-blue-secondary;
      width: var(--container-diameter);
      height: var(--container-diameter);
      border-radius: calc(var(--container-diameter) / 2);
      margin-bottom: 4rem;

      // Small Screens
      @media screen and (max-width: $xl) {
        --container-diameter: 12rem;
        margin-bottom: 2rem;
      }
    }

    // // Small Screens
    // @media screen and (max-width: $xl) {
    //   &--text {
    //     font-size: 1.4rem;
    //   }
    // }
    // // Full Screen Desktop
    // @media screen and (min-width: $xl) {
    //   &--text {
    //     font-size: 2rem;
    //   }
    // }
  }
}
// -------------------------------- FEEDBACK SECTION ----------------------------------//
.landing--feedbacks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Small Screens
  @media screen and (max-width: $xl) {
    padding: 3rem 5%;
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    padding: 5rem 15%;
  }
}

.feedbacks {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 30rem;

  &-carousel {
    width: 80%;
    min-height: 45rem;
    .carousel-control-prev,
    .carousel-control-next {
      filter: invert(100%);
    }
    // Small Screens
    @media screen and (max-width: $xl) {
      width: 100%;
      min-height: 45rem;
    }
  }
}

.feedback-container {
  display: flex;
  justify-content: center;
}

.feedback {
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Small Screens
  @media screen and (max-width: $xl) {
    max-width: 80%;
    margin: 0 auto;
  }

  &-photo {
    box-sizing: border-box;
    --feedback-profile-img: 12rem;
    width: var(--feedback-profile-img);
    height: var(--feedback-profile-img);
    border-radius: calc(var(--feedback-profile-img) / 2);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 2rem;
    border: 6px solid $light-blue;
    img {
      width: 100%;
    }

    // Small Screens
    @media screen and (max-width: $xl) {
      --feedback-profile-img: 12rem;
    }
  }

  &-info {
    p {
      text-align: center;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
        font-size: var(--card-text-font-size) !important;
      }
      font-size: var(--card-text-font-size);
    }
    &--name {
      font-weight: bold;
    }

    // Small Screens
    @media screen and (max-width: $xl) {
      &--text {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}

// -------------------------------- COMPANIES SECTION ----------------------------------//
.landing--companies {
  // Small Screens
  @media screen and (max-width: $xl) {
    padding: 3rem 5%;
    min-height: 25rem;
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    min-height: 40rem;
    padding: 5rem 0;
    display: grid;
  }
}

.companies {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  // Small Screens
  @media screen and (max-width: $xl) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 2rem;
    padding: 0 1rem;
  }
}

.company {
  --company-logo-size: 12rem;

  display: flex;
  justify-content: center;
  align-items: center;

  // Small Screens
  @media screen and (max-width: $xl) {
    --company-logo-size: 6rem;
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    min-width: 20rem;
  }

  &-img-container {
    width: var(--company-logo-size);
    height: var(--company-logo-size);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    clip-path: circle(calc(var(--company-logo-size) / 2 * 0.95) at 50% 50%);

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// -------------------------------- FOOTER SECTION ----------------------------------//
.landing--footer {
  background-color: $light-blue-secondary;

  // Small Screens
  @media screen and (max-width: $xl) {
    padding: 3rem 5%;
    min-height: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  // Full Screen Desktop
  @media screen and (min-width: $xl) {
    padding: 5rem 10%;
    display: grid;
    grid-template-columns: 2fr 7fr 1fr;

    .footer-logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}

.offices {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;

  --text-size: 1.5rem;

  // Mobile
  @media screen and (max-width: $xl) {
    --text-size: 1.4rem;
    display: flex;
    flex-direction: column;
    p {
      width: 100% !important;
      margin-bottom: 1rem !important;
    }
    .office {
      margin-bottom: 3rem;
    }
  }

  .office {
    padding: 0 2rem;

    p {
      font-size: var(--text-size);
      width: 80%;
      margin-bottom: 1.5rem;
    }

    &-country {
      font-weight: bold;
      margin-bottom: 2rem !important;
      // Mobile
      @media screen and (max-width: $xl) {
        margin-bottom: 1rem !important;
      }
    }
    &-detail {
      display: grid;
      grid-template-columns: 3rem 1fr;
      i {
        margin-top: 0.5rem;
      }
    }
  }
}

.social-media {
  display: flex;
  align-items: center;
  flex-direction: column;
  --text-size: 1.8rem;

  p {
    font-size: var(--text-size) !important;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  &--links {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .social-link {
      margin: 0 1.5rem;
      i {
        transform: scale(1.5);
      }
    }
  }
}

// -------------------------------- GENERAL SETTINGS ----------------------------------//

p {
  margin: 0;
}

// CAROUSEL
.carousel {
  max-height: 100%;
  --indicator-size: 1.5rem;
  --indicators-margin: 3rem;

  .carousel-indicators {
    bottom: var(--indicators-margin) !important;

    li {
      width: var(--indicator-size);
      height: var(--indicator-size);
      border-radius: 100%;
      background-color: #999;
      margin: 0 var(--indicators-margin);

      &.active {
        background-color: $light-blue-secondary;
      }
    }
    // Small Screens
    @media screen and (max-width: $xl) {
      --indicator-size: 1rem;
      --indicators-margin: 1rem;
    }
  }
}

// Small Screens
@media screen and (max-width: $xl) {
}
// Full Screen Desktop
@media screen and (min-width: $xl) {
}
