.not-found {
  --big-text: 20rem;
  --secondary-text: 1.8rem;
  width: 100%;
  height: calc(100vh - 12rem);
  min-height: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--body {
    display: flex;
    align-items: center;
    padding: 3rem 0;

    i {
      margin-right: 1rem;
      margin-top: 0.4rem;
    }
  }

  h1 {
    font-size: var(--big-text);
    color: $light-blue-secondary;
  }
  p {
    font-size: var(--secondary-text);
  }

  @media screen and (max-width: $md) {
    --big-text: 12rem;
    --secondary-text: 1.6rem;
    min-height: 40rem;
  }
}

.not-found--navbar {
  background-color: $dark-blue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $md) {
    padding: 0 2rem;

    .main-logo {
      max-width: 15rem;
      justify-content: flex-start;
    }
  }
}
