// Mobile
@media screen and (max-width: $sm) {
  .alert-container {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 80%;
  }
}
// Medium Size
@media screen and (min-width: $sm) and (max-width: $xl) {
  .alert-container {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
  }
}

// Desktop
@media screen and (min-width: $xl) {
  .alert-container {
    position: absolute;
    top: 20%;
    left: 15%;
    width: 34%;
  }
}

// General
.alert-container {
  z-index: -1;
  transition: z-index 0.3s step-end;

  &.show {
    z-index: 99;
    transition: z-index 0.3s step-start;
    .alert {
      opacity: 0.75;
    }
  }
}

.alert {
  transition: all 0.3s ease-in-out;
  background-color: black;
  opacity: 0;
  padding: 1rem 0;
}

.alert-message {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  margin: 0;
}
